/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	AdminZoneListResponse,
	AdminListZonesParams,
	AdminZoneCreateResponse,
	AdminZoneCreateRequest,
	AdminZoneDeleteResponse,
	AdminDeleteZoneParams,
} from "./index.schemas";

export const useAdminListZonesHook = () => {
	const adminListZones = useCustomInstance<AdminZoneListResponse>();

	return (params?: AdminListZonesParams, signal?: AbortSignal) => {
		return adminListZones({ url: `/AdminZone/List`, method: "get", params, signal });
	};
};

export const getAdminListZonesQueryKey = (params?: AdminListZonesParams) => [
	`/AdminZone/List`,
	...(params ? [params] : []),
];

export type AdminListZonesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminListZonesHook>>>
>;
export type AdminListZonesQueryError = unknown;

export const useAdminListZones = <
	TData = Awaited<ReturnType<ReturnType<typeof useAdminListZonesHook>>>,
	TError = unknown
>(
	params?: AdminListZonesParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useAdminListZonesHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminListZonesQueryKey(params);

	const adminListZones = useAdminListZonesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAdminListZonesHook>>>> = ({
		signal,
	}) => adminListZones(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useAdminListZonesHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useAdminCreateZonesHook = () => {
	const adminCreateZones = useCustomInstance<AdminZoneCreateResponse>();

	return (adminZoneCreateRequest: AdminZoneCreateRequest) => {
		return adminCreateZones({
			url: `/AdminZone/Create`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: adminZoneCreateRequest,
		});
	};
};

export type AdminCreateZonesMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminCreateZonesHook>>>
>;
export type AdminCreateZonesMutationBody = AdminZoneCreateRequest;
export type AdminCreateZonesMutationError = unknown;

export const useAdminCreateZones = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useAdminCreateZonesHook>>>,
		TError,
		{ data: AdminZoneCreateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const adminCreateZones = useAdminCreateZonesHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useAdminCreateZonesHook>>>,
		{ data: AdminZoneCreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return adminCreateZones(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof adminCreateZones>>,
		TError,
		{ data: AdminZoneCreateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useAdminDeleteZoneHook = () => {
	const adminDeleteZone = useCustomInstance<AdminZoneDeleteResponse>();

	return (params?: AdminDeleteZoneParams) => {
		return adminDeleteZone({ url: `/AdminZone/Delete`, method: "delete", params });
	};
};

export type AdminDeleteZoneMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminDeleteZoneHook>>>
>;

export type AdminDeleteZoneMutationError = unknown;

export const useAdminDeleteZone = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useAdminDeleteZoneHook>>>,
		TError,
		{ params?: AdminDeleteZoneParams },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const adminDeleteZone = useAdminDeleteZoneHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useAdminDeleteZoneHook>>>,
		{ params?: AdminDeleteZoneParams }
	> = (props) => {
		const { params } = props ?? {};

		return adminDeleteZone(params);
	};

	return useMutation<
		Awaited<ReturnType<typeof adminDeleteZone>>,
		TError,
		{ params?: AdminDeleteZoneParams },
		TContext
	>(mutationFn, mutationOptions);
};
