/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	QueryImageListResponse,
	ListQueryImagesParams,
	QueryImageStartUploadResponse,
	QueryImageStartUploadRequest,
	QueryImageCompleteUploadResponse,
	QueryImageCompleteUploadRequest,
	QueryImageDeleteResponse,
	QueryImageDeleteRequest,
} from "./index.schemas";

export const useListQueryImagesHook = () => {
	const listQueryImages = useCustomInstance<QueryImageListResponse>();

	return (params?: ListQueryImagesParams, signal?: AbortSignal) => {
		return listQueryImages({ url: `/QueryImage/List`, method: "get", params, signal });
	};
};

export const getListQueryImagesQueryKey = (params?: ListQueryImagesParams) => [
	`/QueryImage/List`,
	...(params ? [params] : []),
];

export type ListQueryImagesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListQueryImagesHook>>>
>;
export type ListQueryImagesQueryError = unknown;

export const useListQueryImages = <
	TData = Awaited<ReturnType<ReturnType<typeof useListQueryImagesHook>>>,
	TError = unknown
>(
	params?: ListQueryImagesParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useListQueryImagesHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListQueryImagesQueryKey(params);

	const listQueryImages = useListQueryImagesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListQueryImagesHook>>>> = ({
		signal,
	}) => listQueryImages(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useListQueryImagesHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useStartQueryImageUploadHook = () => {
	const startQueryImageUpload = useCustomInstance<QueryImageStartUploadResponse>();

	return (queryImageStartUploadRequest: QueryImageStartUploadRequest) => {
		return startQueryImageUpload({
			url: `/QueryImage/StartUpload`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: queryImageStartUploadRequest,
		});
	};
};

export type StartQueryImageUploadMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useStartQueryImageUploadHook>>>
>;
export type StartQueryImageUploadMutationBody = QueryImageStartUploadRequest;
export type StartQueryImageUploadMutationError = unknown;

export const useStartQueryImageUpload = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useStartQueryImageUploadHook>>>,
		TError,
		{ data: QueryImageStartUploadRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const startQueryImageUpload = useStartQueryImageUploadHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useStartQueryImageUploadHook>>>,
		{ data: QueryImageStartUploadRequest }
	> = (props) => {
		const { data } = props ?? {};

		return startQueryImageUpload(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof startQueryImageUpload>>,
		TError,
		{ data: QueryImageStartUploadRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useCompleteQueryImageUploadHook = () => {
	const completeQueryImageUpload = useCustomInstance<QueryImageCompleteUploadResponse>();

	return (queryImageCompleteUploadRequest: QueryImageCompleteUploadRequest) => {
		return completeQueryImageUpload({
			url: `/QueryImage/CompleteUpload`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: queryImageCompleteUploadRequest,
		});
	};
};

export type CompleteQueryImageUploadMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCompleteQueryImageUploadHook>>>
>;
export type CompleteQueryImageUploadMutationBody = QueryImageCompleteUploadRequest;
export type CompleteQueryImageUploadMutationError = unknown;

export const useCompleteQueryImageUpload = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCompleteQueryImageUploadHook>>>,
		TError,
		{ data: QueryImageCompleteUploadRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const completeQueryImageUpload = useCompleteQueryImageUploadHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCompleteQueryImageUploadHook>>>,
		{ data: QueryImageCompleteUploadRequest }
	> = (props) => {
		const { data } = props ?? {};

		return completeQueryImageUpload(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof completeQueryImageUpload>>,
		TError,
		{ data: QueryImageCompleteUploadRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteQueryImageHook = () => {
	const deleteQueryImage = useCustomInstance<QueryImageDeleteResponse>();

	return (queryImageDeleteRequest: QueryImageDeleteRequest) => {
		return deleteQueryImage({
			url: `/QueryImage/Delete`,
			method: "delete",
			headers: { "Content-Type": "application/json" },
			data: queryImageDeleteRequest,
		});
	};
};

export type DeleteQueryImageMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteQueryImageHook>>>
>;
export type DeleteQueryImageMutationBody = QueryImageDeleteRequest;
export type DeleteQueryImageMutationError = unknown;

export const useDeleteQueryImage = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteQueryImageHook>>>,
		TError,
		{ data: QueryImageDeleteRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteQueryImage = useDeleteQueryImageHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteQueryImageHook>>>,
		{ data: QueryImageDeleteRequest }
	> = (props) => {
		const { data } = props ?? {};

		return deleteQueryImage(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteQueryImage>>,
		TError,
		{ data: QueryImageDeleteRequest },
		TContext
	>(mutationFn, mutationOptions);
};
