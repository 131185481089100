import { Delete, Edit, Plus, Trash, Undo } from "@tamagui/lucide-icons";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { Paragraph, XStack, H4, Spinner, YStack, TextArea, H6, Input } from "tamagui";

import { useStockLookup } from "../../../common/api/multiRetail";
import { useAdminUpdateQueryResolved } from "../../../common/api/staverton.openapi/admin-query";
import { ZoneItem, ZoneListItem } from "../../../common/api/staverton.openapi/index.schemas";
import { useLookupBarcode } from "../../../common/api/staverton.openapi/open-retail";
import { useGetQuery } from "../../../common/api/staverton.openapi/query";
import { useListQueryImages } from "../../../common/api/staverton.openapi/query-image";
import {
	useCreateZoneItem,
	useDeleteZoneItem,
	useUpdateZoneItem,
} from "../../../common/api/staverton.openapi/zone-item";
import { Button } from "../../../common/components";
import { Alert } from "../../../common/components/Alert";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";
import { useSearch } from "../../../common/hooks/useSearch";
import { QueryItemView } from "../../query/QueryItemView";

interface QueryResolutionForm {
	resolvedDetails: string;
}

function EditQueryCode({
	code: defaultCode,
	onSet,
}: {
	code: string;
	onSet(partialItem: Partial<ZoneListItem>): void;
}) {
	const [code, searchQuery, setCode] = useSearch(300, defaultCode);
	const results = useStockLookup({ code: searchQuery }, { enabled: searchQuery.length > 3 });
	return (
		<YStack space="$2">
			<H4>Item Code</H4>
			<Input
				value={code}
				onChangeText={(inputValue: string) => {
					setCode(inputValue.replace(/[^0-9]/g, ""));
				}}
			/>
			{(results.data?.records.length ?? 0) > 0 && (
				<YStack space="$2">
					{results.data?.records.slice(0, 15).map((result) => (
						<Button
							key={result.Barcode}
							onPress={() => {
								onSet({
									code: result.Barcode,
									retailStockCode: result.Stock_Code,
									retailDescription: result.Full_Desc,
									retailStockBalance: result.Retail_Stock_Balance,
									retailPrice: result.BandA_Sell,
								});
							}}
						>
							<YStack>
								<H6 m="$0">{result.Barcode}</H6>
								<Paragraph lineHeight="$1">{result.Full_Desc}</Paragraph>
							</YStack>
						</Button>
					))}
				</YStack>
			)}
		</YStack>
	);
}

function EditQueryZoneItem({
	zoneItem,
	updateZoneItem,
	deleteZoneItem,
}: {
	zoneItem: ZoneListItem & { deleted?: boolean; countString?: string; isNew?: boolean };
	updateZoneItem(zoneItem: ZoneListItem & { deleted?: boolean; countString?: string }): void;
	deleteZoneItem(): void;
}) {
	const [showEditCode, setShowEditCode] = React.useState(!!zoneItem.isNew);
	return (
		<YStack>
			<Alert
				display={showEditCode}
				onCancel={() => {
					setShowEditCode(false);
					if (zoneItem.isNew && !zoneItem.code) {
						deleteZoneItem();
					}
				}}
			>
				<EditQueryCode
					key={zoneItem.code}
					code={zoneItem.code}
					onSet={(partialItem) => {
						console.log(zoneItem, partialItem);
						updateZoneItem({
							...zoneItem,
							...partialItem,
						});
						setShowEditCode(false);
					}}
				/>
			</Alert>
			<XStack ai="center">
				<XStack space="$2" ai="center" flex={1}>
					<YStack>
						<H6>{zoneItem.code ? zoneItem.code : "No item selected"}</H6>
						<Paragraph color="$color" lineHeight="$1">
							{zoneItem.retailDescription}
						</Paragraph>
					</YStack>
					<Button
						opacity={!zoneItem?.deleted ? 1 : 0.5}
						disabled={zoneItem?.deleted}
						size="$3"
						icon={Edit}
						onPress={() => setShowEditCode(true)}
					/>
				</XStack>

				<Input
					keyboardType="numeric"
					width="$8"
					textAlign="right"
					placeholder="Qty"
					selectTextOnFocus
					opacity={!zoneItem?.deleted ? 1 : 0.5}
					editable={!zoneItem?.deleted}
					disabled={zoneItem?.deleted}
					value={zoneItem.countString ?? String(zoneItem.count)}
					onChangeText={(inputValue: string) => {
						const formattedValue = inputValue.replace(/[^0-9]/g, "");
						updateZoneItem({ ...zoneItem, countString: formattedValue });
					}}
					onBlur={() => {
						if (zoneItem.countString == "") {
							deleteZoneItem();
						}
					}}
				/>
			</XStack>
		</YStack>
	);
}

const quickResponses = ["Done", "I've sorted this", "Thank you"];

export function AdminQueryResolve() {
	const params = useParams();
	const navigate = useNavigate();

	const getQueryQuery = useGetQuery({
		zoneItemQueryId: Number(params.zoneItemQueryId),
	});
	const listQueryImagesQuery = useListQueryImages({
		zoneItemQueryId: Number(params.zoneItemQueryId),
	});
	const updateQueryQuery = useAdminUpdateQueryResolved();
	const deleteZoneItemQuery = useDeleteZoneItem();
	const createZoneItemQuery = useCreateZoneItem();
	const updateZoneItemQuery = useUpdateZoneItem();

	const [formError, setFormError] = React.useState<string>();
	const [queryResolutionForm, setQueryResolutionForm] = React.useState<QueryResolutionForm>({
		resolvedDetails: "",
	});
	const [hasSetZoneItem, setHasSetZoneItem] = React.useState<number | null>(null);
	const [zoneItem, setZoneItem] = React.useState<
		| (ZoneListItem & {
				deleted?: boolean;
				countString?: string;
				hasEdited?: boolean;
				isNew?: boolean;
		  })
		| null
	>(null);

	const isPageLoading = getQueryQuery.isLoading || listQueryImagesQuery.isLoading;
	const isQueryFound = getQueryQuery.isFetched && getQueryQuery.data?.data?.result != null;
	const isQueryResolved =
		getQueryQuery.isFetched && getQueryQuery.data?.data?.result?.resolvedAt != null;

	React.useEffect(() => {
		const newZoneItem = getQueryQuery.data?.data.zoneItem;
		if (newZoneItem && hasSetZoneItem !== newZoneItem.zoneItemId) {
			setHasSetZoneItem(newZoneItem.zoneItemId);
			setZoneItem({ ...newZoneItem });
		} else if (getQueryQuery.data && !newZoneItem && hasSetZoneItem !== null) {
			setZoneItem(null);
			setHasSetZoneItem(null);
		}
	}, [getQueryQuery.data]);

	function navigateBack() {
		navigate(-1);
	}

	function isValidTextLength(value: string) {
		return value.length > 0;
	}

	function validateForm(formData: QueryResolutionForm) {
		if (!isValidTextLength(formData.resolvedDetails)) {
			setFormError("Please enter query resolution details");
			return false;
		}

		setFormError(undefined);
		return true;
	}

	async function handleResolveQuery() {
		if (!getQueryQuery.data?.data.result) return;

		// Validate form has minimum of some resolution details text
		if (!validateForm({ ...queryResolutionForm })) return;

		let zoneItemId = zoneItem?.deleted ? null : zoneItem?.zoneItemId;
		if (zoneItem?.isNew) {
			const result = await createZoneItemQuery.mutateAsync({
				data: {
					zoneId: getQueryQuery.data.data.result.zoneId,
					code: zoneItem.code,
					count: zoneItem.countString ? Number(zoneItem.countString) : zoneItem.count,
					scannedAt: new Date().toISOString(),
					retailStockCode: zoneItem.retailStockCode,
					retailDescription: zoneItem.retailDescription,
					retailStockBalance: zoneItem.retailStockBalance,
					retailPrice: zoneItem.retailPrice,
				},
			});
			zoneItemId = result.data.zoneItemId;
		} else if (zoneItem && !zoneItem?.deleted) {
			await updateZoneItemQuery.mutateAsync({
				data: {
					zoneItemId: zoneItem.zoneItemId,
					code: zoneItem.code,
					count: zoneItem.countString ? Number(zoneItem.countString) : zoneItem.count,
					retailStockCode: zoneItem.retailStockCode,
					retailDescription: zoneItem.retailDescription,
					retailStockBalance: zoneItem.retailStockBalance,
					retailPrice: zoneItem.retailPrice,
				},
			});
		}

		const response = await updateQueryQuery.mutateAsync({
			data: {
				zoneItemQueryId: getQueryQuery.data.data.result?.zoneItemQueryId,
				resolvedDetails: queryResolutionForm.resolvedDetails,
				zoneItemId,
			},
		});

		if (zoneItem?.deleted && getQueryQuery.data.data.result?.zoneItemId) {
			await deleteZoneItemQuery.mutateAsync({
				params: {
					zoneItemId: getQueryQuery.data.data.result?.zoneItemId,
				},
			});
		}

		if (response.data.result) navigateBack();
		else setFormError("An error occurred");
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title="Resolve Query" />

					{isPageLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!isPageLoading && !isQueryFound && <H4>Query not found</H4>}
					{!isPageLoading && isQueryResolved && <H4>Query already resolved</H4>}

					{!isPageLoading &&
						!isQueryResolved &&
						isQueryFound &&
						getQueryQuery.data?.data.result != null && (
							<>
								<QueryItemView
									queryItem={getQueryQuery.data?.data.result}
									hideViewButton
									showQueryTitle
								/>

								<YStack my="$3" space="$3">
									<YStack
										theme={
											zoneItem?.deleted ? "red" : zoneItem && !hasSetZoneItem ? "green" : undefined
										}
										space="$3"
										p="$2"
										br="$4"
										bc="$backgroundSoft"
									>
										<XStack ai="center">
											<YStack flex={1}>
												<H4>Zone Item</H4>
												<Paragraph>
													{hasSetZoneItem
														? zoneItem?.deleted
															? "Zone item will be deleted"
															: zoneItem?.hasEdited
															? "Zone item will be changed"
															: "Zone item will not be changed"
														: zoneItem
														? "New Zone Item will be created"
														: "No Zone Item will be created"}
												</Paragraph>
											</YStack>
											{zoneItem &&
												(zoneItem.deleted ? (
													<Button
														size="$3"
														icon={Undo}
														onPress={() => {
															setZoneItem({
																...zoneItem,
																deleted: undefined,
																countString: zoneItem.countString
																	? zoneItem.countString
																	: String(zoneItem.count),
															});
														}}
													>
														Undo Deletion
													</Button>
												) : (
													<Button
														size="$3"
														icon={Trash}
														theme="red"
														onPress={() => {
															if (!hasSetZoneItem) setZoneItem(null);
															else setZoneItem({ ...zoneItem, deleted: true });
														}}
													>
														{hasSetZoneItem ? "Delete" : "Cancel Creation"}
													</Button>
												))}
										</XStack>

										{zoneItem ? (
											<EditQueryZoneItem
												zoneItem={zoneItem}
												updateZoneItem={(zoneItem) => setZoneItem({ ...zoneItem, hasEdited: true })}
												deleteZoneItem={() => {
													if (!hasSetZoneItem) setZoneItem(null);
													else setZoneItem({ ...zoneItem, deleted: true });
												}}
											/>
										) : (
											<Button
												onPress={() =>
													setZoneItem({
														code: "",
														count: 1,
														retailDescription: undefined,
														retailPrice: undefined,
														retailStockBalance: undefined,
														retailStockCode: undefined,
														scannedAt: new Date().toString(),
														scannedBy: -1,
														zoneCode: getQueryQuery.data!.data.result!.zoneCode,
														zoneId: getQueryQuery.data!.data.result!.zoneId,
														zoneItemId: -1,
														zoneStocktakeId: -1,
														isNew: true,
													})
												}
												alignSelf="center"
												my="$4"
												icon={Plus}
											>
												Create Zone Item
											</Button>
										)}
									</YStack>

									<XStack my="$2" space="$2" ai="flex-end" fw="wrap" jc="space-between">
										<Paragraph mb="$1">Query resolution details</Paragraph>

										<XStack space="$2" fw="wrap" ai="center">
											{quickResponses.map((response, idx) => (
												<Button
													size="$2"
													key={idx}
													onPress={() => setQueryResolutionForm({ resolvedDetails: response })}
												>
													{response}
												</Button>
											))}
										</XStack>
									</XStack>
									<TextArea
										mb="$3"
										height="$9"
										textAlignVertical="top"
										paddingVertical="$3"
										autoFocus
										value={queryResolutionForm.resolvedDetails}
										onChangeText={(inputValue: string) => {
											setQueryResolutionForm({ resolvedDetails: inputValue });
										}}
									/>

									<YStack my="$3" space="$3">
										<XStack justifyContent="flex-end" space="$3">
											<Button
												disabled={updateQueryQuery.isLoading}
												onPress={() => handleResolveQuery()}
											>
												{hasSetZoneItem
													? zoneItem?.deleted
														? "Delete item and resolve"
														: zoneItem?.hasEdited
														? "Edit item and resolve"
														: "Resolve without changes"
													: zoneItem
													? "Create item and resolve"
													: "Resolve without changes"}
											</Button>
										</XStack>

										{formError && (
											<Paragraph m={0} color="$red9">
												{formError}
											</Paragraph>
										)}
									</YStack>
								</YStack>
							</>
						)}
				</>
			</PageScrollNarrow>
		</PageContainer>
	);
}
