import * as Application from "expo-application";
import { Platform } from "react-native";

const applicationId = Platform.OS === "web" ? "" : Application.applicationId!;
const host = Platform.OS === "web" ? window.location.host : "";

let appVariant, appScheme;
if (applicationId.endsWith(".dev") || host.includes("localhost")) {
	appVariant = "development";
	appScheme = "stavertonappdev";
} else if (applicationId.endsWith(".beta") || host.includes(".test")) {
	appVariant = "beta";
	appScheme = "stavertonappbeta";
} else {
	appVariant = "production";
	appScheme = "stavertonapp";
}

export const APP_VARIANT = appVariant;
export const APP_SCHEME = appScheme;
