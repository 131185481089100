import { AlertTriangle, Eye, EyeOff } from "@tamagui/lucide-icons";
import * as React from "react";
import { useState } from "react";
import { Platform } from "react-native";
import { H6, Paragraph, YStack, XStack, H4, Separator, useTheme } from "tamagui";

import { useScanBarcodes } from "../../../plugins/frameProcessors/js/codeScanner";
import { isIgnored, StockLookupResponseRecord, useStockLookup } from "../../common/api/multiRetail";
import { useHasCamera } from "../../common/camera/camera";
import { Button } from "../../common/components";
import { Alert } from "../../common/components/Alert";
import { ManagedScanner } from "../../common/components/ManagedScanner";
import { PageContainer, PageScrollNarrow } from "../../common/components/Page";
import { formatPrice } from "../../common/number/number";
import { playHaptic, playSound } from "../../common/sound";

interface LookupItem {
	code: string;
}

function WarningIcon() {
	const theme = useTheme();
	return <AlertTriangle size={16} color={theme.color.val} />;
}

function LookupProductItem({
	item,
	setItem,
	setEditItem,
	setIsCameraActive,
	setShowEnterBarcode,
}: {
	item: LookupItem;
	setItem: (value?: LookupItem) => void;
	setEditItem: (value?: LookupItem) => void;
	setIsCameraActive: React.Dispatch<React.SetStateAction<boolean>>;
	setShowEnterBarcode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [checkStockCode, setCheckStockCode] = React.useState(false);
	const query = useStockLookup({ code: item.code, checkStockCode });
	const hasLoaded = query.isFetched;
	const resultsExactMatch =
		query.data?.records.filter(
			(result) => result.Barcode === item.code || String(result.Stock_Code) === item.code
		) ?? [];
	const queryResults = resultsExactMatch.length ? resultsExactMatch : query.data?.records ?? [];

	const [displayAlertUnrecognised, setDisplayAlertUnrecognised] = React.useState<boolean>(false);

	const [showIgnored, setShowIgnored] = useState(false);

	let unignoredResults: StockLookupResponseRecord[];
	let ignoredResults: StockLookupResponseRecord[];
	{
		const actualUnignoredResults = queryResults.filter((i) => !isIgnored(i));
		if (actualUnignoredResults.length === 0) {
			unignoredResults = queryResults;
			ignoredResults = [];
		} else {
			unignoredResults = actualUnignoredResults;
			ignoredResults = queryResults.filter((i) => isIgnored(i));
		}
	}

	React.useEffect(() => {
		if (hasLoaded) {
			if (queryResults.length) {
				playSound("high");
				playHaptic("high");
			} else if (!checkStockCode) {
				// Not valid barcode found, check stock code before declaring it as invalid
				setCheckStockCode(true);
			} else {
				playSound("low");
				playHaptic("low");

				setDisplayAlertUnrecognised(true);
			}
		}
	}, [hasLoaded]);

	const renderResult = (result: StockLookupResponseRecord, idx: number) => {
		return (
			<YStack my="$3" space="$3" key={idx}>
				{idx > 0 && <Separator mb="$3" />}
				<YStack flex={1}>
					<H6>{result.Barcode}</H6>
					<Paragraph color="$color">
						{query.isLoading ? "Loading..." : result?.Full_Desc ?? "Invalid code"}
					</Paragraph>
				</YStack>
				{!!result?.Deleted && (
					<XStack ai="center" theme="orange" bc="$backgroundStrong" br="$2" p="$2" space="$2">
						<WarningIcon />
						<Paragraph color="$color">This product has been deleted</Paragraph>
					</XStack>
				)}
				{!!result?.Suspended && (
					<XStack ai="center" theme="orange" bc="$backgroundStrong" br="$2" p="$2" space="$2">
						<WarningIcon />
						<Paragraph color="$color">This product has been suspended</Paragraph>
					</XStack>
				)}
				{!!result?.Discarded && (
					<XStack ai="center" theme="orange" bc="$backgroundStrong" br="$2" p="$2" space="$2">
						<WarningIcon />
						<Paragraph color="$color">This product has been discarded</Paragraph>
					</XStack>
				)}
				<XStack flex={1} alignItems="center" justifyContent="space-between">
					<Paragraph color="$color">Price</Paragraph>
					<H4 color="$color">{result?.BandA_Sell ? formatPrice(result?.BandA_Sell) : ""}</H4>
				</XStack>
				<XStack flex={1} alignItems="center" justifyContent="space-between">
					<Paragraph color="$color">In stock</Paragraph>
					<H4 color="$color">{result?.Retail_Stock_Balance.toString() ?? ""}</H4>
				</XStack>
				<XStack flex={1} alignItems="center" justifyContent="space-between">
					<Paragraph color="$color">Stock code</Paragraph>
					<H4 color="$color">{result?.Stock_Code.toString() ?? ""}</H4>
				</XStack>
			</YStack>
		);
	};

	return (
		<YStack key={item.code} width="100%">
			{query.isLoading && (
				<YStack flex={1}>
					<H6>{item.code}</H6>
					<Paragraph color="$color">Loading...</Paragraph>
				</YStack>
			)}

			{!query.isLoading && !queryResults.length && (
				<YStack flex={1}>
					<H6>{item.code}</H6>
					<Paragraph color="$color">Invalid code</Paragraph>
				</YStack>
			)}

			{!query.isLoading && !!queryResults.length && (
				<>
					{unignoredResults.map(renderResult)}
					{ignoredResults.length > 0 && (
						<>
							<Separator mb="$3" />
							<Button
								size="$2"
								chromeless
								onPress={() => setShowIgnored(!showIgnored)}
								icon={showIgnored ? Eye : EyeOff}
							>
								{showIgnored
									? "Hide Deleted/Suspended/Discarded"
									: "Show Deleted/Suspended/Discarded"}
							</Button>
							<YStack space="$3">{showIgnored && ignoredResults.map(renderResult)}</YStack>
						</>
					)}
				</>
			)}

			<Alert
				display={displayAlertUnrecognised}
				title="Unrecognised barcode"
				message={`Barcode ${item.code} has not been recognised`}
				buttons={[
					...(Platform.OS === "web"
						? []
						: [
								{
									text: "Rescan",
									onPress: () => {
										setItem();
										setIsCameraActive(true);
									},
								},
						  ]),

					{
						text: "Enter code manually",
						onPress: () => {
							setItem();
							setEditItem({ code: item.code });
							setShowEnterBarcode(true);
						},
					},
				]}
				onClose={() => setDisplayAlertUnrecognised(false)}
				onCancel={() => setItem()}
			/>
		</YStack>
	);
}

export function LookupProduct() {
	const [lookupItem, setLookupItem] = React.useState<LookupItem>();
	const [isCameraActive, setIsCameraActive] = React.useState(false);
	const [showEnterBarcode, setShowEnterBarcode] = React.useState(false);
	const [editLookupItem, setEditLookupItem] = React.useState<LookupItem>({
		code: "",
	});

	const hasCamera = useHasCamera();

	const [frameProcessor, barcodes] = useScanBarcodes(isCameraActive);

	React.useEffect(() => {
		const barcode = barcodes[0];
		if (!isCameraActive || !barcode || lookupItem?.code) return;

		setLookupItem({ code: String(barcode.displayValue || "") });
		setIsCameraActive(false);

		playHaptic("mid");
		playSound("mid");
	}, [barcodes]);

	return (
		<PageContainer>
			<YStack flex={1} maxWidth={800} w="100%" als="center">
				<ManagedScanner
					frameProcessor={frameProcessor}
					isActive={isCameraActive}
					startScanning={() => {
						setLookupItem(undefined);
						setIsCameraActive(true);
					}}
				/>
			</YStack>

			<PageScrollNarrow>
				{lookupItem ? (
					<LookupProductItem
						item={lookupItem}
						setItem={setLookupItem}
						setEditItem={(val) => setEditLookupItem({ code: String(val?.code || "") })}
						setIsCameraActive={setIsCameraActive}
						setShowEnterBarcode={setShowEnterBarcode}
						key={lookupItem.code}
					/>
				) : (
					<YStack>
						<H4 mb="$3">Scan barcode or stock code to lookup product</H4>
						<Button
							onPress={() => {
								setShowEnterBarcode(true);
								setEditLookupItem({ code: "" });
								setIsCameraActive(false);
							}}
						>
							Enter code manually
						</Button>
					</YStack>
				)}

				<Alert
					display={showEnterBarcode}
					title="Enter code manually"
					inputs={[
						{
							defaultValue: editLookupItem.code.toString(),
							placeholder: "123456",
							keyboardType: "numeric",
							autoFocus: true,
						},
					]}
					buttons={[
						{
							text: "Done",
							onPress: (response) => {
								let inputZone = response?.inputs?.[0]?.value || "";
								inputZone = inputZone.replace(/\W/g, "");
								setLookupItem({ ...editLookupItem, code: inputZone });
							},
						},
					]}
					onClose={() => {
						setShowEnterBarcode(false);
					}}
					onCancel={() => {
						setLookupItem(undefined);
					}}
				/>
			</PageScrollNarrow>

			<YStack p="$3" space="$3" maxWidth={800} w="100%" $gtSm={{ p: "$6" }} als="center">
				{lookupItem && !hasCamera && (
					<Button
						onPress={() => {
							setLookupItem(undefined);
							setEditLookupItem({ code: "" });
							setShowEnterBarcode(true);
						}}
					>
						Enter another barcode
					</Button>
				)}
				{lookupItem && hasCamera && (
					<Button
						onPress={() => {
							setLookupItem(undefined);
							setIsCameraActive(true);
						}}
					>
						Scan another item
					</Button>
				)}
				{!lookupItem && hasCamera && (
					<Button
						disabled={isCameraActive}
						onPress={() => {
							setIsCameraActive(true);
						}}
					>
						Scan
					</Button>
				)}
			</YStack>
		</PageContainer>
	);
}
