import { LogOut, Bug, Cog, Sun, Moon } from "@tamagui/lucide-icons";
import { useAtom } from "jotai";
import * as React from "react";
import { useNavigate } from "react-router";
import { YStack, XStack, Paragraph, Switch, Spinner, XGroup } from "tamagui";

import { UserNotificationTopicItem } from "../../common/api/staverton.openapi/index.schemas";
import {
	useListUserNotificationTopics,
	useUpdateUserNotificationTopic,
} from "../../common/api/staverton.openapi/user-notification-topic";
import { useAuth, useAuthLogout } from "../../common/auth/useAuth";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";
import { themeAtom } from "../../common/storage/atoms";

export function ProfileView() {
	const auth = useAuth();
	const authLogout = useAuthLogout();
	const [theme, setTheme] = useAtom(themeAtom);
	const navigate = useNavigate();

	const [userTopics, setUserTopics] = React.useState<UserNotificationTopicItem[]>([]);

	const listUserNotificationTopicsQuery = useListUserNotificationTopics();
	const updateUserNotificationTopicQuery = useUpdateUserNotificationTopic();

	React.useEffect(() => {
		if (
			!listUserNotificationTopicsQuery.isFetched ||
			!listUserNotificationTopicsQuery.data?.data.results
		)
			return;

		setUserTopics(listUserNotificationTopicsQuery.data.data.results);
	}, [listUserNotificationTopicsQuery.isFetchedAfterMount]);

	function getTopicTitle(topicKey: string) {
		switch (topicKey) {
			case "admin_query_new":
				return "Query is created";
			case "admin_zone_complete":
				return "Zone is completed";
			case "admin_zone_takeover":
				return "Zone is taken over by a user";
			case "my_zone_takeover":
				return "Zone that I started is taken over by another user";

			default:
				return "";
		}
	}

	async function handleTopicActiveChange(topic: UserNotificationTopicItem, selected: boolean) {
		setUserTopics(
			userTopics.map((userTopic) =>
				userTopic === topic ? { ...topic, active: selected } : userTopic
			)
		);

		await updateUserNotificationTopicQuery.mutateAsync({
			data: {
				userNotificationTopicId: topic.userNotificationTopicId,
				active: selected,
			},
		});
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<PageHeader allowBack title="Profile">
					<Button icon={LogOut} onPress={authLogout}>
						Logout
					</Button>
				</PageHeader>

				<YStack space="$3">
					<YStack>
						<Paragraph m={0} color="$colorTranslucent">
							Name
						</Paragraph>
						<Paragraph size="$5" m={0} color="$color">
							{auth.details?.name}
						</Paragraph>
					</YStack>

					<YStack>
						<Paragraph m={0} color="$colorTranslucent">
							Email
						</Paragraph>
						<Paragraph size="$5" m={0} color="$color">
							{auth.details?.email}
						</Paragraph>
					</YStack>

					<YStack>
						<Paragraph m={0} color="$colorTranslucent">
							Role
						</Paragraph>
						<Paragraph size="$5" m={0} color="$color">
							{auth.isAdmin ? "Admin" : "Stocktaker"}
						</Paragraph>
					</YStack>

					<YStack space="$2">
						<Paragraph m={0} color="$colorTranslucent">
							Alert Notifications
						</Paragraph>

						{listUserNotificationTopicsQuery.isLoading && (
							<XStack jc="center">
								<Spinner />
							</XStack>
						)}
						{!listUserNotificationTopicsQuery.isLoading && userTopics.length === 0 && (
							<Paragraph ta="center" color="$colorTranslucent">
								No alert notification options to show
							</Paragraph>
						)}

						{!listUserNotificationTopicsQuery.isLoading &&
							userTopics.map((topic) => (
								<XStack ai="center" space="$3" mb="$2" key={topic.userNotificationTopicId}>
									<Switch
										p={0}
										size="$2"
										checked={topic.active}
										onCheckedChange={(val) => handleTopicActiveChange(topic, val)}
									>
										<Switch.Thumb animation="quick" />
									</Switch>
									<Paragraph
										flex={1}
										size="$4"
										m={0}
										lh="$1"
										color="$color"
										onPress={() => handleTopicActiveChange(topic, !topic.active)}
									>
										{getTopicTitle(topic.topic)}
									</Paragraph>
								</XStack>
							))}
					</YStack>

					<YStack space="$2">
						<Paragraph m={0} color="$colorTranslucent">
							Theme
						</Paragraph>
						<XGroup>
							<Button
								flex={1}
								bg={theme === "system" ? "$backgroundStrong" : undefined}
								icon={Cog}
								onPress={() => setTheme("system")}
							>
								System
							</Button>
							<Button
								flex={1}
								bg={theme === "light" ? "$backgroundStrong" : undefined}
								icon={Sun}
								onPress={() => setTheme("light")}
							>
								Light
							</Button>
							<Button
								flex={1}
								bg={theme === "dark" ? "$backgroundStrong" : undefined}
								icon={Moon}
								onPress={() => setTheme("dark")}
							>
								Dark
							</Button>
						</XGroup>
					</YStack>

					<YStack space="$2">
						<Paragraph m={0} color="$colorTranslucent">
							Debugging Tools
						</Paragraph>
						<Button theme="red" icon={Bug} onPress={() => navigate("/debug")}>
							Debugging Tools
						</Button>
					</YStack>
				</YStack>
			</PageScrollNarrow>
		</PageContainer>
	);
}
