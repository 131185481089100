import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelToken } from "axios";

import { useAuth, UseAuthResponse } from "../auth/useAuth";

async function doRequest(
	axios: AxiosInstance,
	config: AxiosRequestConfig,
	cancelToken: CancelToken,
	auth?: UseAuthResponse
) {
	const token = auth?.isAuthenticated ? await auth.getToken() : null;
	return axios({
		...config,
		cancelToken,
		headers: {
			...(token ? { Authorization: `Bearer ${token.accessToken}` } : {}),
			...config.headers,
		},
	});
}

const apiEndpoint = process.env.STAVERTON_API_ENDPOINT;

if (!apiEndpoint) {
	throw new Error("STAVERTON_API_ENDPOINT is not set");
}

axios.defaults.baseURL = apiEndpoint;

export const AXIOS_INSTANCE = axios.create({ baseURL: apiEndpoint });

export const useCustomInstance = <T>(): ((
	config: AxiosRequestConfig
) => Promise<AxiosResponse<T>>) => {
	const auth = useAuth();
	return (config: AxiosRequestConfig) => {
		const source = axios.CancelToken.source();
		const promise = doRequest(AXIOS_INSTANCE, config, source.token, auth);

		// @ts-expect-error Cancel doesn't exist on promise by default
		promise.cancel = () => {
			source.cancel("Query was cancelled by React Query");
		};

		return promise;
	};
};

export const useUnauthenticatedInstance = <T>(): ((
	config: AxiosRequestConfig
) => Promise<AxiosResponse<T>>) => {
	return (config: AxiosRequestConfig) => {
		const source = axios.CancelToken.source();
		const promise = doRequest(AXIOS_INSTANCE, config, source.token);

		// @ts-expect-error Cancel doesn't exist on promise by default
		promise.cancel = () => {
			source.cancel("Query was cancelled by React Query");
		};

		return promise;
	};
};

export default useCustomInstance;
