import * as React from "react";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import { H6, Paragraph, YStack, Spinner, XStack, H4 } from "tamagui";

import { MultipartUploadDetails } from "../../common/api/staverton.openapi/index.schemas";
import { useCreateQuery } from "../../common/api/staverton.openapi/query";
import {
	useCompleteQueryImageUpload,
	useStartQueryImageUpload,
} from "../../common/api/staverton.openapi/query-image";
import { useGetZoneItem } from "../../common/api/staverton.openapi/zone-item";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";
import { QueryEditForm, QueryItem, startUploadingQueryImage } from "./QueryEditForm";

export function QueryNew() {
	const navigate = useNavigate();
	const params = useParams();

	const zoneItemQuery = useGetZoneItem(
		{
			zoneItemId: Number(params.zoneItemId),
		},
		{ query: { enabled: !!params.zoneItemId } }
	);

	const isQueryItem = !!params.zoneItemId;
	const isQueryItemFound = zoneItemQuery.isFetched && !!zoneItemQuery?.data?.data?.result;

	const createQueryQuery = useCreateQuery();
	const startQueryImageUpload = useStartQueryImageUpload();
	const completeQueryImageUpload = useCompleteQueryImageUpload();

	const [isUploadingToS3, setIsUploadingToS3] = React.useState<boolean>(false);
	const [createQueryFormError, setCreateQueryFormError] = React.useState<string>();
	const [newQueryItem, setNewQueryItem] = React.useState<QueryItem>({
		zoneItemQueryId: null,
		zoneItemId: Number(params.zoneItemId) || null,
		zoneCode: params.zoneCode ?? "",
		details: "",
		images: [],
	});

	const queryClient = useQueryClient();

	function navigateToZone() {
		navigate(`/zone/scan/${params.zoneCode}`, { replace: true });
	}

	function isValidTextLength(value: string) {
		return value.length > 3;
	}

	function validateForm(formData: QueryItem) {
		if (!isValidTextLength(formData.details)) {
			setCreateQueryFormError("Please enter query details");
			return false;
		}

		setCreateQueryFormError(undefined);
		return true;
	}

	async function handleCreateQuery() {
		if (isQueryItem && !zoneItemQuery.data?.data.result) return;

		// Validate form has minimum of some details text
		if (!validateForm({ ...newQueryItem })) return;

		// Upload the images to s3, any error should exit early
		setIsUploadingToS3(true);
		const uploadResults: {
			eTags: string[];
			details: MultipartUploadDetails;
			fileName: string;
		}[] = [];

		for (const image of newQueryItem.images) {
			const startUploadResult = await startUploadingQueryImage(
				image,
				newQueryItem.zoneCode,
				newQueryItem.zoneItemId,
				startQueryImageUpload
			);

			if (startUploadResult.success) {
				uploadResults.push({
					eTags: startUploadResult.eTags,
					details: startUploadResult.details,
					fileName: image.fileName ?? "",
				});
			} else {
				setIsUploadingToS3(false);
				setCreateQueryFormError(startUploadResult.errorMessage);
			}
		}

		setIsUploadingToS3(false);
		if (newQueryItem.images.length !== uploadResults.length) return;

		// Create the DB item query
		const createQueryResult = await createQueryQuery
			.mutateAsync({
				data: {
					zoneItemId: newQueryItem.zoneItemId,
					zoneCode: newQueryItem.zoneCode,
					details: newQueryItem.details,
				},
			})
			.catch((e) => {
				console.info(`create query error`, e);
				setCreateQueryFormError(`Failed to create query`);
			});
		if (!createQueryResult) return;

		// Complete all image uploads and add the DB query Images
		for (const uploadResult of uploadResults) {
			const imageResp = await completeQueryImageUpload
				.mutateAsync({
					data: {
						...uploadResult,
						zoneItemQueryId: createQueryResult.data.zoneItemQueryId,
					},
				})
				.catch((e) => {
					console.error(`finishing image catch`, e);
				});

			if (!imageResp) {
				setCreateQueryFormError(`Failed to upload ${uploadResult.fileName}`);
				return;
			}
		}

		queryClient.invalidateQueries();
		navigateToZone();
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title="New Query" />

					{zoneItemQuery.isLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{isQueryItem && !isQueryItemFound && <H4>Item not found</H4>}

					{isQueryItem && isQueryItemFound && (
						<H6 mb="$3">Item {zoneItemQuery.data?.data.result?.code}</H6>
					)}

					{!isQueryItem && <H6 mb="$3">Zone {params.zoneCode}</H6>}

					{(!isQueryItem || isQueryItemFound) && (
						<QueryEditForm query={newQueryItem} setQuery={setNewQueryItem} />
					)}
				</>
			</PageScrollNarrow>

			<YStack maxWidth={800} w="100%" space="$3" p="$3" $gtSm={{ p: "$6" }} als="center">
				{createQueryFormError && (
					<Paragraph color="$red9" w="100%">
						{createQueryFormError}
					</Paragraph>
				)}
				<XStack space="$3">
					{(!isQueryItem || isQueryItemFound) && (
						<Button
							flex={1}
							disabled={
								createQueryQuery.isLoading ||
								startQueryImageUpload.isLoading ||
								completeQueryImageUpload.isLoading ||
								isUploadingToS3
							}
							onPress={handleCreateQuery}
						>
							Create Query
						</Button>
					)}
				</XStack>
			</YStack>

			<>
				{(createQueryQuery.isLoading ||
					startQueryImageUpload.isLoading ||
					completeQueryImageUpload.isLoading ||
					isUploadingToS3) && (
					<YStack
						position="absolute"
						top={0}
						bottom={0}
						left={0}
						right={0}
						key="uploading"
						ai="center"
						jc="center"
					>
						<YStack pos="absolute" t={0} b={0} l={0} r={0} bc="black" o={0.5} />
						<YStack bc="$backgroundSoft" ai="center" jc="center" br="$3" p="$3">
							<H6>Uploading</H6>
							<Spinner />
						</YStack>
					</YStack>
				)}
			</>
		</PageContainer>
	);
}
