/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	MultiRetailBarcodeLookupResponse,
	LookupBarcodeParams,
	MultiRetailAvailabilityLookupResponse,
	LookupAvailabilityParams,
} from "./index.schemas";

export const useLookupBarcodeHook = () => {
	const lookupBarcode = useCustomInstance<MultiRetailBarcodeLookupResponse>();

	return (params?: LookupBarcodeParams, signal?: AbortSignal) => {
		return lookupBarcode({ url: `/OpenRetail/LookupBarcode`, method: "get", params, signal });
	};
};

export const getLookupBarcodeQueryKey = (params?: LookupBarcodeParams) => [
	`/OpenRetail/LookupBarcode`,
	...(params ? [params] : []),
];

export type LookupBarcodeQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useLookupBarcodeHook>>>
>;
export type LookupBarcodeQueryError = unknown;

export const useLookupBarcode = <
	TData = Awaited<ReturnType<ReturnType<typeof useLookupBarcodeHook>>>,
	TError = unknown
>(
	params?: LookupBarcodeParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useLookupBarcodeHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLookupBarcodeQueryKey(params);

	const lookupBarcode = useLookupBarcodeHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useLookupBarcodeHook>>>> = ({
		signal,
	}) => lookupBarcode(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useLookupBarcodeHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useLookupAvailabilityHook = () => {
	const lookupAvailability = useCustomInstance<MultiRetailAvailabilityLookupResponse>();

	return (params?: LookupAvailabilityParams, signal?: AbortSignal) => {
		return lookupAvailability({
			url: `/OpenRetail/LookupAvailability`,
			method: "get",
			params,
			signal,
		});
	};
};

export const getLookupAvailabilityQueryKey = (params?: LookupAvailabilityParams) => [
	`/OpenRetail/LookupAvailability`,
	...(params ? [params] : []),
];

export type LookupAvailabilityQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useLookupAvailabilityHook>>>
>;
export type LookupAvailabilityQueryError = unknown;

export const useLookupAvailability = <
	TData = Awaited<ReturnType<ReturnType<typeof useLookupAvailabilityHook>>>,
	TError = unknown
>(
	params?: LookupAvailabilityParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useLookupAvailabilityHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getLookupAvailabilityQueryKey(params);

	const lookupAvailability = useLookupAvailabilityHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useLookupAvailabilityHook>>>
	> = ({ signal }) => lookupAvailability(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useLookupAvailabilityHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};
