import * as React from "react";
import { useNavigate } from "react-router";
import { H6, YStack, H4, XStack, Unspaced } from "tamagui";

import { useOCR } from "../../../plugins/frameProcessors/js/ocr";
import { ZoneItem, ZoneItemStatus } from "../../common/api/staverton.openapi/index.schemas";
import { useGetZone, useUpdateZone } from "../../common/api/staverton.openapi/zone";
import { useHasCamera } from "../../common/camera/camera";
import { Button } from "../../common/components";
import { AlertButton, Alert } from "../../common/components/Alert";
import { ManagedScanner } from "../../common/components/ManagedScanner";
import { PageContainer, PageScrollNarrow } from "../../common/components/Page";

interface ZoneStartItem {
	code: string;
	matchedZone?: ZoneItem;
}

function ZoneStartNewItem({
	item,
	setItem,
	setIsCameraActive,
	setShowEnterBarcode,
}: {
	item: ZoneStartItem;
	setItem: (value?: ZoneStartItem) => void;
	setIsCameraActive: (value: boolean) => void;
	setShowEnterBarcode: (value: boolean) => void;
}) {
	const hasCamera = useHasCamera();

	const zoneQuery = useGetZone({
		zoneCode: String(item.code),
	});

	interface ErrorAlert {
		display: boolean;
		title: string;
		message?: string;
		buttons?: AlertButton[];
	}
	const [errorAlert, setErrorAlert] = React.useState<ErrorAlert>();

	function createAlertMissingNumber() {
		const buttons = [
			{
				text: "Rescan",
				onPress: () => {
					setIsCameraActive(true);
					setItem();
				},
			},
			{
				text: "Enter zone number manually",
				onPress: () => {
					setShowEnterBarcode(true);
					setItem();
				},
			},
		];
		if (!hasCamera) buttons.shift(); // Remove the Rescan option

		setErrorAlert({
			display: true,
			title: "No zone number recognised",
			message: `Couldn't find a valid zone number`,
			buttons,
		});
	}

	function createAlertInvalidZoneNumber() {
		const buttons = [
			{
				text: "Rescan",
				onPress: () => {
					setIsCameraActive(true);
					setItem();
				},
			},
			{
				text: "Enter zone number manually",
				onPress: () => {
					setShowEnterBarcode(true);
					setItem();
				},
			},
		];
		if (!hasCamera) buttons.shift(); // Remove the Rescan option

		setErrorAlert({
			display: true,
			title: "Invalid zone number",
			message: `Zone number ${item.code} is not a valid number for this stocktake`,
			buttons,
		});
	}

	function createAlertZoneStarted() {
		if (!zoneQuery.data?.data.result) return;
		setErrorAlert({
			display: true,
			title: "Zone already started",
			message: `Zone ${zoneQuery.data.data.result.code} has already been started by ${zoneQuery.data.data.result.startedByName}`,
			buttons: [
				{
					text: "Cancel",
					onPress: () => {
						setItem();
					},
				},
			],
		});
	}

	function createAlertZoneCompleted() {
		setErrorAlert({
			display: true,
			title: "Zone already completed",
			message: `Zone ${zoneQuery.data?.data?.result?.code} has already been completed`,
			buttons: [
				{
					text: "Cancel",
					onPress: () => {
						setItem();
					},
				},
			],
		});
	}

	React.useEffect(() => {
		if (!item.code) {
			createAlertMissingNumber();
		}
	}, [item.code]);

	React.useEffect(() => {
		if (!zoneQuery.isFetched || !item.code) return;

		if (zoneQuery.data?.data?.result?.code) {
			if (
				zoneQuery.data?.data?.result?.status === ZoneItemStatus.Started &&
				zoneQuery.data?.data?.result?.startedAt &&
				zoneQuery.data?.data?.result?.startedBy
			) {
				createAlertZoneStarted();
			} else if (
				zoneQuery.data?.data?.result?.status === ZoneItemStatus.Complete ||
				zoneQuery.data?.data?.result?.status === ZoneItemStatus.Approved
			) {
				createAlertZoneCompleted();
			} else {
				setIsCameraActive(false);
				setItem({ code: zoneQuery.data.data.result.code, matchedZone: zoneQuery.data.data.result });
			}
		} else {
			createAlertInvalidZoneNumber();
		}
	}, [zoneQuery.isFetched]);

	return (
		<YStack key={item.code} width="100%">
			{zoneQuery.isLoading && (
				<YStack flex={1}>
					<H6>Loading...</H6>
				</YStack>
			)}

			{!zoneQuery.isLoading && zoneQuery.data?.data?.result?.code && (
				<YStack flex={1}>
					<H6>Ready to start Zone {zoneQuery.data.data.result.code}?</H6>
				</YStack>
			)}

			{errorAlert?.display && (
				<Alert
					title={errorAlert.title}
					message={errorAlert.message}
					buttons={errorAlert.buttons}
					display={errorAlert.display}
					onClose={() => setErrorAlert(undefined)}
					onCancel={() => setItem()}
				/>
			)}
		</YStack>
	);
}

export function ZoneStartNew() {
	const [zoneItem, setZoneItem] = React.useState<ZoneStartItem>();
	const [isCameraActive, setIsCameraActive] = React.useState(false);
	const [showEnterBarcode, setShowEnterBarcode] = React.useState(false);

	const [frameProcessor, matchedOcrText] = useOCR(isCameraActive);

	const navigate = useNavigate();

	const hasCamera = useHasCamera();

	const updateZoneQuery = useUpdateZone();

	async function handleStartZone() {
		if (!zoneItem?.matchedZone?.code) return;

		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: String(zoneItem.matchedZone.code),
				status: 1,
				startedAt: zoneItem.matchedZone.startedAt ?? new Date().toISOString(),
				updateStartedBy: true,
			},
		});

		navigate(`/zone/scan/${zoneItem.code}`, { replace: true });
	}

	React.useEffect(() => {
		if (zoneItem?.code) {
			// Stop handling scanner output if there is a code
			// This caused a bug (#183770277)
			//    - OCR scanned zone code
			//    - API lookup returned cached result
			//    - Display "Start Zone" as code matched
			//    - OCR re-scanned same code before the camera stopped processing
			//    - ZoneItem was overwritten, losing it's matchedZone prop
			//    - "Start Zone" button removed because of this
			return;
		}

		if (matchedOcrText) {
			setIsCameraActive(false);
			setZoneItem({ code: matchedOcrText });
		}
	}, [matchedOcrText]);

	return (
		<PageContainer>
			<YStack flex={1} maxWidth={800} w="100%" als="center">
				<ManagedScanner
					frameProcessor={frameProcessor}
					isActive={isCameraActive}
					startScanning={() => {
						setZoneItem(undefined);
						setIsCameraActive(true);
					}}
				/>
			</YStack>

			<PageScrollNarrow>
				<>
					{zoneItem ? (
						<ZoneStartNewItem
							item={zoneItem}
							setItem={setZoneItem}
							setIsCameraActive={setIsCameraActive}
							setShowEnterBarcode={setShowEnterBarcode}
							key={zoneItem.code}
						/>
					) : (
						<>
							<H4 mb="$3">Scan zone sticker to start zone</H4>

							<Button
								onPress={() => {
									setShowEnterBarcode(true);
									setIsCameraActive(false);
								}}
							>
								Enter zone number manually
							</Button>
						</>
					)}

					<Unspaced>
						<Alert
							display={showEnterBarcode}
							title="Enter zone number manually"
							inputs={[
								{
									placeholder: "123456",
									keyboardType: "numeric",
									autoFocus: true,
								},
							]}
							buttons={[
								{
									text: "Done",
									onPress: (response) => {
										let inputZone = response?.inputs?.[0]?.value || "";
										inputZone = inputZone.replace(/[^0-9]/g, "");
										setZoneItem({ code: inputZone });
									},
								},
							]}
							onClose={() => {
								setShowEnterBarcode(false);
							}}
							onCancel={() => {
								setZoneItem(undefined);
							}}
						/>
					</Unspaced>
				</>
			</PageScrollNarrow>

			<XStack mt="$3" space="$3" maxWidth={800} w="100%" p="$3" $gtSm={{ p: "$6" }} als="center">
				{!zoneItem && hasCamera && (
					<Button
						flex={1}
						disabled={isCameraActive}
						onPress={() => {
							setIsCameraActive(true);
						}}
					>
						Scan
					</Button>
				)}

				{!!zoneItem && (
					<Button flex={1} onPress={() => setZoneItem(undefined)}>
						Back
					</Button>
				)}
				{!!zoneItem?.matchedZone && (
					<Button flex={1} disabled={updateZoneQuery.isLoading} onPress={handleStartZone}>
						Start Zone
					</Button>
				)}
			</XStack>
		</PageContainer>
	);
}
