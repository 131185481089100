import { CheckCircle, ChevronRight, UserCheck } from "@tamagui/lucide-icons";
import * as React from "react";
import { Platform } from "react-native";
import { H4, Paragraph, XStack, Spinner, Separator, SizableText, Unspaced, YStack } from "tamagui";

import { useAdminListQueries } from "../../../common/api/staverton.openapi/admin-query";
import {
	QueryItem,
	QueryFilterUserOption,
	QueryFilterZoneOption,
} from "../../../common/api/staverton.openapi/index.schemas";
import { FilterSelect, FilterOption } from "../../../common/components/FilterSelect";
import { PageContainer, PageHeader, PageScrollWide } from "../../../common/components/Page";
import { PagingButtons } from "../../../common/components/PagingButtons";
import { Link } from "../../../navigation/router";

function QueryListItem({ queryItem }: { queryItem: QueryItem }) {
	const isResolved = queryItem.resolvedAt != null;

	return (
		<>
			<Separator />
			<Link
				to={`/query/edit/${queryItem.zoneItemQueryId}`}
				opacity={isResolved ? 0.5 : 1}
				hoverStyle={{ backgroundColor: "$backgroundSoft" }}
			>
				<YStack py="$2" px="$3">
					<XStack ai="center" space="$3">
						<XStack w="50%" $gtSm={{ w: "20%" }} ai="center" space="$2">
							<SizableText m={0} color="$color">
								{queryItem.zoneItemId
									? "Item " + queryItem.zoneItemCode
									: "Zone " + queryItem.zoneCode}
							</SizableText>
						</XStack>

						<Unspaced>
							<XStack
								display="none"
								$gtSm={{ display: "flex", f: 1, w: "30%", mr: "$3", ml: "$3" }}
								ai="center"
							>
								<Paragraph
									m={0}
									color="$color"
									whiteSpace="nowrap"
									overflow="hidden"
									textOverflow="ellipsis"
									cursor="pointer"
								>
									{queryItem.details}
								</Paragraph>
							</XStack>

							<XStack display="none" $gtSm={{ display: "flex", mr: "$3", w: "20%" }}>
								<SizableText m={0} color="$color">
									{queryItem.queriedByName}
								</SizableText>
							</XStack>
						</Unspaced>

						<XStack w="50%" $gtSm={{ w: "20%" }} ai="center">
							{(isResolved || queryItem.claimedBy) && (
								<XStack fs={0} mr="$2">
									{isResolved ? <CheckCircle size={16} /> : <UserCheck size={16} />}
								</XStack>
							)}

							<Paragraph
								m={0}
								fg={1}
								fs={1}
								lh={16}
								color="$color"
								whiteSpace="nowrap"
								overflow="hidden"
								textOverflow="ellipsis"
								cursor="pointer"
								size={!isResolved && queryItem.claimedBy ? "$2" : "$4"}
							>
								{isResolved
									? "Resolved"
									: queryItem.claimedBy
									? `Claimed by ${queryItem.claimedByName}`
									: "New"}
							</Paragraph>
						</XStack>
						<XStack display="none" $gtSm={{ display: "flex", w: 14, jc: "center" }}>
							<ChevronRight size={14} />
						</XStack>
					</XStack>
					<Paragraph
						m={0}
						color="$color"
						whiteSpace="nowrap"
						overflow="hidden"
						textOverflow="ellipsis"
						cursor="pointer"
						$gtSm={{
							display: "none",
						}}
					>
						{queryItem.details}
					</Paragraph>
				</YStack>
			</Link>
		</>
	);
}

export function AdminQueryList() {
	const [filterZoneValue, setFilterZoneValue] = React.useState<string>("");
	const [filterItemValue, setFilterItemValue] = React.useState<string>("");
	const [filterZoneItemOptions, setFilterZoneItemOptions] = React.useState<FilterOption[]>([]);
	const [filterUserValue, setFilterUserValue] = React.useState<string>("");
	const [filterUserOptions, setFilterUserOptions] = React.useState<FilterOption[]>([]);
	const [filterStatusValue, setFilterStatusValue] = React.useState<string>("");
	const filterStatusOptions: FilterOption[] = [
		{
			id: "",
			label: "Any",
		},
		{
			id: "new",
			label: "New",
		},
		{
			id: "claimed",
			label: "Claimed",
		},
		{
			id: "resolved",
			label: "Resolved",
		},
	];
	const [pageFrom, setPageFrom] = React.useState<number>(0);
	const pageSize = Platform.OS === "web" ? 50 : 10;

	const query = useAdminListQueries({
		pageFrom,
		pageSize,
		filterZone: filterZoneValue,
		filterItem: filterZoneValue ? "null" : filterItemValue,
		filterUser: filterUserValue ? Number(filterUserValue) : undefined,
		filterStatus: filterStatusValue,
	});

	React.useEffect(() => {
		if (
			query?.data?.data?.filterZoneItemOptions &&
			Array.isArray(query?.data?.data?.filterZoneItemOptions)
		) {
			const zoneItemFilters = query.data.data.filterZoneItemOptions
				.sort((a, b) => {
					if (a.zoneItemCode && !b.zoneItemCode) return 1;
					if (!a.zoneItemCode && b.zoneItemCode) return -1;
					if (a.zoneItemCode && b.zoneItemCode) return a.zoneItemCode > b.zoneItemCode ? 1 : -1;
					return a.zoneCode > b.zoneCode ? 1 : -1;
				})
				.map((filterZoneOption: QueryFilterZoneOption) => {
					return filterZoneOption.zoneItemCode
						? {
								id: `|${filterZoneOption.zoneItemCode}`,
								label: `Item ${filterZoneOption.zoneItemCode}`,
						  }
						: {
								id: `${filterZoneOption.zoneCode}|`,
								label: `Zone ${filterZoneOption.zoneCode}`,
						  };
				});

			setFilterZoneItemOptions([{ id: "|", label: "Any" }, ...zoneItemFilters]);
		}

		if (Array.isArray(query?.data?.data?.filterUserOptions)) {
			setFilterUserOptions([
				{ id: "", label: "Any" },
				...(query.data?.data.filterUserOptions.map((filterUserOption: QueryFilterUserOption) => {
					return { id: String(filterUserOption.userId), label: String(filterUserOption.userName) };
				}) || []),
			]);
		}
	}, [query.isFetchedAfterMount]);

	return (
		<PageContainer>
			<PageScrollWide>
				<>
					<PageHeader title="Queries" />

					<XStack py="$2" px="$3" alignItems="center" space="$3">
						<XStack w="50%" $gtSm={{ w: "20%" }}>
							<H4 m={0} color="$color" flex={1}>
								Item/Zone
							</H4>
						</XStack>

						<Unspaced>
							<XStack
								display="none"
								$gtSm={{ display: "flex", f: 1, w: "30%", mr: "$3", ml: "$3" }}
								ai="center"
							>
								<H4 m={0} color="$color" flex={1}>
									Details
								</H4>
							</XStack>

							<XStack display="none" $gtSm={{ display: "flex", mr: "$3", w: "20%" }}>
								<H4 m={0} color="$color">
									Queried By
								</H4>
							</XStack>
						</Unspaced>

						<XStack w="50%" $gtSm={{ display: "flex", w: "20%" }}>
							<H4 m={0} color="$color" flex={1}>
								Status
							</H4>
						</XStack>

						<XStack display="none" $gtSm={{ display: "flex", w: 14 }} />
					</XStack>

					<Separator />

					<XStack py="$2" px="$3" alignItems="center" space="$3">
						<XStack w="50%" $gtSm={{ w: "20%" }}>
							<FilterSelect
								value={`${filterZoneValue}|${filterItemValue}`}
								options={filterZoneItemOptions}
								updateValue={(val) => {
									setPageFrom(0);

									setFilterZoneValue(val.split("|")[0]);
									setFilterItemValue(val.split("|")[1]);
								}}
							/>
						</XStack>

						<Unspaced>
							<XStack
								display="none"
								$gtSm={{ display: "flex", f: 1, w: "30%", mr: "$3", ml: "$3" }}
								ai="center"
							/>

							<XStack display="none" $gtSm={{ display: "flex", mr: "$3", w: "20%" }}>
								<FilterSelect
									value={filterUserValue}
									options={filterUserOptions}
									updateValue={(val) => {
										setPageFrom(0);
										setFilterUserValue(val);
									}}
								/>
							</XStack>
						</Unspaced>

						<XStack w="50%" $gtSm={{ display: "flex", w: "20%" }}>
							<FilterSelect
								value={filterStatusValue}
								options={filterStatusOptions}
								updateValue={(val) => {
									setPageFrom(0);
									setFilterStatusValue(val);
								}}
							/>
						</XStack>

						<XStack display="none" $gtSm={{ display: "flex", w: 14 }} />
					</XStack>

					{query.isLoading && (
						<>
							<Separator mb="$3" />
							<XStack jc="center">
								<Spinner />
							</XStack>
						</>
					)}

					{query.isFetched &&
						query.data?.data?.results &&
						query.data?.data?.results.map((queryItem, idx) => (
							<QueryListItem queryItem={queryItem} key={idx} />
						))}

					{query.isFetched && query?.data?.data?.results?.length === 0 && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								No queries to show
							</Paragraph>
						</>
					)}

					{query.isFetched && query?.data?.data?.errorMessage != undefined && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								{query.data.data.errorMessage}
							</Paragraph>
						</>
					)}

					{query.isFetched &&
						query.data?.data?.resultsTotal != undefined &&
						query.data?.data?.resultsTotal > 0 && (
							<>
								<Separator mb="$3" />
								<PagingButtons
									from={pageFrom}
									size={pageSize}
									items={query.data.data.results?.length ?? 0}
									total={query.data.data.resultsTotal}
									updateFrom={(val) => setPageFrom(val)}
								/>
							</>
						)}
				</>
			</PageScrollWide>
		</PageContainer>
	);
}
