import { Check, ChevronRight, Plus } from "@tamagui/lucide-icons";
import * as React from "react";
import { Platform, useWindowDimensions } from "react-native";
import { useNavigate } from "react-router";
import {
	H4,
	Paragraph,
	XStack,
	Spinner,
	Separator,
	SizableText,
	H2,
	YStack,
	H6,
	Input,
} from "tamagui";

import { AlertBasic } from "../../../../assets/icons/AlertBasic";
import { useAdminListZones } from "../../../common/api/staverton.openapi/admin-zone";
import { ZoneItem, ZoneItemStatus } from "../../../common/api/staverton.openapi/index.schemas";
import { Button, Icon } from "../../../common/components";
import { FilterSelect } from "../../../common/components/FilterSelect";
import { PageContainer, PageHeader, PageScrollWide } from "../../../common/components/Page";
import { PagingButtons } from "../../../common/components/PagingButtons";
import { useSearch } from "../../../common/hooks/useSearch";
import { useQueryParamsState } from "../../../common/hooks/useSyncQueryParams";
import { Link } from "../../../navigation/router";

function getZoneStatusName(status: number | string) {
	switch (status) {
		case ZoneItemStatus.WaitingToStart:
		case 0:
			return "Waiting To Start";
		case ZoneItemStatus.Started:
		case 1:
			return "Started";
		case ZoneItemStatus.Complete:
		case 2:
			return "Complete";
		case ZoneItemStatus.Approved:
		case 3:
			return "Approved";
		default:
			return "";
	}
}

function ZoneStatusItem({ zoneItem }: { zoneItem: ZoneItem }) {
	let statusIcon = <Plus size={14} color="white" />;
	let statusBgColor = "$gray9";

	if (zoneItem.status === ZoneItemStatus.Complete && zoneItem.queryCount === 0) {
		statusIcon = <Check size={14} color="white" />;
		statusBgColor = "$green9";
	} else if (zoneItem.status === ZoneItemStatus.Complete && zoneItem.queryCount > 0) {
		statusIcon = <AlertBasic size={14} color="white" />;
		statusBgColor = "$red9";
	} else if (zoneItem.status === ZoneItemStatus.Started && zoneItem.queryCount > 0) {
		statusIcon = <Plus size={14} color="white" />;
		statusBgColor = "$orange9";
	} else if (zoneItem.status === ZoneItemStatus.Approved) {
		statusIcon = <Check size={14} color="white" />;
		statusBgColor = "$blue9";
	}

	const zoneQueryString = zoneItem.queryCount
		? `${zoneItem.queryCount} ${zoneItem.queryCount === 1 ? "query" : "queries"}`
		: "";

	return (
		<>
			<Separator />
			<Link to={`/admin/zone/${zoneItem.code}`} hoverStyle={{ backgroundColor: "$backgroundSoft" }}>
				<XStack py="$2" px="$3" ai="center" w="100%" space="$3">
					<XStack f={1} w="50%" $gtSm={{ w: "30%" }}>
						<SizableText m={0} color="$color">
							{"Zone " + zoneItem.code}
						</SizableText>
					</XStack>
					<XStack w="50%" ai="center" $gtSm={{ w: "30%" }}>
						<XStack mr="$2" w="$1" h="$1" ai="center" jc="center" br={12} bc={statusBgColor}>
							{statusIcon}
						</XStack>
						<SizableText m={0} color="$color">
							{getZoneStatusName(zoneItem.status)}
						</SizableText>
					</XStack>
					<XStack w="30%" display="none" $gtSm={{ display: "flex" }}>
						<SizableText m={0} color="$color">
							{zoneQueryString}
						</SizableText>
					</XStack>
					<XStack w={14} jc="center">
						<ChevronRight size={14} />
					</XStack>
				</XStack>
			</Link>
		</>
	);
}

export function AdminZoneList() {
	const [filterStatusValue, setFilterStatusValue] = useQueryParamsState<string>("status", "any");
	// Must use int status ids for the API
	const filterStatusOptions = [
		{ id: "any", label: "Any" },
		...[0, 1, 2, 3].map((key) => {
			return {
				id: String(key),
				label: getZoneStatusName(key),
			};
		}),
	];
	const [filterQueryValue, setFilterQueryValue] = useQueryParamsState<string>("queries", "any");
	const filterQueryOptions = [
		{ id: "any", label: "Any" },
		{ id: "0", label: "No queries" },
		{ id: "1", label: "Some queries" },
	];

	const pageSize = Platform.OS === "web" ? 50 : 10;
	const [pageFrom, setPageFrom] = React.useState<number>(0);

	const [searchValue, searchQuery, setSearchValue] = useSearch(300);

	const query = useAdminListZones({
		filterQuery: filterQueryValue !== "any" ? Number(filterQueryValue) : undefined,
		filterStatus: filterStatusValue !== "any" ? Number(filterStatusValue) : undefined,
		pageFrom,
		pageSize,
		searchQuery: searchQuery ? searchQuery : undefined,
	});

	const navigate = useNavigate();

	function navigateToCreateZones() {
		navigate("/admin/zone/create");
	}

	function navigateToAdminStocktakeComplete() {
		navigate("/admin/stocktake/complete");
	}

	function navigateToAdminStocktakeStart() {
		navigate("/admin/stocktake/start");
	}

	const showCreateZonesButton = !query.isLoading && !!query.data?.data?.results;
	const showDesktopStocktakeButton = useWindowDimensions().width >= 800 || !showCreateZonesButton;
	const stocktakeButton =
		!query.isLoading && query.data?.data ? (
			<XStack space="$3" jc="flex-end" ai="center" fw="wrap">
				{query.data.data.stocktakeId != null ? (
					<Button icon={Check} onPress={navigateToAdminStocktakeComplete}>
						Complete Stocktake
					</Button>
				) : (
					<Button icon={Plus} onPress={navigateToAdminStocktakeStart}>
						Start new Stocktake
					</Button>
				)}
			</XStack>
		) : (
			<></>
		);

	return (
		<PageContainer>
			<PageScrollWide>
				<>
					<PageHeader title="Admin">
						<XStack space="$3" jc="flex-end" ai="center" fw="wrap">
							{showDesktopStocktakeButton && stocktakeButton}
							{showCreateZonesButton && (
								<Button onPress={navigateToCreateZones}>Create Zones</Button>
							)}
						</XStack>
					</PageHeader>

					{!showDesktopStocktakeButton && stocktakeButton}

					{!!query.data?.data?.overview && (
						<XStack m="$2" h="$2" overflow="hidden" br="$4">
							{query.data?.data?.overview?.map((i) => (
								<YStack
									alignSelf="stretch"
									jc="center"
									ai="center"
									width={0}
									flex={i.count}
									bc={
										{
											Complete: "$green9",
											Approved: "$blue9",
											Started: "$yellow9",
											WaitingToStart: "$gray9",
										}[i.status]
									}
								>
									<H6
										flex={1}
										textAlign="center"
										overflow="hidden"
										ellipsizeMode="middle"
										color={i.status === "WaitingToStart" ? "#ffffff" : "#000000"}
									>
										{
											{
												Complete: "Completed",
												Approved: "Approved",
												Started: "Started",
												WaitingToStart: "Waiting",
											}[i.status]
										}{" "}
										({i.count.toLocaleString()})
									</H6>
								</YStack>
							))}
						</XStack>
					)}

					<XStack py="$2" px="$3" alignItems="center" space="$3">
						<XStack f={1} w="50%" $gtSm={{ w: "30%" }}>
							<H4 m={0} color="$color" flex={1}>
								Zone
							</H4>
						</XStack>
						<XStack w="50%" $gtSm={{ w: "30%" }}>
							<H4 m={0} color="$color" flex={1}>
								Status
							</H4>
						</XStack>
						<XStack w="30%" display="none" $gtSm={{ display: "flex" }}>
							<H4 m={0} color="$color" flex={1}>
								Queries
							</H4>
						</XStack>
						<XStack w={14} />
					</XStack>

					<Separator />

					<XStack py="$2" px="$3" alignItems="center" space="$3">
						<XStack f={1} w="50%" $gtSm={{ w: "30%" }}>
							<Input
								placeholder="Search"
								value={searchValue}
								onChangeText={(v) => setSearchValue(v.replace(/\D/g, ""))}
								flex={1}
								keyboardType="number-pad"
							/>
						</XStack>

						<XStack w="50%" $gtSm={{ w: "30%" }}>
							<FilterSelect
								value={filterStatusValue}
								options={filterStatusOptions}
								updateValue={(val) => {
									setPageFrom(0);
									setFilterStatusValue(val);
								}}
							/>
						</XStack>

						<XStack display="none" $gtSm={{ display: "flex", w: "30%" }}>
							<FilterSelect
								value={filterQueryValue}
								options={filterQueryOptions}
								updateValue={(val) => {
									setPageFrom(0);
									setFilterQueryValue(val);
								}}
							/>
						</XStack>

						<XStack w={14} />
					</XStack>

					{query.isLoading && (
						<>
							<Separator mb="$3" />
							<XStack jc="center">
								<Spinner />
							</XStack>
						</>
					)}

					{!query.isLoading &&
						query.data?.data?.results &&
						query.data?.data?.results.map((zoneItem: ZoneItem) => (
							<ZoneStatusItem zoneItem={zoneItem} key={zoneItem.zoneId} />
						))}

					{!query.isLoading && query.data?.data?.results?.length === 0 && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								No zones to show
							</Paragraph>
						</>
					)}

					{!query.isLoading && query.data?.data?.errorMessage != undefined && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								{query.data.data.errorMessage}
							</Paragraph>
						</>
					)}

					{!query.isLoading && query.data?.data?.results && (
						<>
							<Separator mb="$3" />
							<PagingButtons
								from={pageFrom}
								size={pageSize}
								items={query.data.data.results.length}
								total={query.data.data.resultsTotal}
								updateFrom={(val) => setPageFrom(val)}
							/>
						</>
					)}

					{!query.isLoading && !!query.data?.data.completedStocktakes?.length && (
						<>
							<Separator my="$3" />
							<H2 mb="$2">Completed Stocktakes</H2>
							{query.data?.data?.completedStocktakes.map((completedStocktake) => (
								<YStack key={completedStocktake.stocktakeId}>
									<Separator />
									<Link
										to={`/admin/stocktake/review/${completedStocktake.stocktakeId}`}
										hoverStyle={{ backgroundColor: "$backgroundSoft" }}
									>
										<XStack py="$2" px="$3" ai="center" w="100%" space="$3">
											<SizableText flex={1} m={0} color="$color">
												{completedStocktake.stocktakeLabel}
											</SizableText>
											<Icon icon={ChevronRight} size="$3" color="$color" />
										</XStack>
									</Link>
								</YStack>
							))}
						</>
					)}
				</>
			</PageScrollWide>
		</PageContainer>
	);
}
