import { Audio } from "expo-av";
import * as Haptics from "expo-haptics";
import { Platform } from "react-native";

export async function playSound(soundKey: "high" | "mid" | "low") {
	const audioClips = {
		high: require("../../../assets/audio/High.wav"),
		mid: require("../../../assets/audio/Mid.wav"),
		low: require("../../../assets/audio/Low.wav"),
	};
	const soundObject = await Audio.Sound.createAsync(audioClips[soundKey]).catch(() => {
		console.info("Audio playback failed");
	});

	if (soundObject?.sound) await soundObject.sound.playAsync();
}

export async function playHaptic(hapticKey: "high" | "mid" | "low") {
	if (Platform.OS === "web") return;

	const hapticKeys = {
		high: Haptics.NotificationFeedbackType.Success,
		mid: Haptics.NotificationFeedbackType.Success,
		low: Haptics.NotificationFeedbackType.Warning,
	};

	await Haptics.notificationAsync(hapticKeys[hapticKey]);
}
