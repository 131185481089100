import {
	AlertTriangle,
	Check,
	ChevronDown,
	ChevronUp,
	HelpCircle,
	MoreVertical,
	Scan,
	ScanLine,
	StopCircle,
	TextCursor,
	X,
} from "@tamagui/lucide-icons";
import * as React from "react";
import { useMemo } from "react";
import { Platform, Alert as RNAlert } from "react-native";
import { useNavigate, useParams } from "react-router";
import {
	H6,
	Paragraph,
	YStack,
	XStack,
	Input,
	H4,
	Spinner,
	Unspaced,
	H1,
	Separator,
	H5,
} from "tamagui";

import { useScanBarcodes } from "../../../plugins/frameProcessors/js/codeScanner";
import { isIgnored, StockLookupResponseRecord, useStockLookup } from "../../common/api/multiRetail";
import {
	QueryItem,
	ZoneItemStatus,
	ZoneListItem,
} from "../../common/api/staverton.openapi/index.schemas";
import { useListQueries } from "../../common/api/staverton.openapi/query";
import { useGetZone } from "../../common/api/staverton.openapi/zone";
import {
	useCreateZoneItem,
	useDeleteZoneItem,
	useListZoneItems,
	useUpdateZoneItem,
} from "../../common/api/staverton.openapi/zone-item";
import { useHasCamera } from "../../common/camera/camera";
import { Button } from "../../common/components";
import { Alert } from "../../common/components/Alert";
import { ManagedScanner } from "../../common/components/ManagedScanner";
import { PageContainer, PageScrollNarrow } from "../../common/components/Page";
import { formatPrice } from "../../common/number/number";
import { playHaptic, playSound } from "../../common/sound";
import { QueryItemView } from "../query/QueryItemView";

interface ScannedZoneItem extends Omit<ZoneListItem, "count"> {
	count: string;
	isScannedNew: boolean;
	showEdit: boolean;
}

function ZoneScanListItemOptions({
	itemQuery,
	itemDelete,
	disabled,
}: {
	itemQuery: () => void;
	itemDelete: () => void;
	disabled?: boolean;
}) {
	const [displayModal, setDisplayModal] = React.useState<boolean>(false);
	return (
		<>
			<Button
				disabled={disabled}
				icon={MoreVertical}
				p="$1"
				onPress={() => {
					setDisplayModal(!displayModal);
				}}
			/>

			<Alert
				display={displayModal}
				buttons={[
					{
						text: "Query",
						icon: HelpCircle,
						onPress: itemQuery,
					},
					{
						text: "Delete",
						icon: X,
						onPress: itemDelete,
					},
				]}
				onClose={() => {
					setDisplayModal(false);
				}}
			/>
		</>
	);
}

function ZoneScanListItem({
	queryItemsUpdatedAt,
	...props
}: {
	item: ScannedZoneItem;
	zoneStatus?: ZoneItemStatus;
	queryItems?: QueryItem[];
	queryItemsUpdatedAt: number;
	itemUpdate: (updatedValue: ScannedZoneItem) => void;
	itemDelete: () => void;
	isCameraActive: boolean;
	setIsCameraActive: React.Dispatch<React.SetStateAction<boolean>>;
	setShowEnterBarcode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const propsRef = React.useRef<typeof props>(props);
	propsRef.current = props;
	return useMemo(() => {
		return (
			<InternalZoneScanListItem
				{...props}
				itemUpdate={(val) => propsRef.current.itemUpdate(val)}
				itemDelete={() => propsRef.current.itemDelete()}
				setIsCameraActive={(val) => propsRef.current.setIsCameraActive(val)}
				setShowEnterBarcode={(val) => propsRef.current.setShowEnterBarcode(val)}
			/>
		);
	}, [
		props.queryItems?.length ? queryItemsUpdatedAt : 0,
		props.zoneStatus,
		props.item,
		props.isCameraActive,
	]);
}

function InternalZoneScanListItem({
	item,
	zoneStatus,
	queryItems,
	itemUpdate,
	itemDelete,
	isCameraActive,
	setIsCameraActive,
	setShowEnterBarcode,
}: {
	item: ScannedZoneItem;
	zoneStatus?: ZoneItemStatus;
	queryItems?: QueryItem[];
	itemUpdate: (updatedValue: ScannedZoneItem) => void;
	itemDelete: () => void;
	isCameraActive: boolean;
	setIsCameraActive: React.Dispatch<React.SetStateAction<boolean>>;
	setShowEnterBarcode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [checkStockCode, setCheckStockCode] = React.useState<boolean>(false);
	const [displayAlertUnrecognised, setDisplayAlertUnrecognised] = React.useState<boolean>(false);
	const [displayAlertMultiMatch, setDisplayAlertMultiMatch] = React.useState<boolean>(false);

	const stockQuery = useStockLookup(
		{ code: item.code, checkStockCode },
		{ enabled: !item.retailDescription }
	);
	// Prioritize exact match results before falling back to all results (which may be partial match)
	// e.g. Searching for "12345" may return ["012345", "123450", "12345"]
	const resultsExactMatch =
		stockQuery.data?.records.filter(
			(result) => result.Barcode === item.code || String(result.Stock_Code) === item.code
		) ?? [];
	const queryResults = resultsExactMatch.length
		? resultsExactMatch
		: stockQuery.data?.records ?? [];

	const hasLoaded = stockQuery.isFetchedAfterMount;
	const hasUnresolvedQueries = queryItems && queryItems.filter((q) => !q.resolvedAt).length > 0;
	const isCompleted = zoneStatus === ZoneItemStatus.Complete;
	// eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
	const canEditItem = hasUnresolvedQueries || !isCompleted;
	const isDisabled = !canEditItem || isCameraActive;

	const navigate = useNavigate();

	function navigateToQueryItem() {
		navigate(`/query/new/${item.zoneCode}/${item.zoneItemId}`);
	}

	function mapResultToItem(result: StockLookupResponseRecord) {
		return {
			...item,
			code: result.Barcode,
			retailStockCode: result.Stock_Code,
			retailDescription: result.Full_Desc,
			retailStockBalance: result.Retail_Stock_Balance,
			retailPrice: result.BandA_Sell,
		} as ScannedZoneItem;
	}

	React.useEffect(() => {
		if (!hasLoaded) return;

		const updatedItem = { ...item };

		const autoSelectResults = queryResults.filter(
			(result) => !isIgnored(result) && !!result.Stock_Code
		);

		if (!item.retailStockCode && autoSelectResults.length === 1) {
			// Apply the lookup details to the db item
			Object.assign(updatedItem, mapResultToItem(queryResults[0]));
		}

		if (item.isScannedNew) {
			// Only trigger haptics and unrecognised if a newly scanned item
			updatedItem.isScannedNew = false;
			if (autoSelectResults.length === 1) {
				playSound("high");
				playHaptic("high");
			} else if (queryResults.length > 0) {
				// Alert user to choose from result results
				setDisplayAlertMultiMatch(true);
			} else if (queryResults.length === 0 && !checkStockCode) {
				// Not valid barcode found, check stock code before declaring it as invalid
				updatedItem.isScannedNew = true; // Otherwise we never declare as unrecognised
				setCheckStockCode(true);
				return;
			} else {
				playSound("low");
				playHaptic("low");

				setDisplayAlertUnrecognised(true);
			}
		}

		// Update to prevent re-playing of audio + haptics after another new item is added
		itemUpdate(updatedItem);
	}, [hasLoaded]);

	return (
		<YStack my="$3" space="$3">
			<XStack space="$3" alignItems="center">
				<YStack flex={1}>
					<H6>{item.code}</H6>
					<Paragraph color="$color" lineHeight="$1">
						{stockQuery.isLoading
							? "Loading..."
							: queryResults[0]?.Full_Desc ?? item.retailDescription ?? "Invalid code"}
					</Paragraph>
				</YStack>

				<Input
					keyboardType="numeric"
					width="$8"
					textAlign="right"
					placeholder="Qty"
					selectTextOnFocus
					opacity={canEditItem ? 1 : 0.5}
					editable={!isDisabled}
					disabled={isDisabled}
					value={item.count}
					onChangeText={
						!isDisabled
							? (inputValue: string) => {
									const formattedValue = inputValue.replace(/[^0-9]/g, "");
									itemUpdate({ ...item, count: formattedValue });
							  }
							: undefined
					}
					onBlur={
						canEditItem
							? () => {
									if (item.count == "") {
										itemDelete();
									}
							  }
							: undefined
					}
				/>

				{canEditItem && (
					<ZoneScanListItemOptions
						disabled={isDisabled}
						itemQuery={navigateToQueryItem}
						itemDelete={itemDelete}
					/>
				)}
			</XStack>

			{!!queryItems?.length &&
				queryItems.map((queryItem) => (
					<QueryItemView queryItem={queryItem} key={queryItem.zoneItemQueryId} />
				))}

			<Unspaced>
				<Alert
					display={displayAlertUnrecognised}
					title="Unrecognised barcode"
					message={`Barcode ${item.code} has not been recognised. Would you like to raise a query?`}
					buttons={[
						{
							text: "Yes",
							onPress: () => navigateToQueryItem(),
						},
						{
							text: "No - rescan",
							onPress: () => {
								itemDelete();
								setIsCameraActive(true);
							},
						},
						{
							text: "Enter code manually",
							onPress: () => {
								itemDelete();
								setIsCameraActive(false);
								setShowEnterBarcode(true);
							},
						},
					]}
					onClose={() => setDisplayAlertUnrecognised(false)}
					onCancel={() => {
						itemDelete();
						setIsCameraActive(true);
					}}
				/>
				<Alert
					display={displayAlertMultiMatch}
					title={queryResults.length > 1 ? "Multiple barcode matches" : "Confirm product"}
					message={
						queryResults.length > 1
							? `Barcode ${item.code} has matched multiple products. Please select one:`
							: "Please confirm the product:"
					}
					buttons={[
						...queryResults.map((result) => {
							return {
								text: `${result.Full_Desc} ${formatPrice(result?.BandA_Sell)} (${
									result.Barcode ?? result.Stock_Code
								})${result.Deleted ? " (DELETED)" : ""}${result.Suspended ? " (SUSPENDED)" : ""}${
									result.Discarded ? " (DISCARDED)" : ""
								}`,
								iconAfter: isIgnored(result) ? AlertTriangle : undefined,
								theme: isIgnored(result) ? ("orange" as const) : undefined,
								onPress: () => {
									itemUpdate(mapResultToItem(result));
									setDisplayAlertMultiMatch(false);
								},
							};
						}),
						{
							text: "Cancel",
							onPress: () => {
								itemDelete();
								setIsCameraActive(false);
								setShowEnterBarcode(true);
							},
						},
					]}
					onClose={() => setDisplayAlertUnrecognised(false)}
					onCancel={() => {
						itemDelete();
						setIsCameraActive(true);
					}}
				/>
			</Unspaced>
		</YStack>
	);
}

export function ZoneScanItems() {
	const params = useParams();
	const navigate = useNavigate();
	const hasCamera = useHasCamera();

	const createZoneItemQuery = useCreateZoneItem();
	const updateZoneItemQuery = useUpdateZoneItem();
	const deleteZoneItemQuery = useDeleteZoneItem();

	const zoneQuery = useGetZone({
		zoneCode: params.zoneCode,
	});
	const zoneItemsQuery = useListZoneItems({
		zoneCode: params.zoneCode,
	});
	const zoneQueriesQuery = useListQueries({
		filterZone: params.zoneCode,
	});
	const zoneQueriesWithoutItem =
		zoneQueriesQuery.data?.data?.results?.filter((q: QueryItem) => !q.zoneItemId) ?? [];

	const isPageLoading =
		zoneQuery.isLoading ||
		zoneItemsQuery.isLoading ||
		zoneQueriesQuery.isLoading ||
		!zoneQuery.isFetchedAfterMount ||
		!zoneQueriesQuery.isFetchedAfterMount;

	// Make sure the zone is started by this user and must be started status
	const isZoneFound =
		zoneQuery.isFetched &&
		!!zoneQuery.data?.data?.result &&
		(zoneQuery.data.data.result.status === ZoneItemStatus.Started ||
			zoneQuery.data.data.result.status === ZoneItemStatus.Complete) &&
		zoneQuery.data.data.startedByRequestor;

	const [listItems, setListItems] = React.useState<ScannedZoneItem[]>([]);
	const [isCameraActive, setIsCameraActive] = React.useState(false);
	const [showEnterBarcode, setShowEnterBarcode] = React.useState(false);
	const [showZoneQueries, setShowZoneQueries] = React.useState(true);

	const [frameProcessor, barcodes] = useScanBarcodes(isCameraActive);

	React.useEffect(() => {
		if (zoneItemsQuery.isFetched && zoneItemsQuery.data?.data?.results) {
			setListItems(
				zoneItemsQuery.data?.data?.results.map((item: ZoneListItem) => {
					return {
						...item,
						count: String(item.count),
						isScannedNew: false,
						showEdit: false,
					};
				})
			);
		}
	}, [zoneItemsQuery.isFetched, zoneItemsQuery.isFetchedAfterMount]);

	React.useEffect(() => {
		if (!isCameraActive) return;

		const barcode = barcodes[0];
		if (!barcode) return;

		handleInsertListItem(barcode.displayValue);

		setIsCameraActive(false);

		playHaptic("mid");
		playSound("mid");
	}, [barcodes]);

	async function handleInsertListItem(code: string) {
		setShowZoneQueries(false);

		const latestListItem = listItems[0];
		if (latestListItem && latestListItem.code === code) {
			// Handle re-scan by incrementing count
			handleUpdateListItem(latestListItem, {
				...latestListItem,
				count: String(parseInt(latestListItem.count) + 1),
			});
		} else {
			// Handle new item insert
			const item: ScannedZoneItem = {
				zoneStocktakeId: 0,
				code,
				zoneCode: String(params.zoneCode || ""),
				count: "1",
				showEdit: false,
				zoneItemId: 0,
				zoneId: zoneQuery.data?.data?.result?.zoneId || 0,
				isScannedNew: true,
				scannedAt: new Date().toISOString(),
				scannedBy: 0,
			};

			const createItemResult = await createZoneItemQuery.mutateAsync({
				data: {
					zoneId: item.zoneId,
					code: item.code,
					count: Number(item.count),
					scannedAt: item.scannedAt,
				},
			});

			setListItems([
				{
					...item,
					zoneItemId: createItemResult.data?.zoneItemId,
				},
				...listItems,
			]);
		}
	}

	async function handleUpdateListItem(item: ScannedZoneItem, updatedValue: ScannedZoneItem) {
		const newListItem = { ...updatedValue };
		setListItems(listItems.map((i) => (i === item ? newListItem : i)));

		if (item !== updatedValue) {
			await updateZoneItemQuery
				.mutateAsync({
					data: {
						zoneItemId: updatedValue.zoneItemId,
						code: updatedValue.code,
						count: Number(updatedValue.count),
						retailStockCode: updatedValue.retailStockCode,
						retailDescription: updatedValue.retailDescription,
						retailStockBalance: updatedValue.retailStockBalance,
						retailPrice: updatedValue.retailPrice,
					},
				})
				.catch((error) => {
					console.error(error);
					if (Platform.OS === "web") {
						if (
							confirm(
								"Oops, something went wrong when updating this item. Would you like to try again?"
							)
						) {
							handleUpdateListItem(item, updatedValue);
						} else {
							setListItems(listItems.map((i) => (i === newListItem ? item : i)));
						}
					} else {
						RNAlert.alert(
							"Error updating item",
							"Oops, something went wrong when updating this item. Would you like to try again?",
							[
								{
									text: "Cancel",
									onPress: () => {
										setListItems(listItems.map((i) => (i === newListItem ? item : i)));
									},
								},
								{
									text: "Try Again",
									onPress: () => {
										handleUpdateListItem(item, updatedValue);
									},
								},
							],
							{ cancelable: false }
						);
					}
				});
		}
	}

	async function handleDeleteListItem(item: ScannedZoneItem) {
		setListItems(listItems.filter((i) => i !== item));

		await deleteZoneItemQuery.mutateAsync({
			params: {
				zoneItemId: item.zoneItemId,
			},
		});
	}

	function completeZoneHandler() {
		navigate("/zone/complete/" + params.zoneCode);
	}

	return (
		<PageContainer>
			{Platform.OS !== "web" && (
				<YStack flex={1} maxWidth={800} w="100%" als="center">
					<ManagedScanner
						frameProcessor={frameProcessor}
						isActive={isCameraActive}
						startScanning={() => setIsCameraActive(true)}
					/>
				</YStack>
			)}

			<PageScrollNarrow>
				<>
					<XStack ai="center" space="$3">
						<H1 size="$9" flex={1}>
							Zone {params.zoneCode}
						</H1>
						{zoneQuery.data?.data.result?.status !== ZoneItemStatus.Complete && (
							<Button icon={Check} disabled={listItems.length === 0} onPress={completeZoneHandler}>
								Complete
							</Button>
						)}
					</XStack>

					{isPageLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!isPageLoading && !isZoneFound && <H4>Zone not found</H4>}

					{!isPageLoading && isZoneFound && zoneQueriesWithoutItem.length > 0 && (
						<YStack my="$3" space="$3">
							<XStack space="$3">
								<H6>Zone Queries</H6>
								<Button
									size="$2"
									icon={showZoneQueries ? ChevronUp : ChevronDown}
									onPress={() => setShowZoneQueries(!showZoneQueries)}
								/>
							</XStack>

							{showZoneQueries &&
								zoneQueriesWithoutItem.map((query) => (
									<QueryItemView queryItem={query} key={query.zoneItemQueryId} />
								))}
							<Separator />
						</YStack>
					)}

					{!isPageLoading && isZoneFound && listItems.length === 0 && <H4>Scan code to start</H4>}

					{!isPageLoading &&
						isZoneFound &&
						listItems.length > 0 &&
						listItems.map((item) => (
							<ZoneScanListItem
								item={item}
								zoneStatus={zoneQuery.data?.data.result?.status}
								queryItems={zoneQueriesQuery.data?.data?.results?.filter(
									(q: QueryItem) => q.zoneItemId === item.zoneItemId
								)}
								queryItemsUpdatedAt={zoneQueriesQuery.dataUpdatedAt}
								itemUpdate={(updatedValue) => handleUpdateListItem(item, updatedValue)}
								itemDelete={() => handleDeleteListItem(item)}
								isCameraActive={isCameraActive}
								setIsCameraActive={setIsCameraActive}
								setShowEnterBarcode={setShowEnterBarcode}
								key={`${item.zoneItemId}`}
							/>
						))}
				</>
			</PageScrollNarrow>

			{!isPageLoading && isZoneFound ? (
				<XStack space="$3" maxWidth={800} w="100%" p="$3" $gtSm={{ p: "$6" }} als="center">
					{zoneQuery.data?.data.result?.status === ZoneItemStatus.Complete ? (
						<H5 theme="green" als="center" flex={1}>
							Zone Completed
						</H5>
					) : (
						<XStack space="$3" flex={1}>
							{(!hasCamera || isCameraActive) && (
								<Button
									fontSize="$3"
									width={0}
									flex={1}
									disabled={showEnterBarcode}
									onPress={() => {
										setShowEnterBarcode(true);
										setIsCameraActive(false);
									}}
									icon={TextCursor}
								>
									Enter Code
								</Button>
							)}
							{hasCamera && isCameraActive ? (
								<Button
									width={0}
									flex={1}
									onPress={() => setIsCameraActive(false)}
									icon={StopCircle}
								>
									Stop Scanning
								</Button>
							) : (
								<Button width={0} flex={1} onPress={() => setIsCameraActive(true)} icon={ScanLine}>
									Scan
								</Button>
							)}
						</XStack>
					)}
				</XStack>
			) : (
				<></>
			)}

			<Alert
				display={showEnterBarcode}
				title="Code"
				inputs={[
					{
						placeholder: "123456",
						keyboardType: "numeric",
						defaultValue: "",
						autoFocus: true,
					},
				]}
				buttons={[
					{
						text: "Done",
						onPress: (response) => {
							const barcodeValue = response?.inputs?.[0]?.value || "";
							if (barcodeValue.length) {
								handleInsertListItem(barcodeValue);
							}
						},
					},
				]}
				onClose={() => setShowEnterBarcode(false)}
			/>
		</PageContainer>
	);
}
