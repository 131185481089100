import { ArrowRight, Download } from "@tamagui/lucide-icons";
import * as React from "react";
import { useNavigate, useParams } from "react-router";
import { Paragraph, Spinner, YStack } from "tamagui";

import { useGetAdminStocktakeReview } from "../../../common/api/staverton.openapi/admin-stocktake";
import { Button } from "../../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";
import { openNewTab } from "../../../common/url/url";

export function AdminStocktakeReview() {
	const navigate = useNavigate();
	const params = useParams();

	const stocktakeReviewQuery = useGetAdminStocktakeReview({
		stocktakeId: Number(params.stocktakeId),
	});

	return (
		<PageContainer>
			<PageScrollNarrow>
				<PageHeader allowBack title="Stocktake Review" />

				<YStack space="$4">
					{stocktakeReviewQuery.isLoading && (
						<YStack justifyContent="center" space="$3">
							<Paragraph m={0} color="$colorTranslucent" ta="center">
								Getting ready for export.
							</Paragraph>
							<Spinner />
						</YStack>
					)}

					{!!stocktakeReviewQuery.data?.data.stocktake && (
						<Paragraph mt={0}>Your stocktake is ready for review.</Paragraph>
					)}

					{!!stocktakeReviewQuery.data?.data.stocktake && (
						<YStack>
							{!!stocktakeReviewQuery.data?.data.stocktake?.startedAt && (
								<YStack>
									<Paragraph m={0} color="$colorTranslucent">
										Started At
									</Paragraph>
									<Paragraph size="$5" m={0} color="$color">
										{new Date(stocktakeReviewQuery.data.data.stocktake.startedAt).toLocaleString(
											"en-GB"
										)}
									</Paragraph>
								</YStack>
							)}
							{!!stocktakeReviewQuery.data?.data.stocktake?.completedAt && (
								<YStack>
									<Paragraph m={0} color="$colorTranslucent">
										Completed At
									</Paragraph>
									<Paragraph size="$5" m={0} color="$color">
										{new Date(stocktakeReviewQuery.data.data.stocktake.completedAt).toLocaleString(
											"en-GB"
										)}
									</Paragraph>
								</YStack>
							)}
							{stocktakeReviewQuery.data?.data.stocktake?.totalItems != null && (
								<YStack>
									<Paragraph m={0} color="$colorTranslucent">
										Total number of lines counted
									</Paragraph>
									<Paragraph size="$5" m={0} color="$color">
										{stocktakeReviewQuery.data.data.stocktake.totalItems}
									</Paragraph>
								</YStack>
							)}
							{stocktakeReviewQuery.data?.data.stocktake?.sumItemCounts != null && (
								<YStack>
									<Paragraph m={0} color="$colorTranslucent">
										Total of line quantities
									</Paragraph>
									<Paragraph size="$5" m={0} color="$color">
										{stocktakeReviewQuery.data.data.stocktake.sumItemCounts}
									</Paragraph>
								</YStack>
							)}
							{stocktakeReviewQuery.data?.data.stocktake?.totalQueries != null && (
								<YStack>
									<Paragraph m={0} color="$colorTranslucent">
										Total queries
									</Paragraph>
									<Paragraph size="$5" m={0} color="$color">
										{stocktakeReviewQuery.data.data.stocktake.totalQueries}
									</Paragraph>
								</YStack>
							)}
						</YStack>
					)}

					{!stocktakeReviewQuery.isLoading && !!stocktakeReviewQuery.data?.data.s3Location && (
						<YStack space="$2">
							<Paragraph m={0}>
								Click the button to download your stocktake spreadsheet (Excel).
							</Paragraph>
							<Button
								icon={Download}
								onPress={() => openNewTab(stocktakeReviewQuery.data?.data.s3Location ?? "")}
							>
								Download
							</Button>
						</YStack>
					)}

					{stocktakeReviewQuery.isSuccess && stocktakeReviewQuery.data?.data.errorMessage && (
						<Paragraph m={0} color="$red9">
							{stocktakeReviewQuery.data?.data.errorMessage}
						</Paragraph>
					)}

					<Button iconAfter={ArrowRight} onPress={() => navigate("/")}>
						Continue
					</Button>
				</YStack>
			</PageScrollNarrow>
		</PageContainer>
	);
}
