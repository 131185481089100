import { LogOut } from "@tamagui/lucide-icons";
import React, { useEffect } from "react";
import { BackHandler } from "react-native";
import { Route, useNavigate } from "react-router";
import { H1 } from "tamagui";

import { useAuth, useAuthLogout } from "../common/auth/useAuth";
import { Button } from "../common/components";
import { PageContainer, PageScrollNarrow } from "../common/components/Page";
import { AvailabilityLists } from "../features/admin/availabilityLists/AdminAvailabilityList";
import { AdminQueryList } from "../features/admin/query/AdminQueryList";
import { AdminQueryResolve } from "../features/admin/query/AdminQueryResolve";
import { AdminStocktakeComplete } from "../features/admin/stocktake/AdminStocktakeComplete";
import { AdminStocktakeReview } from "../features/admin/stocktake/AdminStocktakeReview";
import { AdminStocktakeStart } from "../features/admin/stocktake/AdminStocktakeStart";
import { AdminZoneCreate } from "../features/admin/zone/AdminZoneCreate";
import { AdminZoneList } from "../features/admin/zone/AdminZoneList";
import { AdminZoneView } from "../features/admin/zone/AdminZoneView";
import { AlertList } from "../features/alert/AlertList";
import { DebugScreen } from "../features/debug/DebugScreen";
import { LoginScreen } from "../features/login/LoginScreen";
import { LookupProduct } from "../features/lookup/LookupProduct";
import { ProfileView } from "../features/profile/ProfileView";
import { QueryEdit } from "../features/query/QueryEdit";
import { QueryNew } from "../features/query/QueryNew";
import { StocktakeScreen } from "../features/stocktake/StocktakeScreen";
import { ZoneComplete } from "../features/zone/ZoneComplete";
import { ZoneScanItems } from "../features/zone/ZoneScanItems";
import { ZoneStartNew } from "../features/zone/ZoneStartNew";
import { Navigate, Routes } from "./router";
import { TabbedScreen } from "./tabbedScreen";

export function Navigation() {
	const navigate = useNavigate();
	useEffect(() => {
		const backAction = () => {
			navigate(-1);
			return true;
		};

		const backHandler = BackHandler.addEventListener("hardwareBackPress", backAction);

		return () => backHandler.remove();
	}, []);

	const auth = useAuth();
	const authLogout = useAuthLogout();

	if (!auth.isAuthenticated) {
		return (
			<Routes>
				<Route path="*" element={<Navigate to="/login" replace />} />
				<Route path="/login" element={<LoginScreen />} />
			</Routes>
		);
	}

	if (
		auth.isAuthenticated &&
		auth.details?.roles &&
		!auth.details.roles.some((role) =>
			[
				"Staverton.Role.Admin",
				"Staverton.Role.User",
				"Staverton.Role.AvailabilityListAdmin",
			].includes(role)
		)
	) {
		return (
			<Routes>
				<Route
					path="*"
					element={
						<PageContainer>
							<PageScrollNarrow>
								<H1>Not authorized</H1>
								<Button icon={LogOut} onPress={authLogout}>
									Logout
								</Button>
							</PageScrollNarrow>
						</PageContainer>
					}
				/>
			</Routes>
		);
	}

	return (
		<Routes>
			<Route element={<TabbedScreen />}>
				<Route
					path="*"
					element={
						<Navigate
							to={
								auth.isAdmin
									? "/admin/zones"
									: auth.isAvailabilityListAdmin
									? "/admin/availabilityLists"
									: "/stocktake"
							}
							replace
						/>
					}
				/>

				{auth.isAvailabilityListAdmin && (
					<Route path="admin">
						<Route path="availability" element={<AvailabilityLists />} />
					</Route>
				)}

				{auth.isAdmin ? (
					<Route path="admin">
						<Route path="query" element={<AdminQueryList />} />
						<Route path="query/resolve/:zoneItemQueryId" element={<AdminQueryResolve />} />
						<Route path="stocktake/complete" element={<AdminStocktakeComplete />} />
						<Route path="stocktake/review/:stocktakeId" element={<AdminStocktakeReview />} />
						<Route path="stocktake/start" element={<AdminStocktakeStart />} />
						<Route path="zone/:zoneCode" element={<AdminZoneView />} />
						<Route path="zone/create" element={<AdminZoneCreate />} />
						<Route path="zones" element={<AdminZoneList />} />
					</Route>
				) : (
					<Route
						path="admin/*"
						element={
							<PageContainer>
								<PageScrollNarrow>
									<H1>Not authorized</H1>
								</PageScrollNarrow>
							</PageContainer>
						}
					/>
				)}

				<Route path="alerts" element={<AlertList />} />
				<Route path="lookup" element={<LookupProduct />} />
				<Route path="profile" element={<ProfileView />} />
				<Route path="query/new/:zoneCode/:zoneItemId" element={<QueryNew />} />
				<Route path="query/new/:zoneCode" element={<QueryNew />} />
				<Route path="query/edit/:zoneItemQueryId" element={<QueryEdit />} />
				<Route path="stocktake" element={<StocktakeScreen />} />
				<Route path="zone/complete/:zoneCode" element={<ZoneComplete />} />
				<Route path="zone/new" element={<ZoneStartNew />} />
				<Route path="zone/scan/:zoneCode" element={<ZoneScanItems />} />
				<Route path="debug" element={<DebugScreen />} />
			</Route>
		</Routes>
	);
}
