import { ViewProps } from "react-native";
import Animated, { useAnimatedKeyboard, useAnimatedStyle } from "react-native-reanimated";
import { useSafeAreaInsets } from "react-native-safe-area-context";

export function KeyboardPaddedView({ children, ...rest }: ViewProps) {
	const safeAreaInsets = useSafeAreaInsets();
	const { height: keyboardHeight } = useAnimatedKeyboard();
	const keyboardPadding = useAnimatedStyle(() => {
		return {
			paddingBottom: Math.max(keyboardHeight.value, safeAreaInsets.bottom),
		};
	}, [keyboardHeight, safeAreaInsets.bottom]);
	return (
		<Animated.View {...rest} style={keyboardPadding}>
			{children}
		</Animated.View>
	);
}
