/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	AllCategoriesResponse,
	CategoryResponse,
	CategoryDetailsParams,
} from "./index.schemas";

export const useCategoriesHook = () => {
	const categories = useCustomInstance<AllCategoriesResponse>();

	return (signal?: AbortSignal) => {
		return categories({ url: `/AvailabilityLists/Index`, method: "get", signal });
	};
};

export const getCategoriesQueryKey = () => [`/AvailabilityLists/Index`];

export type CategoriesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCategoriesHook>>>
>;
export type CategoriesQueryError = unknown;

export const useCategories = <
	TData = Awaited<ReturnType<ReturnType<typeof useCategoriesHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useCategoriesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getCategoriesQueryKey();

	const categories = useCategoriesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCategoriesHook>>>> = ({
		signal,
	}) => categories(signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useCategoriesHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useCategoryDetailsHook = () => {
	const categoryDetails = useCustomInstance<CategoryResponse>();

	return (params?: CategoryDetailsParams, signal?: AbortSignal) => {
		return categoryDetails({ url: `/AvailabilityLists/Details`, method: "get", params, signal });
	};
};

export const getCategoryDetailsQueryKey = (params?: CategoryDetailsParams) => [
	`/AvailabilityLists/Details`,
	...(params ? [params] : []),
];

export type CategoryDetailsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCategoryDetailsHook>>>
>;
export type CategoryDetailsQueryError = unknown;

export const useCategoryDetails = <
	TData = Awaited<ReturnType<ReturnType<typeof useCategoryDetailsHook>>>,
	TError = unknown
>(
	params?: CategoryDetailsParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useCategoryDetailsHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getCategoryDetailsQueryKey(params);

	const categoryDetails = useCategoryDetailsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useCategoryDetailsHook>>>> = ({
		signal,
	}) => categoryDetails(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useCategoryDetailsHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};
