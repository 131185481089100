import { X } from "@tamagui/lucide-icons";
import { AxiosResponse } from "axios";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { Alert as NativeAlert, Image, Platform } from "react-native";
import { UseMutationResult } from "react-query";
import { Paragraph, TextArea, Unspaced, XStack, YStack } from "tamagui";

import { putBlob } from "../../common/api/blobUploader";
import {
	MultipartUploadDetails,
	QueryImageStartUploadRequest,
	QueryImageStartUploadResponse,
} from "../../common/api/staverton.openapi/index.schemas";
import { Button, ScrollView } from "../../common/components";
import { Alert } from "../../common/components/Alert";

export interface QueryItemImage {
	uri?: string;
	base64?: string;
	fileName?: string;
	type?: string;

	s3Location?: string;
	zoneItemQueryImageId?: number;
}

export interface QueryItem {
	zoneItemQueryId: number | null;
	zoneItemId: number | null;
	zoneCode: string;
	details: string;
	images: QueryItemImage[];
}

export function QueryEditForm({
	query,
	setQuery,
	deleteQueryImage,
}: {
	query: QueryItem;
	setQuery: (val: QueryItem) => void;
	deleteQueryImage?: (zoneItemQueryImageId: number) => void;
}) {
	const [displayAlertAddImage, setDisplayAlertAddImage] = React.useState<boolean>(false);
	const [displayAlertRemoveImage, setDisplayAlertRemoveImage] = React.useState<boolean>(false);
	const [alertImageToRemoveImage, setAlertImageToRemoveImage] = React.useState<QueryItemImage>();

	function addQueryImage(image: ImagePicker.ImagePickerAsset) {
		// Prevent selecting unsupported image type
		if (!(image?.fileName || image?.uri)?.match(/(.jpg|.jpeg|.png)/)) {
			const errorMsg = "Unsupported image format. Please select a jpeg, jpg or png image";
			return Platform.OS === "web" ? alert(errorMsg) : NativeAlert.alert(errorMsg);
		}

		const updatedQueryItem = { ...query };
		updatedQueryItem.images = [
			{
				base64: image.base64!,
				uri: image.uri,
				fileName: getImageFileName(image),
				type: image.type,
			},
			...updatedQueryItem.images,
		];
		setQuery(updatedQueryItem);
	}

	async function captureImage() {
		const result: ImagePicker.ImagePickerResult = await ImagePicker.launchCameraAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0.4,
			selectionLimit: 1,
			base64: true,
		});

		const firstAsset = result.assets?.[0];
		if (!firstAsset) return;

		addQueryImage(firstAsset);
	}

	async function pickImage() {
		const result: ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0.4,
			selectionLimit: 1,
			base64: true,
		});
		const firstAsset = result.assets?.[0];
		if (!firstAsset) return;

		addQueryImage(firstAsset);
	}

	function removeImage() {
		if (!alertImageToRemoveImage) return;

		const updatedQueryItem = { ...query };
		updatedQueryItem.images = [
			...updatedQueryItem.images.filter((i) => i !== alertImageToRemoveImage),
		];
		setQuery(updatedQueryItem);

		if (alertImageToRemoveImage.zoneItemQueryImageId) {
			deleteQueryImage?.(alertImageToRemoveImage.zoneItemQueryImageId);
		}
	}

	function getImageFileName(image: ImagePicker.ImageInfo) {
		return image.fileName || image.uri.substring(image.uri.lastIndexOf("/") + 1) || "";
	}

	return (
		<>
			<YStack mb="$3">
				<Paragraph mb="$1">Query details</Paragraph>
				<TextArea
					height="$9"
					textAlignVertical="top"
					paddingVertical="$3"
					autoFocus
					value={query.details}
					onChangeText={(inputValue: string) => {
						setQuery({ ...query, details: inputValue });
					}}
				/>
			</YStack>

			<YStack mb="$3">
				<Paragraph mb="$1">Image</Paragraph>

				{query.images.length > 0 && (
					<ScrollView mb="$3" flex={1} horizontal>
						<XStack space="$3">
							{query.images.map((image) => (
								<YStack position="relative" key={image.zoneItemQueryImageId ?? image.uri}>
									<Image
										source={{ uri: image.s3Location ?? image.uri, width: 128, height: 128 }}
										onError={() => {
											setAlertImageToRemoveImage(image);
											removeImage();
										}}
									/>
									<Button
										position="absolute"
										top={3}
										right={3}
										size="$2"
										theme="red_alt2"
										icon={X}
										onPress={() => {
											setAlertImageToRemoveImage(image);
											setDisplayAlertRemoveImage(true);
										}}
									/>
								</YStack>
							))}
						</XStack>
					</ScrollView>
				)}

				<Button
					onPress={() => (Platform.OS === "web" ? pickImage() : setDisplayAlertAddImage(true))}
				>
					Add Image
				</Button>

				<Unspaced>
					<Alert
						display={displayAlertAddImage}
						title="Add image to query"
						buttons={[
							{
								text: "Capture Image",
								onPress: captureImage,
							},
							{
								text: "Select Image",
								onPress: pickImage,
							},
							{
								text: "Cancel",
							},
						]}
						onClose={() => setDisplayAlertAddImage(false)}
					/>

					<Alert
						display={displayAlertRemoveImage}
						title="Remove image"
						message="Are you sure you wish to remove this image?"
						buttons={[
							{
								text: "Remove",
								onPress: () => removeImage(),
							},
							{
								text: "Cancel",
							},
						]}
						onClose={() => setDisplayAlertRemoveImage(false)}
					/>
				</Unspaced>
			</YStack>
		</>
	);
}

export async function startUploadingQueryImage(
	image: QueryItemImage,
	zoneCode: string,
	zoneItemId: number | null,
	startUpload: UseMutationResult<
		AxiosResponse<QueryImageStartUploadResponse, any>,
		unknown,
		{
			data: QueryImageStartUploadRequest;
		},
		unknown
	>
): Promise<
	| {
			success: true;
			eTags: string[];
			details: MultipartUploadDetails;
	  }
	| {
			success: false;
			errorMessage: string;
	  }
> {
	try {
		if (!image.base64)
			return {
				success: false,
				errorMessage: "Image not valid",
			};

		// Chunk the image base64 string for larger uploads
		const chars = 12000000;
		const chunks = Math.ceil(image.base64.length / chars);
		const chunkedBase64 = [];
		for (let i = 0; i < chunks; i++) {
			chunkedBase64.push(image.base64.slice(i * chars, (i + 1) * chars));
		}

		// Get the presigned s3 urls to upload the image
		const started = await startUpload
			.mutateAsync({
				data: {
					zoneItemId,
					zoneCode,
					chunks,
					contentType: "image/jpeg",
				},
			})
			.catch((e) => {
				console.error(`image start error `, e);
			});
		if (!started) {
			return {
				success: false,
				errorMessage: `Failed to start upload ${image.fileName}`,
			};
		}

		// Upload the image chunks to the presigned S3 urls
		const eTags = [];
		let index = 0;
		for (const url of started.data.urls) {
			const result = await putBlob({
				url,
				data: chunkedBase64[index],
			});
			if (!result?.eTag) {
				return {
					success: false,
					errorMessage: `Failed to start upload ${image.fileName}`,
				};
			}

			eTags.push(result.eTag);
			index++;
		}

		return {
			success: true,
			eTags,
			details: started.data.details,
		};
	} catch (e) {
		console.error(e);
		return {
			success: false,
			errorMessage: `Failed to start upload ${image.fileName}`,
		};
	}
}
