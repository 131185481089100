/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	AdminQueryListResponse,
	AdminListQueriesParams,
	QueryUpdateClaimedResponse,
	QueryUpdateClaimedRequest,
	QueryUpdateResolvedResponse,
	QueryUpdateResolvedRequest,
} from "./index.schemas";

export const useAdminListQueriesHook = () => {
	const adminListQueries = useCustomInstance<AdminQueryListResponse>();

	return (params?: AdminListQueriesParams, signal?: AbortSignal) => {
		return adminListQueries({ url: `/AdminQuery/List`, method: "get", params, signal });
	};
};

export const getAdminListQueriesQueryKey = (params?: AdminListQueriesParams) => [
	`/AdminQuery/List`,
	...(params ? [params] : []),
];

export type AdminListQueriesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminListQueriesHook>>>
>;
export type AdminListQueriesQueryError = unknown;

export const useAdminListQueries = <
	TData = Awaited<ReturnType<ReturnType<typeof useAdminListQueriesHook>>>,
	TError = unknown
>(
	params?: AdminListQueriesParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useAdminListQueriesHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getAdminListQueriesQueryKey(params);

	const adminListQueries = useAdminListQueriesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useAdminListQueriesHook>>>> = ({
		signal,
	}) => adminListQueries(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useAdminListQueriesHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useAdminUpdateQueryClaimedHook = () => {
	const adminUpdateQueryClaimed = useCustomInstance<QueryUpdateClaimedResponse>();

	return (queryUpdateClaimedRequest: QueryUpdateClaimedRequest) => {
		return adminUpdateQueryClaimed({
			url: `/AdminQuery/UpdateClaimed`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: queryUpdateClaimedRequest,
		});
	};
};

export type AdminUpdateQueryClaimedMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryClaimedHook>>>
>;
export type AdminUpdateQueryClaimedMutationBody = QueryUpdateClaimedRequest;
export type AdminUpdateQueryClaimedMutationError = unknown;

export const useAdminUpdateQueryClaimed = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryClaimedHook>>>,
		TError,
		{ data: QueryUpdateClaimedRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const adminUpdateQueryClaimed = useAdminUpdateQueryClaimedHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryClaimedHook>>>,
		{ data: QueryUpdateClaimedRequest }
	> = (props) => {
		const { data } = props ?? {};

		return adminUpdateQueryClaimed(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof adminUpdateQueryClaimed>>,
		TError,
		{ data: QueryUpdateClaimedRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useAdminUpdateQueryResolvedHook = () => {
	const adminUpdateQueryResolved = useCustomInstance<QueryUpdateResolvedResponse>();

	return (queryUpdateResolvedRequest: QueryUpdateResolvedRequest) => {
		return adminUpdateQueryResolved({
			url: `/AdminQuery/UpdateResolved`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: queryUpdateResolvedRequest,
		});
	};
};

export type AdminUpdateQueryResolvedMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryResolvedHook>>>
>;
export type AdminUpdateQueryResolvedMutationBody = QueryUpdateResolvedRequest;
export type AdminUpdateQueryResolvedMutationError = unknown;

export const useAdminUpdateQueryResolved = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryResolvedHook>>>,
		TError,
		{ data: QueryUpdateResolvedRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const adminUpdateQueryResolved = useAdminUpdateQueryResolvedHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useAdminUpdateQueryResolvedHook>>>,
		{ data: QueryUpdateResolvedRequest }
	> = (props) => {
		const { data } = props ?? {};

		return adminUpdateQueryResolved(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof adminUpdateQueryResolved>>,
		TError,
		{ data: QueryUpdateResolvedRequest },
		TContext
	>(mutationFn, mutationOptions);
};
