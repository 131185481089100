/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	UserNotificationTopicListResponse,
	UserNotificationTopicCreateResponse,
	UserNotificationTopicCreateRequest,
	UserNotificationTopicUpdateResponse,
	UserNotificationTopicUpdateRequest,
	UserNotificationTopicDeleteResponse,
	DeleteUserNotificationTopicParams,
} from "./index.schemas";

export const useListUserNotificationTopicsHook = () => {
	const listUserNotificationTopics = useCustomInstance<UserNotificationTopicListResponse>();

	return (signal?: AbortSignal) => {
		return listUserNotificationTopics({
			url: `/UserNotificationTopic/List`,
			method: "get",
			signal,
		});
	};
};

export const getListUserNotificationTopicsQueryKey = () => [`/UserNotificationTopic/List`];

export type ListUserNotificationTopicsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListUserNotificationTopicsHook>>>
>;
export type ListUserNotificationTopicsQueryError = unknown;

export const useListUserNotificationTopics = <
	TData = Awaited<ReturnType<ReturnType<typeof useListUserNotificationTopicsHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useListUserNotificationTopicsHook>>>,
		TError,
		TData
	>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListUserNotificationTopicsQueryKey();

	const listUserNotificationTopics = useListUserNotificationTopicsHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useListUserNotificationTopicsHook>>>
	> = ({ signal }) => listUserNotificationTopics(signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useListUserNotificationTopicsHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useCreateUserNotificationTopicHook = () => {
	const createUserNotificationTopic = useCustomInstance<UserNotificationTopicCreateResponse>();

	return (userNotificationTopicCreateRequest: UserNotificationTopicCreateRequest) => {
		return createUserNotificationTopic({
			url: `/UserNotificationTopic/Create`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: userNotificationTopicCreateRequest,
		});
	};
};

export type CreateUserNotificationTopicMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCreateUserNotificationTopicHook>>>
>;
export type CreateUserNotificationTopicMutationBody = UserNotificationTopicCreateRequest;
export type CreateUserNotificationTopicMutationError = unknown;

export const useCreateUserNotificationTopic = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCreateUserNotificationTopicHook>>>,
		TError,
		{ data: UserNotificationTopicCreateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const createUserNotificationTopic = useCreateUserNotificationTopicHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCreateUserNotificationTopicHook>>>,
		{ data: UserNotificationTopicCreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return createUserNotificationTopic(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof createUserNotificationTopic>>,
		TError,
		{ data: UserNotificationTopicCreateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useUpdateUserNotificationTopicHook = () => {
	const updateUserNotificationTopic = useCustomInstance<UserNotificationTopicUpdateResponse>();

	return (userNotificationTopicUpdateRequest: UserNotificationTopicUpdateRequest) => {
		return updateUserNotificationTopic({
			url: `/UserNotificationTopic/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: userNotificationTopicUpdateRequest,
		});
	};
};

export type UpdateUserNotificationTopicMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationTopicHook>>>
>;
export type UpdateUserNotificationTopicMutationBody = UserNotificationTopicUpdateRequest;
export type UpdateUserNotificationTopicMutationError = unknown;

export const useUpdateUserNotificationTopic = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationTopicHook>>>,
		TError,
		{ data: UserNotificationTopicUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateUserNotificationTopic = useUpdateUserNotificationTopicHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationTopicHook>>>,
		{ data: UserNotificationTopicUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateUserNotificationTopic(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateUserNotificationTopic>>,
		TError,
		{ data: UserNotificationTopicUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteUserNotificationTopicHook = () => {
	const deleteUserNotificationTopic = useCustomInstance<UserNotificationTopicDeleteResponse>();

	return (params?: DeleteUserNotificationTopicParams) => {
		return deleteUserNotificationTopic({
			url: `/UserNotificationTopic/Delete`,
			method: "delete",
			params,
		});
	};
};

export type DeleteUserNotificationTopicMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteUserNotificationTopicHook>>>
>;

export type DeleteUserNotificationTopicMutationError = unknown;

export const useDeleteUserNotificationTopic = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserNotificationTopicHook>>>,
		TError,
		{ params?: DeleteUserNotificationTopicParams },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteUserNotificationTopic = useDeleteUserNotificationTopicHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserNotificationTopicHook>>>,
		{ params?: DeleteUserNotificationTopicParams }
	> = (props) => {
		const { params } = props ?? {};

		return deleteUserNotificationTopic(params);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteUserNotificationTopic>>,
		TError,
		{ params?: DeleteUserNotificationTopicParams },
		TContext
	>(mutationFn, mutationOptions);
};
