import {Alert} from "../../../common/components/Alert";
import {H4, Paragraph, Separator, Spinner, XStack, YStack} from "tamagui";
import * as React from "react";
import {useCategoryDetails} from "../../../common/api/staverton.openapi/availability-lists";

export function StockItemListAlert({categoryId, onClose}: { categoryId: number, onClose: () => void }) {
	const query = !!categoryId ? useCategoryDetails({id: categoryId}) : null;

	return (
		<Alert onClose={() => {
			onClose();
		}} showCloseCross={true} display={!!categoryId} size="large" >
			<YStack>
				<XStack py="$2" px="$3" alignItems="center" space="$2">
					<XStack w="20%">
						<H4 m={0} color="$color" flex={1}>
							Stock code
						</H4>
					</XStack>
					<XStack
						display = "flex"
						flex={1}
						w = "40%"
						ai="center"
					>
						<H4 m={0} color="$color" flex={1}>
							Description
						</H4>
					</XStack>

					<XStack
						display = "flex"
						flex={1}
						w = "20%"
						ai="center">
						<H4 m={0} color="$color">
							Price
						</H4>
					</XStack>

					<XStack
						display = "flex"
						flex={1}
						w = "20%"
						ai="center">
						<H4 m={0} color="$color" flex={1}>
							Quantity available
						</H4>
					</XStack>

				</XStack>


				{query?.isLoading && (
					<>
						<Separator mb="$3"/>
						<XStack jc="center">
							<Spinner/>
						</XStack>
					</>
				)}

				{query?.isFetched &&
					query?.data?.data?.stockItems &&
					query?.data?.data?.stockItems.map((stockItem) => (
						<XStack key={stockItem.stockCode} py="$2" px="$3" alignItems="center" space="$3">
							<XStack w="20%">
								<Paragraph m={0} color="$color" flex={1}>
									{stockItem.stockCode}
								</Paragraph>
							</XStack>

								<XStack
									display = "flex"
									flex={1}
									w = "40%"
									ai="center"
								>
									<Paragraph m={0} color="$color" flex={1}>
										{stockItem.description}
									</Paragraph>
								</XStack>

								<XStack
									display = "flex"
									flex={1}
									w = "20%"
									ai="center">
									<Paragraph m={0} color="$color">
										£{stockItem.sellingPrice?.toFixed(2) ?? " - "}
									</Paragraph>
								</XStack>

							<XStack
								display = "flex"
								flex={1}
								w = "20%"
								ai="center">
								<Paragraph m={0} color="$color" flex={1}>
									{stockItem.quantityAvailable > 0 ? stockItem.quantityAvailable : "out of stock"}
								</Paragraph>
							</XStack>

						</XStack>
					))}

				{query?.isFetched && query?.data?.data?.stockItems?.length === 0 && (
					<>
						<Separator mb="$3"/>
						<Paragraph ta="center" color="$colorTranslucent">
							No items to show
						</Paragraph>
					</>
				)}
			</YStack>

		</Alert>
	);
}
