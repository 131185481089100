import { Bell, Check } from "@tamagui/lucide-icons";
import * as React from "react";
import { Platform } from "react-native";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router";
import { YStack, XStack, Spinner, SizableText, Paragraph } from "tamagui";

import { queryClient } from "../../App";
import { UserNotificationItem } from "../../common/api/staverton.openapi/index.schemas";
import {
	useListUserNotifications,
	useUpdateUserNotification,
} from "../../common/api/staverton.openapi/user-notification";
import { useAuth } from "../../common/auth/useAuth";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";

function AlertItem({
	notification,
	onUpdate,
}: {
	notification: UserNotificationItem;
	onUpdate: (value: UserNotificationItem) => void;
}) {
	const updateUserNotificationQuery = useUpdateUserNotification();
	const auth = useAuth();
	const navigate = useNavigate();

	async function handleNotificationMarkAsRead() {
		if (!notification.readAt) {
			const readAt = new Date().toISOString();

			await updateUserNotificationQuery.mutateAsync({
				data: {
					userNotificationId: notification.userNotificationId,
					readAt,
				},
			});

			onUpdate({ ...notification, readAt });
		}
	}

	async function handleNotificationPress() {
		handleNotificationMarkAsRead();

		if (notification.zoneCode && auth.isAdmin) {
			navigate(`/admin/zone/${notification.zoneCode}`);
		}
	}

	return (
		<YStack br="$3" bc={notification.readAt === null ? "$blue4" : "$blue3"}>
			<XStack
				br="$3"
				p="$3"
				ai="center"
				space="$3"
				cursor={Platform.OS === "web" ? "pointer" : undefined}
				hoverStyle={{
					bc: notification.readAt === null ? "$blue3" : "$blue2",
				}}
				onPress={() => handleNotificationPress()}
			>
				{notification.readAt === null && <Bell size={16} color="$color" />}
				<YStack flex={1}>
					<SizableText
						size="$4"
						flex={1}
						lineHeight="$1"
						color={notification.readAt === null ? "$color" : "$colorTranslucent"}
						fontWeight={notification.readAt === null ? "900" : "500"}
					>
						{notification.title}
					</SizableText>
					{notification.body != null && (
						<SizableText
							size="$3"
							flex={1}
							color={notification.readAt === null ? "$color" : "$colorTranslucent"}
						>
							{notification.body}
						</SizableText>
					)}
					<SizableText size="$1" flex={1} color="$colorTranslucent">
						{new Date(notification.notifiedAt).toLocaleString("en-GB")}
					</SizableText>
				</YStack>
			</XStack>
			{notification.readAt === null && (
				<XStack btw={1} btc="$background" p="$3" ai="center" space="$3">
					<Button
						size="$2"
						theme="blue_alt2"
						icon={Check}
						disabled={updateUserNotificationQuery.isLoading}
						onPress={handleNotificationMarkAsRead}
					>
						Mark as Read
					</Button>
				</XStack>
			)}
		</YStack>
	);
}

export function AlertList() {
	const [userNotifications, setUserNotifications] = React.useState<UserNotificationItem[]>([]);
	const [pageFrom, setPageFrom] = React.useState<number>(0);
	const pageSize = 20;

	const listUserNotificationsQuery = useListUserNotifications({
		pageFrom,
		pageSize,
	});

	React.useEffect(() => {
		if (listUserNotificationsQuery.isLoading || !listUserNotificationsQuery.data?.data.results)
			return;

		// Append the new unique results to handle paging of notifications
		const existingIds = userNotifications.map((v) => v.userNotificationId);
		const newResults = listUserNotificationsQuery.data.data.results.filter(
			(r) => !existingIds.includes(r.userNotificationId)
		);
		const allNotifications = [...userNotifications, ...newResults].sort((a, b) => {
			if (a.readAt && b.readAt) return a.readAt > b.readAt ? -1 : 1;
			else if (a.readAt) return 1;
			else if (b.readAt) return -1;
			return a.notifiedAt > b.notifiedAt ? -1 : 1;
		});
		setUserNotifications(allNotifications);

		queryClient.refetchQueries({ queryKey: "TabbedUserNotifications" });
	}, [
		listUserNotificationsQuery.isFetchedAfterMount,
		listUserNotificationsQuery.isLoading,
		listUserNotificationsQuery.isRefetching,
	]);

	function handleNotificationUpdate(
		notification: UserNotificationItem,
		updatedNotification: UserNotificationItem
	) {
		setUserNotifications(
			// Update the fetched version of the notification
			userNotifications.map((userNotification) =>
				userNotification === notification ? { ...updatedNotification } : userNotification
			)
		);

		// Invalidate queries to help the tabbedScreen count to update
		queryClient.invalidateQueries();
		queryClient.refetchQueries({ queryKey: "TabbedUserNotifications" });
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<PageHeader allowBack title="Alerts" />

				<YStack space="$3">
					{userNotifications.map((notification) => (
						<AlertItem
							key={notification.userNotificationId}
							notification={notification}
							onUpdate={(val) => handleNotificationUpdate(notification, val)}
						/>
					))}

					{!listUserNotificationsQuery.isLoading && userNotifications.length === 0 && (
						<Paragraph ta="center" color="$colorTranslucent">
							No alerts to show
						</Paragraph>
					)}

					{listUserNotificationsQuery.isLoading && (
						<XStack jc="center">
							<Spinner />
						</XStack>
					)}

					{!listUserNotificationsQuery.isLoading &&
						userNotifications.length === pageFrom + pageSize && (
							<XStack jc="center">
								<Button onPress={() => setPageFrom(pageFrom + pageSize)}>Load more</Button>
							</XStack>
						)}
				</YStack>
			</PageScrollNarrow>
		</PageContainer>
	);
}
