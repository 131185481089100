import * as React from "react";
import { useNavigate } from "react-router";
import { YStack, XStack, Input, Paragraph } from "tamagui";

import { useAdminCreateZones } from "../../../common/api/staverton.openapi/admin-zone";
import { Button } from "../../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";

export interface CreateZoneForm {
	fromValue: string;
	toValue: string;
}

export function AdminZoneCodesForm({
	fromValue,
	toValue,
	updateFromValue,
	updateToValue,
}: {
	fromValue: string;
	toValue: string;
	updateFromValue: (val: string) => void;
	updateToValue: (val: string) => void;
}) {
	function formatZoneNumberInput(value: string) {
		return String(value).replace(/\D/gm, "");
	}

	return (
		<XStack space="$3">
			<YStack flex={1}>
				<Paragraph mb="$1" color="$color">
					From
				</Paragraph>
				<Input
					keyboardType="numeric"
					autoFocus
					value={fromValue}
					onChangeText={(value: string) => updateFromValue(formatZoneNumberInput(value))}
				/>
			</YStack>
			<YStack flex={1}>
				<Paragraph mb="$1" color="$color">
					To
				</Paragraph>
				<Input
					keyboardType="numeric"
					value={toValue}
					onChangeText={(value: string) => updateToValue(formatZoneNumberInput(value))}
				/>
			</YStack>
		</XStack>
	);
}

export function AdminZoneCreate() {
	const navigate = useNavigate();
	const createZonesQuery = useAdminCreateZones();

	const [createZoneForm, setCreateZoneForm] = React.useState<CreateZoneForm>({
		fromValue: "",
		toValue: "",
	});
	const [createZoneFormError, setCreateZoneFormError] = React.useState<string>();

	let createZonesCount = 0;
	if (
		createZoneForm.fromValue &&
		createZoneForm.toValue &&
		parseInt(createZoneForm.fromValue) <= parseInt(createZoneForm.toValue)
	) {
		createZonesCount = parseInt(createZoneForm.toValue) - parseInt(createZoneForm.fromValue) + 1;
	}

	function handleFormUpdate() {
		setCreateZoneFormError(undefined);
	}

	function isValidNumber(value: string) {
		return !isNaN(parseInt(value)) && parseInt(value) > 0;
	}

	function validateForm(formData: CreateZoneForm) {
		if (!isValidNumber(formData.fromValue)) {
			setCreateZoneFormError("Zone Number From is an invalid number");
			return false;
		}
		if (!isValidNumber(formData.toValue)) {
			setCreateZoneFormError("Zone Number To is an invalid number");
			return false;
		}
		if (parseInt(formData.fromValue) > parseInt(formData.toValue)) {
			setCreateZoneFormError("Zone Number To must be greater than Zone Number From");
			return false;
		}
		if (createZonesCount > 999) {
			setCreateZoneFormError("Too many zones to create at one time");
			return false;
		}

		setCreateZoneFormError(undefined);
		return true;
	}

	async function handleSaveCreateZones() {
		if (!validateForm({ ...createZoneForm })) return;

		try {
			const response = await createZonesQuery.mutateAsync({
				data: {
					fromZoneCode: Number(createZoneForm.fromValue || 0),
					toZoneCode: Number(createZoneForm.toValue || 0),
				},
			});

			if (response.data.errorMessage) {
				const relatedCodes = response.data.existingZoneCodes || [];
				let codesString = "";

				if (relatedCodes.length > 1) {
					codesString = relatedCodes
						.slice(0, 3)
						.map((z) => z.code)
						.join(", ");
					if (relatedCodes.length > 3) codesString += "...";
					if (codesString.length) codesString = ` (${codesString})`;
				}

				setCreateZoneFormError(response.data.errorMessage + codesString);
			} else if (response.data.result) {
				setCreateZoneFormError(undefined);
				navigate("/");
			}
		} catch (err) {
			setCreateZoneFormError("Something went wrong");
		}
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title="Create Zones" />

					<Paragraph size="$6" mb="$1" color="$color">
						Zone Numbers
					</Paragraph>

					<AdminZoneCodesForm
						fromValue={createZoneForm.fromValue}
						toValue={createZoneForm.toValue}
						updateFromValue={(val) => {
							setCreateZoneForm({ ...createZoneForm, fromValue: val });
							handleFormUpdate();
						}}
						updateToValue={(val) => {
							setCreateZoneForm({ ...createZoneForm, toValue: val });
							handleFormUpdate();
						}}
					/>

					<XStack mt="$6">
						<Button disabled={createZonesQuery.isLoading} onPress={() => handleSaveCreateZones()}>
							{createZonesCount
								? createZonesCount === 1
									? "Create Zone"
									: `Create ${createZonesCount.toString()} Zones`
								: "Create Zones"}
						</Button>
					</XStack>

					{createZoneFormError && (
						<Paragraph color="$red9" mt="$3">
							{createZoneFormError}
						</Paragraph>
					)}
				</>
			</PageScrollNarrow>
		</PageContainer>
	);
}
