import * as Notifications from "expo-notifications";
import { useEffect } from "react";
import { Platform } from "react-native";
import { useQuery } from "react-query";

import {
	useCreateUserDevice,
	useListUserDevices,
} from "../common/api/staverton.openapi/user-device";
import {
	useCreateUserNotificationTopic,
	useDeleteUserNotificationTopic,
	useListUserNotificationTopics,
} from "../common/api/staverton.openapi/user-notification-topic";
import { useAuth } from "../common/auth/useAuth";

export async function hasPermission() {
	if (Platform.OS === "web") return false;

	const { status } = await Notifications.getPermissionsAsync();
	return status === "granted";
}

export async function requestPermission() {
	if (Platform.OS === "web") return;

	await Notifications.requestPermissionsAsync();
}

export async function registerForPushNotifications() {
	if (Platform.OS === "web") return;

	let enabled = await hasPermission();
	if (!enabled) {
		await requestPermission();
		enabled = await hasPermission();
	}
	if (!enabled) {
		console.warn("Notification permission not authorized for push notifications");
		return;
	}

	const { data: token } = await Notifications.getExpoPushTokenAsync({
		projectId: "b43754b8-94c3-4724-ab0c-0fe8cfd43bd4",
	});

	if (Platform.OS === "android") {
		await Notifications.setNotificationChannelAsync("default", {
			name: "default",
			importance: Notifications.AndroidImportance.MAX,
			vibrationPattern: [0, 250, 250, 250],
			lightColor: "#FF231F7C",
		});
	}

	return token;
}

export function AuthenticatedNotification() {
	const auth = useAuth();
	const listUserNotificationTopicsQuery = useListUserNotificationTopics();
	const createUserNotificationTopicQuery = useCreateUserNotificationTopic();
	const deleteUserNotificationTopicQuery = useDeleteUserNotificationTopic();

	const createUserTopic = async (topic: string) => {
		createUserNotificationTopicQuery.mutateAsync({
			data: {
				topic,
				active: true,
			},
		});
	};

	const deleteUserTopic = async (userNotificationTopicId: number) => {
		deleteUserNotificationTopicQuery.mutateAsync({
			params: {
				userNotificationTopicId,
			},
		});
	};

	useEffect(() => {
		// Handle user notification topics
		if (
			!listUserNotificationTopicsQuery.isFetchedAfterMount ||
			!listUserNotificationTopicsQuery.data?.data.results
		)
			return;

		const userTopics = listUserNotificationTopicsQuery.data.data.results;

		const baseTopics: string[] = [];
		const adminTopics = ["admin_query_new", "admin_zone_complete"];
		const allowedTopics = [...baseTopics];
		if (auth.isAdmin) allowedTopics.push(...adminTopics);

		// Create new topic if they do not have one
		const createTopics = allowedTopics.filter(
			(topic) => !userTopics.some((t) => t.topic === topic)
		);
		createTopics.forEach((createTopic) => {
			createUserTopic(createTopic);
		});

		// Delete old topics (handles non-admin users having admin topics)
		const deleteNotificationTopics = userTopics.filter((t) => !allowedTopics.includes(t.topic));
		deleteNotificationTopics.forEach((deleteNotificationTopic) => {
			deleteUserTopic(deleteNotificationTopic.userNotificationTopicId);
		});
	}, [auth.isAdmin, listUserNotificationTopicsQuery.isFetchedAfterMount]);

	const listUserDevicesQuery = useListUserDevices();
	const createUserDevicesQuery = useCreateUserDevice();

	const notificationToken = useQuery(["expoPushToken"], registerForPushNotifications, {
		enabled: auth.isAdmin,
		refetchInterval: false,
	});

	const updateNotificationToken = async (token: string) => {
		const matchingUserDevices = listUserDevicesQuery.data?.data.results?.filter(
			(device) => device.token === token
		);

		// Create new device for admin users if they do not have one
		if (matchingUserDevices?.length === 0) {
			await createUserDevicesQuery.mutateAsync({
				data: {
					token,
					platform: Platform.OS,
					tokenType: "expo",
				},
			});
		}
	};

	useEffect(() => {
		if (notificationToken.data) {
			const subscription = Notifications.addPushTokenListener((token) =>
				updateNotificationToken(token.data)
			);
			return () => subscription.remove();
		}
	}, []);

	useEffect(() => {
		// Handle user devices
		if (!auth.isAdmin) return;
		const token = notificationToken.data;
		if (
			!token ||
			!listUserDevicesQuery.isFetchedAfterMount ||
			!listUserDevicesQuery.data?.data.results
		)
			return;

		updateNotificationToken(token);
	}, [auth.isAdmin, listUserDevicesQuery.isFetchedAfterMount, notificationToken.data]);

	return null;
}

export function Notification() {
	const auth = useAuth();
	if (auth.isAuthenticated) {
		return <AuthenticatedNotification />;
	}

	return null;
}
