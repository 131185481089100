/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	ZoneItemListResponse,
	ListZoneItemsParams,
	ZoneItemGetResponse,
	GetZoneItemParams,
	ZoneItemCreateResponse,
	ZoneItemCreateRequest,
	ZoneItemUpdateResponse,
	ZoneItemUpdateRequest,
	ZoneItemDeleteResponse,
	DeleteZoneItemParams,
} from "./index.schemas";

export const useListZoneItemsHook = () => {
	const listZoneItems = useCustomInstance<ZoneItemListResponse>();

	return (params?: ListZoneItemsParams, signal?: AbortSignal) => {
		return listZoneItems({ url: `/ZoneItem/List`, method: "get", params, signal });
	};
};

export const getListZoneItemsQueryKey = (params?: ListZoneItemsParams) => [
	`/ZoneItem/List`,
	...(params ? [params] : []),
];

export type ListZoneItemsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListZoneItemsHook>>>
>;
export type ListZoneItemsQueryError = unknown;

export const useListZoneItems = <
	TData = Awaited<ReturnType<ReturnType<typeof useListZoneItemsHook>>>,
	TError = unknown
>(
	params?: ListZoneItemsParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useListZoneItemsHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListZoneItemsQueryKey(params);

	const listZoneItems = useListZoneItemsHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListZoneItemsHook>>>> = ({
		signal,
	}) => listZoneItems(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useListZoneItemsHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useGetZoneItemHook = () => {
	const getZoneItem = useCustomInstance<ZoneItemGetResponse>();

	return (params?: GetZoneItemParams, signal?: AbortSignal) => {
		return getZoneItem({ url: `/ZoneItem/Get`, method: "get", params, signal });
	};
};

export const getGetZoneItemQueryKey = (params?: GetZoneItemParams) => [
	`/ZoneItem/Get`,
	...(params ? [params] : []),
];

export type GetZoneItemQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetZoneItemHook>>>
>;
export type GetZoneItemQueryError = unknown;

export const useGetZoneItem = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetZoneItemHook>>>,
	TError = unknown
>(
	params?: GetZoneItemParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useGetZoneItemHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetZoneItemQueryKey(params);

	const getZoneItem = useGetZoneItemHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetZoneItemHook>>>> = ({
		signal,
	}) => getZoneItem(params, signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetZoneItemHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useCreateZoneItemHook = () => {
	const createZoneItem = useCustomInstance<ZoneItemCreateResponse>();

	return (zoneItemCreateRequest: ZoneItemCreateRequest) => {
		return createZoneItem({
			url: `/ZoneItem/Create`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: zoneItemCreateRequest,
		});
	};
};

export type CreateZoneItemMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCreateZoneItemHook>>>
>;
export type CreateZoneItemMutationBody = ZoneItemCreateRequest;
export type CreateZoneItemMutationError = unknown;

export const useCreateZoneItem = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCreateZoneItemHook>>>,
		TError,
		{ data: ZoneItemCreateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const createZoneItem = useCreateZoneItemHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCreateZoneItemHook>>>,
		{ data: ZoneItemCreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return createZoneItem(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof createZoneItem>>,
		TError,
		{ data: ZoneItemCreateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useUpdateZoneItemHook = () => {
	const updateZoneItem = useCustomInstance<ZoneItemUpdateResponse>();

	return (zoneItemUpdateRequest: ZoneItemUpdateRequest) => {
		return updateZoneItem({
			url: `/ZoneItem/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: zoneItemUpdateRequest,
		});
	};
};

export type UpdateZoneItemMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateZoneItemHook>>>
>;
export type UpdateZoneItemMutationBody = ZoneItemUpdateRequest;
export type UpdateZoneItemMutationError = unknown;

export const useUpdateZoneItem = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateZoneItemHook>>>,
		TError,
		{ data: ZoneItemUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateZoneItem = useUpdateZoneItemHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateZoneItemHook>>>,
		{ data: ZoneItemUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateZoneItem(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateZoneItem>>,
		TError,
		{ data: ZoneItemUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteZoneItemHook = () => {
	const deleteZoneItem = useCustomInstance<ZoneItemDeleteResponse>();

	return (params?: DeleteZoneItemParams) => {
		return deleteZoneItem({ url: `/ZoneItem/Delete`, method: "delete", params });
	};
};

export type DeleteZoneItemMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteZoneItemHook>>>
>;

export type DeleteZoneItemMutationError = unknown;

export const useDeleteZoneItem = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteZoneItemHook>>>,
		TError,
		{ params?: DeleteZoneItemParams },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteZoneItem = useDeleteZoneItemHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteZoneItemHook>>>,
		{ params?: DeleteZoneItemParams }
	> = (props) => {
		const { params } = props ?? {};

		return deleteZoneItem(params);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteZoneItem>>,
		TError,
		{ params?: DeleteZoneItemParams },
		TContext
	>(mutationFn, mutationOptions);
};
