import { TokenResponseConfig } from "expo-auth-session/src/TokenRequest.types";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { Platform } from "react-native";

import { mmkv } from "./mmkv";

const createMMKVStorage = <T>() => {
	const s = createJSONStorage<T>(() => ({
		getItem: (key: string) => mmkv.getString(key) ?? null,
		setItem: (key: string, newValue: string | undefined) => {
			if (newValue === undefined) {
				mmkv.delete(key);
			} else {
				mmkv.set(key, newValue);
			}
		},
		removeItem: (key: string) => mmkv.delete(key),
	}));
	s.subscribe = (key, callback) => {
		if (Platform.OS === "web") {
			const webListener = (e: StorageEvent) => {
				if (key === e.key) {
					const v = mmkv.getString(key) ?? null;
					callback(v === null ? v : JSON.parse(v));
				}
			};
			window.addEventListener("storage", webListener);
			return () => {
				window.removeEventListener("storage", webListener);
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		return () => {};
	};
	return s;
};

export function atomWithMMKV<T>(key: string, initialValue?: T) {
	return atomWithStorage<T>(`@staverton-app/${key}`, initialValue!, createMMKVStorage<T>());
}

/** @deprecated Use `authAtom` instead. */
export const loginAtom = atomWithMMKV<string | undefined>("login");
export const authAtom = atomWithMMKV<TokenResponseConfig | undefined>("auth");

export const themeAtom = atomWithMMKV<"light" | "dark" | "system">("theme", "system");
