import * as React from "react";
import { useNavigate } from "react-router";
import { Paragraph, YStack } from "tamagui";

import { useCreateAdminStocktake } from "../../../common/api/staverton.openapi/admin-stocktake";
import { useAdminCreateZones } from "../../../common/api/staverton.openapi/admin-zone";
import { Button } from "../../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";
import { AdminZoneCodesForm, CreateZoneForm } from "../zone/AdminZoneCreate";

export function AdminStocktakeStart() {
	const navigate = useNavigate();

	const createAdminStocktakeQuery = useCreateAdminStocktake();
	const createZonesQuery = useAdminCreateZones();

	const [createStocktakeForm, setCreateStocktakeForm] = React.useState<CreateZoneForm>({
		fromValue: "",
		toValue: "",
	});
	const [createStocktakeFormError, setCreateStocktakeFormError] = React.useState<string>();

	let createZonesCount = 0;
	if (
		createStocktakeForm.fromValue &&
		createStocktakeForm.toValue &&
		parseInt(createStocktakeForm.fromValue) <= parseInt(createStocktakeForm.toValue)
	) {
		createZonesCount =
			parseInt(createStocktakeForm.toValue) - parseInt(createStocktakeForm.fromValue) + 1;
	}

	function handleFormUpdate() {
		setCreateStocktakeFormError(undefined);
	}

	function isValidNumber(value: string) {
		return !isNaN(parseInt(value)) && parseInt(value) > 0;
	}

	function validateForm(formData: CreateZoneForm) {
		if (!isValidNumber(formData.fromValue)) {
			setCreateStocktakeFormError("Zone Number From is an invalid number");
			return false;
		}
		if (!isValidNumber(formData.toValue)) {
			setCreateStocktakeFormError("Zone Number To is an invalid number");
			return false;
		}
		if (parseInt(formData.fromValue) > parseInt(formData.toValue)) {
			setCreateStocktakeFormError("Zone Number To must be bigger than Zone Number From");
			return false;
		}
		if (createZonesCount > 999) {
			setCreateStocktakeFormError("Too many zones to create at one time");
			return false;
		}

		setCreateStocktakeFormError(undefined);
		return true;
	}

	async function handleStartStocktake() {
		if (!validateForm(createStocktakeForm)) return;

		let stocktakeId = createAdminStocktakeQuery.data?.data?.stocktakeId;
		if (!stocktakeId) {
			// Only create one on the first call
			const createResponse = await createAdminStocktakeQuery.mutateAsync();
			if (createResponse.data.errorMessage) return;
			stocktakeId = createResponse.data.stocktakeId;
		}

		// Create new zones on the new stocktake
		const response = await createZonesQuery.mutateAsync({
			data: {
				fromZoneCode: Number(createStocktakeForm.fromValue || 0),
				toZoneCode: Number(createStocktakeForm.toValue || 0),
			},
		});

		if (response.data.errorMessage) {
			const relatedCodes = response.data.existingZoneCodes || [];
			let codesString = "";

			if (relatedCodes.length > 1) {
				codesString = relatedCodes
					.slice(0, 3)
					.map((z) => z.code)
					.join(", ");
				if (relatedCodes.length > 3) codesString += "...";
				if (codesString.length) codesString = ` (${codesString})`;
			}

			setCreateStocktakeFormError(response.data.errorMessage + codesString);
		} else if (response.data.result) {
			setCreateStocktakeFormError(undefined);
			navigate("/");
		}
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title="Start a new Stocktake" />

					<Paragraph size="$6" mb="$1" color="$color">
						New Stocktake Zone Numbers
					</Paragraph>

					<AdminZoneCodesForm
						fromValue={createStocktakeForm.fromValue}
						toValue={createStocktakeForm.toValue}
						updateFromValue={(val) => {
							setCreateStocktakeForm({ ...createStocktakeForm, fromValue: val });
							handleFormUpdate();
						}}
						updateToValue={(val) => {
							setCreateStocktakeForm({ ...createStocktakeForm, toValue: val });
							handleFormUpdate();
						}}
					/>

					<YStack mt="$3" space="$3">
						<Button disabled={createAdminStocktakeQuery.isLoading} onPress={handleStartStocktake}>
							Start stocktake
						</Button>

						{createZonesCount > 0 && (
							<Paragraph m={0} color="$color">
								Creating {createZonesCount} {createZonesCount === 1 ? "Zone" : "Zones"}
							</Paragraph>
						)}

						{(createStocktakeFormError != undefined ||
							(createAdminStocktakeQuery.isSuccess &&
								createAdminStocktakeQuery.data?.data.errorMessage)) && (
							<Paragraph m={0} color="$red9">
								{createStocktakeFormError || createAdminStocktakeQuery.data?.data.errorMessage}
							</Paragraph>
						)}
					</YStack>
				</>
			</PageScrollNarrow>
		</PageContainer>
	);
}
