import React from "react";
import { YStack } from "tamagui";

import { useAuthLogin } from "../../common/auth/useAuth";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";

export function LoginScreen() {
	const login = useAuthLogin();

	return (
		<PageContainer>
			<PageScrollNarrow>
				<YStack als="center" ai="center">
					<PageHeader title="Staverton Stocktake" />

					<Button
						disabled={!login}
						onPress={() => {
							login?.();
						}}
					>
						Login with Microsoft 365
					</Button>
				</YStack>
			</PageScrollNarrow>
		</PageContainer>
	);
}
