import { useMemo, useState } from "react";
import { Paragraph, XStack, Spinner, Separator, YStack } from "tamagui";

import { useGetAvailabilityLists } from "../../../common/api/staverton.openapi/availability-lists-admin";
import { PageContainer, PageHeader, PageScrollWide } from "../../../common/components/Page";
import AvailabilityCategory from "./AdminAvailabilityListItem";
import { StockItemListAlert } from "./StockItemListAlert";

export function AvailabilityLists() {
	const query = useGetAvailabilityLists();
	const [testStockListId, setTestStockListId] = useState<number | null>(null);

	const categories = useMemo(() => {
		const results = query.data?.data?.results;
		if (results) {
			return [
				...results,
				{
					availabilityListId: undefined,
					listName: "",
					superDepartment: 1,
					department: 1,
					active: false,
				},
			];
		} else {
			return [];
		}
	}, [query.data]);

	async function handleSaveCategory() {
		await query.refetch();
	}

	return (
		<PageContainer>
			<PageScrollWide>
				<>
					<PageHeader title="Availability Lists" />
					{query.isLoading && (
						<>
							<Separator mb="$3" />
							<XStack jc="center">
								<Spinner />
							</XStack>
						</>
					)}

					{query.isFetched && query.data?.data?.results && (
						<YStack space="$2">
							{categories.map((category) => (
								<AvailabilityCategory
									category={category}
									onCategoryUpdated={handleSaveCategory}
									key={category.availabilityListId}
									showTestAlertForCategory={(id) => setTestStockListId(id)}
								/>
							))}
						</YStack>
					)}

					{query.isFetched && query?.data?.data?.results?.length === 0 && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								No lists found
							</Paragraph>
						</>
					)}

					{(query.isError || (query.isFetched && query?.data?.data?.errorMessage != undefined)) && (
						<>
							<Separator mb="$3" />
							<Paragraph ta="center" color="$colorTranslucent">
								{query.isError ? query.error?.toString() : query.data?.data.errorMessage}
							</Paragraph>
						</>
					)}
				</>
			</PageScrollWide>
			<>
				{!!testStockListId && (
					<StockItemListAlert
						categoryId={testStockListId}
						onClose={() => setTestStockListId(null)}
					/>
				)}
			</>
		</PageContainer>
	);
}
