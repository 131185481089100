import "expo-dev-client";
import "expo-asset";
import "react-native-reanimated";

import { tokens } from "@tamagui/theme-base";
import { useFonts } from "expo-font";
import { useAtomValue } from "jotai";
import type { ReactNode } from "react";
// TODO: Types for react-devtools-core
import { connectToDevTools } from "react-devtools-core";
import { Platform, StatusBar, useColorScheme } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "sentry-expo";
import { TamaguiProvider, Theme } from "tamagui";

import { ColorModeContext, useColorMode } from "./common/colorMode/colorMode";
import { themeAtom } from "./common/storage/atoms";
import { APP_VARIANT } from "./constants/application";
import { Navigation } from "./navigation/navigation";
import { Router } from "./navigation/router";
import config from "./tamagui.config";

if (__DEV__) {
	connectToDevTools({
		host: "localhost",
		port: 8097,
	});
} else {
	Sentry.init({
		dsn: "https://0eff76eae57c4a9da579058fef7ea8da@o4504354991243264.ingest.sentry.io/4504354995109888",
		enableInExpoDevelopment: false,
		debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
		environment: APP_VARIANT,
	});
}

export const queryClient = new QueryClient();

function StyleProvider() {
	const colorMode = useColorMode();
	const color = tokens.color[colorMode === "dark" ? "blue5Dark" : "blue5Light"];
	return (
		<style>
			{`
a[href] {
  text-decoration: none;
}
* {
  scrollbar-color: ${color} transparent;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
    background-color: transparent;
    width: 16px;
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
    background-color: transparent;
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
    background-color: ${color};
    background-clip: padding-box;
    border-radius: 16px;
    border: 4px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
    display:none;
}`}
		</style>
	);
}

function ThemeProvider({ children }: { children: ReactNode }) {
	const selectedTheme = useAtomValue(themeAtom);
	const colorScheme = useColorScheme() ?? "light";
	const theme = selectedTheme === "system" ? colorScheme : selectedTheme;
	return (
		<SafeAreaProvider>
			<ColorModeContext.Provider value={colorScheme}>
				<TamaguiProvider config={config} defaultTheme="light">
					<StatusBar
						backgroundColor="#fff0"
						translucent
						barStyle={theme === "light" ? "dark-content" : "light-content"}
					/>
					<Theme name={theme}>
						<Theme name="blue">
							{Platform.OS === "web" && <StyleProvider />}
							{children}
						</Theme>
					</Theme>
				</TamaguiProvider>
			</ColorModeContext.Provider>
		</SafeAreaProvider>
	);
}

export default function App() {
	const [loaded] = useFonts({
		Inter: require("@tamagui/font-inter/otf/Inter-Medium.otf"),
	});

	if (!loaded) {
		return null;
	}

	return (
		<QueryClientProvider client={queryClient}>
			<ThemeProvider>
				<Router>
					<Navigation />
				</Router>
			</ThemeProvider>
		</QueryClientProvider>
	);
}
