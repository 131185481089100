/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
export type DeleteZoneItemParams = { zoneItemId?: number };

export type GetZoneItemParams = { zoneItemId?: number };

export type ListZoneItemsParams = { zoneCode?: string };

export type GetZoneParams = { zoneCode?: string };

export type DeleteUserNotificationTopicParams = { userNotificationTopicId?: number };

export type ListUserNotificationsParams = { pageFrom?: number; pageSize?: number };

export type DeleteUserDeviceParams = { userDeviceId?: number };

export type ListQueryImagesParams = { zoneItemQueryId?: number };

export type GetQueryParams = { zoneItemQueryId?: number };

export type ListQueriesParams = { filterZone?: string };

export type LookupAvailabilityParams = {
	superDepartmentId?: number;
	departmentId?: number;
	groupId?: number;
	subGroupId?: number;
};

export type LookupBarcodeParams = { checkStockCode?: boolean; barcode?: string };

export type DeleteAvailabilityListParams = { id?: number };

export type CategoryDetailsParams = { id?: number };

export type AdminDeleteZoneParams = { zoneCode?: string };

export type AdminListZonesParams = {
	filterStatus?: number;
	filterQuery?: number;
	pageFrom?: number;
	pageSize?: number;
	searchQuery?: string;
};

export type GetAdminStocktakeReviewParams = { stocktakeId?: number };

export type AdminListQueriesParams = {
	filterZone?: string;
	filterItem?: string;
	filterUser?: number;
	filterStatus?: string;
	pageFrom?: number;
	pageSize?: number;
};

export interface ZoneUpdateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface ZoneUpdateRequest {
	zoneCode: string;
	status?: number | null;
	startedAt?: string | null;
	updateStartedBy: boolean;
}

export interface ZoneOverviewSection {
	status: ZoneItemStatus;
	count: number;
}

export interface ZoneListResponse {
	errorMessage?: string | null;
	results?: ZoneItem[] | null;
	stocktake?: StocktakeItem;
}

export interface ZoneListItem {
	zoneStocktakeId: number;
	zoneItemId: number;
	zoneId: number;
	zoneCode: string;
	code: string;
	count: number;
	scannedAt: string;
	scannedBy: number;
	retailStockCode?: number | null;
	retailDescription?: string | null;
	retailStockBalance?: number | null;
	retailPrice?: string | null;
}

export interface ZoneItemUpdateResponse {
	result: boolean;
}

export interface ZoneItemUpdateRequest {
	zoneItemId: number;
	code: string;
	count: number;
	retailStockCode?: number | null;
	retailDescription?: string | null;
	retailStockBalance?: number | null;
	retailPrice?: string | null;
}

export type ZoneItemStatus = typeof ZoneItemStatus[keyof typeof ZoneItemStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ZoneItemStatus = {
	WaitingToStart: "WaitingToStart",
	Started: "Started",
	Complete: "Complete",
	Approved: "Approved",
} as const;

export interface ZoneItemListResponse {
	results?: ZoneListItem[] | null;
	errorMessage?: string | null;
}

export interface ZoneItemGetResponse {
	result?: ZoneListItem;
	errorMessage?: string | null;
}

export interface ZoneItemDeleteResponse {
	result: boolean;
}

export interface ZoneItemCreateResponse {
	zoneItemId: number;
	errorMessage?: string | null;
}

export interface ZoneItemCreateRequest {
	zoneId: number;
	code: string;
	count: number;
	scannedAt: string;
	retailStockCode?: number | null;
	retailDescription?: string | null;
	retailStockBalance?: number | null;
	retailPrice?: string | null;
}

export interface ZoneItem {
	stocktakeId: number;
	zoneId: number;
	code: string;
	status: ZoneItemStatus;
	queryCount: number;
	startedAt?: string | null;
	startedBy?: number | null;
	startedByName?: string | null;
}

export interface ZoneGetResponse {
	errorMessage?: string | null;
	result?: ZoneItem;
	startedByRequestor: boolean;
}

export interface ZoneCodeResult {
	code: string;
}

export interface UserNotificationUpdateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserNotificationUpdateRequest {
	userNotificationId: number;
	readAt: string;
}

export interface UserNotificationTopicUpdateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserNotificationTopicUpdateRequest {
	userNotificationTopicId: number;
	active: boolean;
}

export interface UserNotificationTopicItem {
	userNotificationTopicId: number;
	userId: number;
	topic: string;
	active: boolean;
}

export interface UserNotificationTopicListResponse {
	errorMessage?: string | null;
	results?: UserNotificationTopicItem[] | null;
}

export interface UserNotificationTopicDeleteResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserNotificationTopicCreateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserNotificationTopicCreateRequest {
	topic: string;
	active: boolean;
}

export interface UserNotificationItem {
	userNotificationId: number;
	userId: number;
	title: string;
	body?: string | null;
	notifiedAt: string;
	readAt?: string | null;
	topic?: string | null;
	zoneId?: number | null;
	zoneCode?: string | null;
	zoneItemId?: number | null;
	zoneItemQueryId?: number | null;
}

export interface UserNotificationListResponse {
	errorMessage?: string | null;
	results?: UserNotificationItem[] | null;
}

export interface UserDeviceUpdateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserDeviceUpdateRequest {
	userDeviceId: number;
	token: string;
}

export interface UserDeviceItem {
	userDeviceId: number;
	userId: number;
	token: string;
	platform: string;
	tokenType: string;
}

export interface UserDeviceListResponse {
	errorMessage?: string | null;
	results?: UserDeviceItem[] | null;
}

export interface UserDeviceDeleteResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserDeviceCreateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UserDeviceCreateRequest {
	token: string;
	platform: string;
	tokenType: string;
}

export interface UpdateAdminStocktakeResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface UpdateAdminStocktakeRequest {
	status: string;
}

export interface StocktakeReviewItem {
	stocktakeId: number;
	status: string;
	year: number;
	startedBy: number;
	startedAt: string;
	completedBy?: number | null;
	completedAt?: string | null;
	totalItems?: number | null;
	totalQueries?: number | null;
	sumItemCounts?: number | null;
}

export interface StocktakeItem {
	stocktakeId: number;
	status: string;
	year: number;
	startedBy: number;
	startedAt: string;
	completedBy?: number | null;
	completedAt?: string | null;
}

export interface StartAvailabilityListImageUploadRequest {
	availabilityListId: number;
	contentType: string;
	fileExtension: string;
	chunks: number;
}

export interface ResponseBase {
	errorMessage?: string | null;
}

export interface QueryUpdateResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface QueryUpdateResolvedResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface QueryUpdateResolvedRequest {
	zoneItemQueryId: number;
	resolvedDetails: string;
	zoneItemId?: number | null;
}

export interface QueryUpdateRequest {
	zoneItemQueryId: number;
	details: string;
}

export interface QueryUpdateClaimedResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface QueryUpdateClaimedRequest {
	zoneItemQueryId: number;
	userClaimed: boolean;
}

export interface QueryItem {
	zoneItemQueryId: number;
	zoneId: number;
	zoneCode: string;
	zoneItemId?: number | null;
	zoneItemCode?: string | null;
	zoneItemStocktakeId?: number | null;
	queriedAt: string;
	queriedBy: number;
	queriedByName: string;
	details: string;
	resolvedAt?: string | null;
	resolvedBy?: number | null;
	resolvedDetails?: string | null;
	claimedBy?: number | null;
	claimedByName?: string | null;
}

export interface QueryListResponse {
	errorMessage?: string | null;
	results?: QueryItem[] | null;
}

export interface QueryImageStartUploadResponse {
	urls: string[];
	details: MultipartUploadDetails;
}

export interface QueryImageStartUploadRequest {
	chunks: number;
	zoneItemId?: number | null;
	zoneCode?: string | null;
	contentType: string;
}

export interface QueryImageItem {
	zoneItemQueryImageId: number;
	zoneItemQueryId: number;
	s3Location: string;
}

export interface QueryImageListResponse {
	results?: QueryImageItem[] | null;
}

export interface QueryImageDeleteResponse {
	result: boolean;
}

export interface QueryImageDeleteRequest {
	zoneItemQueryImageId: number;
}

export interface QueryImageCompleteUploadResponse {
	result: boolean;
}

export interface QueryImageCompleteUploadRequest {
	zoneItemQueryId: number;
	eTags: string[];
	details: MultipartUploadDetails;
}

export interface QueryGetResponse {
	errorMessage?: string | null;
	result?: QueryItem;
	userId?: number | null;
	zoneItem?: ZoneListItem;
}

export interface QueryFilterZoneOption {
	zoneCode: string;
	zoneItemCode: string;
}

export interface QueryFilterUserOption {
	userId: number;
	userName: string;
}

export interface QueryCreateResponse {
	errorMessage?: string | null;
	zoneItemQueryId: number;
}

export interface QueryCreateRequest {
	zoneItemId?: number | null;
	zoneCode?: string | null;
	details: string;
}

export interface MultipartUploadDetails {
	bucket: string;
	key: string;
	uploadId: string;
}

export interface StartAvailabilityListImageUploadResponse {
	urls: string[];
	details: MultipartUploadDetails;
}

export interface MultiRetailBarcodeLookupResponse {
	response: string;
}

export interface MultiRetailAvailabilityLookupResponse {
	errorMessage?: string | null;
	response?: AvailabilityRecords;
}

export interface GetConfigResponse {
	openRetailUsername: string;
	openRetailPassword: string;
	openRetailStockLookupUrl: string;
}

export interface GetAuthConfigResponse {
	azureScopes: string[];
	azureDiscoveryUrl: string;
	azureClientId: string;
}

export interface GetAdminStocktakeReviewResponse {
	errorMessage?: string | null;
	stocktake?: StocktakeReviewItem;
	s3Location?: string | null;
}

export interface GetAdminStocktakeCompletableResponse {
	errorMessage?: string | null;
	result: boolean;
	stocktake?: StocktakeItem;
}

export interface DeleteAvailabilityListImageRequest {
	availabilityListId: number;
}

export interface CreateAdminStocktakeResponse {
	errorMessage?: string | null;
	result: boolean;
	stocktakeId?: number | null;
}

export interface CompletedStocktakeListItem {
	stocktakeId: number;
	completedBy?: number | null;
	completedByName?: string | null;
	completedAt?: string | null;
	year: number;
	readonly stocktakeLabel: string;
}

export interface CompleteAvailabilityListImageUploadRequest {
	details: MultipartUploadDetails;
	eTags: string[];
	availabilityListId: number;
}

export interface CategorySummary {
	availabilityListId?: number | null;
	listName: string;
	description?: string | null;
	imageUrl?: string | null;
	active: boolean;
}

export interface Category {
	availabilityListId?: number | null;
	listName: string;
	description?: string | null;
	imageUrl?: string | null;
	active: boolean;
	superDepartment?: number | null;
	department?: number | null;
	mainGroup?: number | null;
	subGroup?: number | null;
	minQuantity?: number | null;
}

export interface CategoryEnumerableResponse {
	errorMessage?: string | null;
	results?: Category[] | null;
}

export interface AvailabilityStockItem {
	stockCode: number;
	description: string;
	sellingPrice?: number | null;
	quantityAvailable: number;
}

export interface CategoryResponse {
	listName: string;
	stockItems: AvailabilityStockItem[];
}

export interface AvailabilityRecord {
	branch: number;
	stock_Code: number;
	retail_Stock_Balance: number;
	warehouse_Stock_Balance: number;
	min_Stock_Balance: number;
	max_Stock_Balance: number;
	on_Order_Purchases: number;
	bandA_Sell?: string | null;
	last_Sell_Date?: string | null;
	supplier_Ref?: string | null;
	outer_Unit: number;
	expected_GI_Date?: string | null;
	super_Department_ID: number;
	department_ID: number;
	group_ID: number;
	sub_Group_ID: number;
	last_PO_Date?: string | null;
	last_PO_No?: string | null;
	account_No?: string | null;
	full_Desc: string;
}

export interface AvailabilityRecords {
	records?: AvailabilityRecord[] | null;
}

export interface AllCategoriesResponse {
	categories: CategorySummary[];
}

export interface AdminZoneListResponse {
	errorMessage?: string | null;
	results?: ZoneItem[] | null;
	resultsTotal: number;
	stocktakeId?: number | null;
	completedStocktakes?: CompletedStocktakeListItem[] | null;
	overview?: ZoneOverviewSection[] | null;
}

export interface AdminZoneDeleteResponse {
	errorMessage?: string | null;
	result: boolean;
}

export interface AdminZoneCreateResponse {
	errorMessage?: string | null;
	result: boolean;
	existingZoneCodes?: ZoneCodeResult[] | null;
}

export interface AdminZoneCreateRequest {
	fromZoneCode: number;
	toZoneCode: number;
}

export interface AdminQueryListResponse {
	errorMessage?: string | null;
	results?: QueryItem[] | null;
	resultsTotal: number;
	filterZoneItemOptions?: QueryFilterZoneOption[] | null;
	filterUserOptions?: QueryFilterUserOption[] | null;
	stocktakeId?: number | null;
}
