import { Check, Plus, ChevronRight } from "@tamagui/lucide-icons";
import * as React from "react";
import { useNavigate } from "react-router";
import { H4, Paragraph, YStack, XStack, Spinner, Separator, SizableText } from "tamagui";

import { AlertBasic } from "../../../assets/icons/AlertBasic";
import { ZoneItem, ZoneItemStatus } from "../../common/api/staverton.openapi/index.schemas";
import { useListZones } from "../../common/api/staverton.openapi/zone";
import { useHasCamera } from "../../common/camera/camera";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";

function ZoneStatusItem({ zoneItem }: { zoneItem: ZoneItem }) {
	let statusIcon = <Plus size={14} color="white" />;
	let statusBgColor = "$gray9";

	if (zoneItem.status === ZoneItemStatus.Complete && zoneItem.queryCount === 0) {
		statusIcon = <Check size={14} color="white" />;
		statusBgColor = "$green9";
	} else if (zoneItem.status === ZoneItemStatus.Complete && zoneItem.queryCount > 0) {
		statusIcon = <AlertBasic size={14} color="white" />;
		statusBgColor = "$red9";
	} else if (zoneItem.status === ZoneItemStatus.Started && zoneItem.queryCount > 0) {
		statusIcon = <Plus size={14} color="white" />;
		statusBgColor = "$orange9";
	} else if (zoneItem.status === ZoneItemStatus.Approved) {
		statusIcon = <Check size={14} color="white" />;
		statusBgColor = "$blue9";
	}

	const zoneQueryString = zoneItem.queryCount
		? `(${zoneItem.queryCount} ${zoneItem.queryCount === 1 ? "query" : "queries"})`
		: "";

	const navigate = useNavigate();

	function handleEditZone() {
		navigate(`/zone/scan/${zoneItem.code}`);
	}

	return (
		<>
			<Separator />
			<XStack
				py="$2"
				px="$3"
				ai="center"
				cursor="pointer"
				hoverStyle={{ backgroundColor: "$backgroundSoft" }}
				onPress={handleEditZone}
			>
				<YStack
					mr="$2"
					width="$1"
					height="$1"
					alignItems="center"
					justifyContent="center"
					borderRadius={12}
					bc={statusBgColor}
				>
					{statusIcon}
				</YStack>

				<SizableText flexGrow={1} color="$color" m={0}>
					{"Zone " + zoneItem.code + ": " + zoneItem.status + " " + zoneQueryString}
				</SizableText>

				<ChevronRight size={18} color="gray" />
			</XStack>
		</>
	);
}

export function StocktakeScreen() {
	const zonesQuery = useListZones();

	const navigate = useNavigate();
	const hasCamera = useHasCamera();

	function navigateToLookup() {
		navigate("/lookup");
	}

	function navigateToZoneNew() {
		navigate("/zone/new");
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader title="Stocktake" />

					{zonesQuery.isLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!zonesQuery.isLoading && zonesQuery.data?.data.errorMessage != null && (
						<Paragraph color="$colorTranslucent" mb="$3">
							{zonesQuery.data.data.errorMessage}
						</Paragraph>
					)}

					{!zonesQuery.isLoading &&
						zonesQuery.data?.data?.results &&
						zonesQuery.data?.data?.results?.length === 0 && (
							<Paragraph mb="$3">Tap "Start new zone" to start stocktaking.</Paragraph>
						)}

					{!zonesQuery.isLoading &&
						zonesQuery.data?.data?.results &&
						zonesQuery.data?.data?.results.map((zoneItem) => (
							<ZoneStatusItem zoneItem={zoneItem} key={zoneItem.code} />
						))}
				</>
			</PageScrollNarrow>

			<YStack space="$3" maxWidth={800} w="100%" p="$3" $gtSm={{ p: "$6" }} als="center">
				{!zonesQuery.isLoading && zonesQuery.data?.data.stocktake?.stocktakeId != undefined && (
					<Button onPress={navigateToZoneNew}>Start new zone</Button>
				)}

				<H4>Product lookup</H4>
				<Button onPress={navigateToLookup}>
					{hasCamera ? "Scan code" : "Enter code manually"}
				</Button>
			</YStack>
		</PageContainer>
	);
}
