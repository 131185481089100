import { ArrowRight, UserCheck, UserX } from "@tamagui/lucide-icons";
import * as React from "react";
import { Alert, Platform } from "react-native";
import { useNavigate, useParams } from "react-router";
import { Paragraph, XStack, H4, Spinner, YStack, H6 } from "tamagui";

import { queryClient } from "../../App";
import { useAdminUpdateQueryClaimed } from "../../common/api/staverton.openapi/admin-query";
import { MultipartUploadDetails } from "../../common/api/staverton.openapi/index.schemas";
import { useGetQuery, useUpdateQuery } from "../../common/api/staverton.openapi/query";
import {
	useCompleteQueryImageUpload,
	useDeleteQueryImage,
	useListQueryImages,
	useStartQueryImageUpload,
} from "../../common/api/staverton.openapi/query-image";
import { useAuth } from "../../common/auth/useAuth";
import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";
import { LinkButton } from "../../navigation/link";
import { QueryItem, QueryEditForm, startUploadingQueryImage } from "./QueryEditForm";
import { QueryItemView } from "./QueryItemView";

export function QueryEdit() {
	const params = useParams();
	const navigate = useNavigate();
	const auth = useAuth();

	const getQueryQuery = useGetQuery({
		zoneItemQueryId: Number(params.zoneItemQueryId),
	});
	const listQueryImagesQuery = useListQueryImages({
		zoneItemQueryId: Number(params.zoneItemQueryId),
	});
	const updateQueryQuery = useUpdateQuery();
	const updateQueryClaimedQuery = useAdminUpdateQueryClaimed();
	const deleteQueryImageQuery = useDeleteQueryImage();
	const startQueryImageUpload = useStartQueryImageUpload();
	const completeQueryImageUpload = useCompleteQueryImageUpload();

	const [isUploadingToS3, setIsUploadingToS3] = React.useState<boolean>(false);
	const [formError, setFormError] = React.useState<string>();
	const [editQueryItem, setEditQueryItem] = React.useState<QueryItem>({
		zoneItemQueryId: Number(params.zoneItemQueryId),
		zoneItemId: null,
		zoneCode: params.zoneCode ?? "",
		details: "",
		images: [],
	});

	const queryResult = getQueryQuery.isFetchedAfterMount
		? getQueryQuery.data?.data.result
		: undefined;
	const queryUserId = getQueryQuery.data?.data.userId ?? undefined;
	const queryImages = listQueryImagesQuery.data?.data.results;
	const isPageLoading =
		getQueryQuery.isLoading ||
		listQueryImagesQuery.isLoading ||
		!getQueryQuery.isFetchedAfterMount ||
		!listQueryImagesQuery.isFetchedAfterMount;
	const isQueryFound = !!queryResult;
	const isQueryEditable =
		!queryResult?.resolvedAt && (auth.isAdmin || queryResult?.queriedBy == queryUserId);

	React.useEffect(() => {
		if (!queryResult || !queryImages) return;

		setEditQueryItem({
			zoneItemQueryId: queryResult.zoneItemQueryId,
			zoneItemId: queryResult.zoneItemId ?? null,
			zoneCode: queryResult.zoneCode ?? "",
			details: queryResult.details ?? "",
			images: queryImages.map((image) => {
				return {
					s3Location: image.s3Location,
					zoneItemQueryImageId: image.zoneItemQueryImageId,
				};
			}),
		});
	}, [getQueryQuery.isFetchedAfterMount, listQueryImagesQuery.isFetchedAfterMount]);

	function navigateBack() {
		navigate(-1);
	}

	function isValidTextLength(value: string) {
		return value.length > 3;
	}

	function validateForm(formData: QueryItem) {
		if (!isValidTextLength(formData.details)) {
			setFormError("Please enter query details");
			return false;
		}

		setFormError(undefined);
		return true;
	}

	async function handleUpdateQuery() {
		if (!queryResult || !editQueryItem.zoneItemQueryId) return;

		// Validate form has minimum of some resolution details text
		if (!validateForm({ ...editQueryItem })) return;

		const response = await updateQueryQuery.mutateAsync({
			data: {
				zoneItemQueryId: queryResult.zoneItemQueryId,
				details: editQueryItem.details,
			},
		});

		// Upload the images to s3, any error should exit early
		setIsUploadingToS3(true);
		const uploadResults: {
			eTags: string[];
			details: MultipartUploadDetails;
			fileName: string;
		}[] = [];

		for (const image of editQueryItem.images.filter(
			(image) => !!image.base64 && !image.zoneItemQueryImageId
		)) {
			const startUploadResult = await startUploadingQueryImage(
				image,
				editQueryItem.zoneCode,
				editQueryItem.zoneItemId,
				startQueryImageUpload
			);

			if (startUploadResult.success) {
				uploadResults.push({
					eTags: startUploadResult.eTags,
					details: startUploadResult.details,
					fileName: image.fileName ?? "",
				});
			} else {
				setIsUploadingToS3(false);
				setFormError(startUploadResult.errorMessage);
				return;
			}
		}

		// Complete all image uploads and add the DB query Images
		for (const uploadResult of uploadResults) {
			const imageResp = await completeQueryImageUpload
				.mutateAsync({
					data: {
						...uploadResult,
						zoneItemQueryId: editQueryItem.zoneItemQueryId,
					},
				})
				.catch((e) => {
					console.error(`finishing image catch`, e);
				});

			if (!imageResp) {
				setIsUploadingToS3(false);
				setFormError(`Failed to upload ${uploadResult.fileName}`);
				return;
			}
		}
		setIsUploadingToS3(false);

		queryClient.invalidateQueries();

		if (response.data.result) navigateBack();
		else setFormError("An error occurred");
	}

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title={isQueryEditable ? "Edit Query" : "View Query"}>
						<XStack>
							{!!queryResult?.zoneCode && (
								<LinkButton
									to={
										auth.isAdmin
											? `/admin/zone/${queryResult.zoneCode}`
											: `/zone/scan/${queryResult.zoneCode}`
									}
								>
									View Zone {queryResult.zoneCode}
								</LinkButton>
							)}
						</XStack>
					</PageHeader>

					{isPageLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!isPageLoading && !isQueryFound && <H4>Query not found</H4>}

					{!isPageLoading && isQueryFound && !!queryResult && isQueryEditable && (
						<>
							<H6 mb="$3">
								{queryResult.zoneItemId
									? `Item ${queryResult.zoneItemCode}`
									: `Zone ${queryResult.zoneCode}`}
							</H6>

							<QueryEditForm
								query={editQueryItem}
								setQuery={setEditQueryItem}
								deleteQueryImage={(zoneQueryImageId) => {
									deleteQueryImageQuery.mutate({
										data: {
											zoneItemQueryImageId: zoneQueryImageId,
										},
									});
								}}
							/>

							{!!queryResult.claimedByName && (
								<YStack mb="$3">
									<Paragraph mb="$1">Query claimed by</Paragraph>
									<Paragraph size="$5" m={0}>
										{queryResult.claimedByName}
									</Paragraph>
								</YStack>
							)}

							{auth.isAdmin && (
								<>
									{!queryResult.resolvedAt && !queryResult.claimedBy && (
										<YStack mb="$3">
											<Paragraph mb="$1">Query is unclaimed</Paragraph>
											<Button
												f={1}
												icon={UserCheck}
												onPress={async () => {
													const result = await updateQueryClaimedQuery.mutateAsync({
														data: {
															userClaimed: true,
															zoneItemQueryId: queryResult.zoneItemQueryId,
														},
													});
													if (result.data.errorMessage) {
														if (Platform.OS === "web") {
															alert(result.data.errorMessage);
														} else {
															Alert.alert(result.data.errorMessage);
														}
													}
													getQueryQuery.refetch();
												}}
											>
												Claim query
											</Button>
										</YStack>
									)}

									{!queryResult.resolvedAt && queryResult.claimedBy == queryUserId && (
										<XStack space="$3" mb="$3">
											<Button
												f={1}
												icon={UserX}
												onPress={async () => {
													const result = await updateQueryClaimedQuery.mutateAsync({
														data: {
															userClaimed: false,
															zoneItemQueryId: queryResult.zoneItemQueryId,
														},
													});
													if (result.data.errorMessage) {
														if (Platform.OS === "web") {
															alert(result.data.errorMessage);
														} else {
															Alert.alert(result.data.errorMessage);
														}
													}
													getQueryQuery.refetch();
												}}
											>
												Unclaim
											</Button>
											<LinkButton
												iconAfter={ArrowRight}
												f={1}
												to={`/admin/query/resolve/${queryResult.zoneItemQueryId}`}
											>
												Resolve
											</LinkButton>
										</XStack>
									)}
								</>
							)}
						</>
					)}

					{!isPageLoading && isQueryFound && !!queryResult && !isQueryEditable && (
						<QueryItemView queryItem={queryResult} hideViewButton showQueryTitle />
					)}
				</>
			</PageScrollNarrow>

			<YStack maxWidth={800} w="100%" space="$3" p="$3" $gtSm={{ p: "$6" }} als="center">
				{formError && (
					<Paragraph color="$red9" w="100%">
						{formError}
					</Paragraph>
				)}
				<XStack space="$3">
					{!isPageLoading && isQueryFound && isQueryEditable && (
						<Button
							flex={1}
							disabled={
								updateQueryQuery.isLoading ||
								startQueryImageUpload.isLoading ||
								completeQueryImageUpload.isLoading ||
								isUploadingToS3
							}
							onPress={handleUpdateQuery}
						>
							Save
						</Button>
					)}
				</XStack>
			</YStack>

			<>
				{(updateQueryQuery.isLoading || completeQueryImageUpload.isLoading || isUploadingToS3) && (
					<YStack
						position="absolute"
						top={0}
						bottom={0}
						left={0}
						right={0}
						key="uploading"
						ai="center"
						jc="center"
					>
						<YStack pos="absolute" t={0} b={0} l={0} r={0} bc="black" o={0.5} />
						<YStack bc="$backgroundSoft" ai="center" jc="center" br="$3" p="$3">
							<H6>Uploading</H6>
							<Spinner />
						</YStack>
					</YStack>
				)}
			</>
		</PageContainer>
	);
}
