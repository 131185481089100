/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	UserNotificationListResponse,
	ListUserNotificationsParams,
	UserNotificationUpdateResponse,
	UserNotificationUpdateRequest,
} from "./index.schemas";

export const useListUserNotificationsHook = () => {
	const listUserNotifications = useCustomInstance<UserNotificationListResponse>();

	return (params?: ListUserNotificationsParams, signal?: AbortSignal) => {
		return listUserNotifications({ url: `/UserNotification/List`, method: "get", params, signal });
	};
};

export const getListUserNotificationsQueryKey = (params?: ListUserNotificationsParams) => [
	`/UserNotification/List`,
	...(params ? [params] : []),
];

export type ListUserNotificationsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListUserNotificationsHook>>>
>;
export type ListUserNotificationsQueryError = unknown;

export const useListUserNotifications = <
	TData = Awaited<ReturnType<ReturnType<typeof useListUserNotificationsHook>>>,
	TError = unknown
>(
	params?: ListUserNotificationsParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useListUserNotificationsHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListUserNotificationsQueryKey(params);

	const listUserNotifications = useListUserNotificationsHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useListUserNotificationsHook>>>
	> = ({ signal }) => listUserNotifications(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useListUserNotificationsHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useUpdateUserNotificationHook = () => {
	const updateUserNotification = useCustomInstance<UserNotificationUpdateResponse>();

	return (userNotificationUpdateRequest: UserNotificationUpdateRequest) => {
		return updateUserNotification({
			url: `/UserNotification/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: userNotificationUpdateRequest,
		});
	};
};

export type UpdateUserNotificationMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationHook>>>
>;
export type UpdateUserNotificationMutationBody = UserNotificationUpdateRequest;
export type UpdateUserNotificationMutationError = unknown;

export const useUpdateUserNotification = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationHook>>>,
		TError,
		{ data: UserNotificationUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateUserNotification = useUpdateUserNotificationHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserNotificationHook>>>,
		{ data: UserNotificationUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateUserNotification(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateUserNotification>>,
		TError,
		{ data: UserNotificationUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
