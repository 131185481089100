import { useQuery } from "react-query";

import { useLookupBarcodeHook } from "./staverton.openapi/open-retail";

export interface StockLookupResponse {
	records: StockLookupResponseRecord[];
}

export interface StockLookupResponseRecord {
	Stock_Code: number;
	Full_Desc: string;
	Barcode: string;
	Type: number;
	Outer_Unit: number;
	Retail_Stock_Balance: number;
	Warehouse_Stock_Balance: number;
	Last_Sell_Date: string;
	Supplier_Ref: string;
	Stocktake_Freeze_Retail: number;
	BandA_Sell: string;
	RRP: string;
	Preferred_Supplier: string;
	Date_Time_Stamp?: string;
	Deleted?: 1 | 0;
	Suspended?: 1 | 0;
	Discarded?: 1 | 0;
}

export function isIgnored(result: StockLookupResponseRecord) {
	return !!result.Deleted || !!result.Suspended || !!result.Discarded;
}

export function useStockLookup(
	{ code, checkStockCode }: { code: string; checkStockCode?: boolean },
	{ enabled } = { enabled: true }
) {
	const lookupBarcode = useLookupBarcodeHook();
	return useQuery(
		["stockLookup", code, checkStockCode],
		async () => {
			const result = await lookupBarcode({
				barcode: code,
				checkStockCode: checkStockCode ?? false,
			});
			const response = JSON.parse(result.data.response) as StockLookupResponse;
			const orderedRecords = response.records.slice().sort((a, b) => {
				// Sort by whether the item is deleted, suspended or discarded
				const aIsIgnored = isIgnored(a);
				const bIsIgnored = isIgnored(b);
				if (aIsIgnored && !bIsIgnored) return 1;
				if (!aIsIgnored && bIsIgnored) return -1;

				// Sort by date descending
				const timeDiff =
					new Date(b.Date_Time_Stamp ?? 0).getTime() - new Date(a.Date_Time_Stamp ?? 0).getTime();
				if (timeDiff !== 0) return timeDiff;

				return 0;
			});
			return {
				records: orderedRecords,
			};
		},
		{
			enabled,
		}
	);
}
