import * as React from "react";
import { Platform } from "react-native";
import { useNavigate, useParams } from "react-router";
import { H6, YStack, H4, XStack } from "tamagui";

import { useOCR } from "../../../plugins/frameProcessors/js/ocr";
import { ZoneItemStatus } from "../../common/api/staverton.openapi/index.schemas";
import { useGetZone, useUpdateZone } from "../../common/api/staverton.openapi/zone";
import { useHasCamera } from "../../common/camera/camera";
import { Button } from "../../common/components";
import { Alert, AlertButton } from "../../common/components/Alert";
import { ManagedScanner } from "../../common/components/ManagedScanner";
import { PageContainer, PageScrollNarrow } from "../../common/components/Page";

interface ZoneItem {
	code: string;
}

function ZoneCompleteItem({
	item,
	setItem,
	setEditItem,
	setIsCameraActive,
	setShowEnterBarcode,
	setShowCompleteZone,
}: {
	item: ZoneItem;
	setItem: (value?: ZoneItem) => void;
	setEditItem: React.Dispatch<React.SetStateAction<ZoneItem>>;
	setIsCameraActive: React.Dispatch<React.SetStateAction<boolean>>;
	setShowEnterBarcode: React.Dispatch<React.SetStateAction<boolean>>;
	setShowCompleteZone: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const zoneQuery = useGetZone({
		zoneCode: item.code,
	});
	const params = useParams();

	interface ErrorAlert {
		display: boolean;
		title: string;
		message?: string;
		buttons?: AlertButton[];
	}
	const [errorAlert, setErrorAlert] = React.useState<ErrorAlert>();

	function createAlertMissingNumber() {
		setErrorAlert({
			display: true,
			title: "No zone number recognised",
			message: `Couldn't find a valid zone number`,
			buttons: [
				...(Platform.OS === "web"
					? []
					: [
							{
								text: "Rescan",
								onPress: () => {
									setItem();
									setIsCameraActive(true);
								},
							},
					  ]),
				{
					text: "Enter zone number manually",
					onPress: () => {
						setItem();
						setEditItem({ code: item.code });
						setShowEnterBarcode(true);
					},
				},
			],
		});
	}

	function createAlertInvalidZoneNumber() {
		setErrorAlert({
			display: true,
			title: "Invalid zone number",
			message: `Zone number ${item.code} is not a valid number for this stocktake`,
			buttons: [
				...(Platform.OS === "web"
					? []
					: [
							{
								text: "Rescan",
								onPress: () => {
									setItem();
									setIsCameraActive(true);
								},
							},
					  ]),
				{
					text: "Enter zone number manually",
					onPress: () => {
						setItem();
						setEditItem({ code: item.code });
						setShowEnterBarcode(true);
					},
				},
			],
		});
	}

	function createAlertZoneNumberMismatch() {
		setErrorAlert({
			display: true,
			title: "Zone number mismatch",
			message: `Zone number ${item.code} does not match the scanned zone ${params.zoneCode}`,
			buttons: [
				...(Platform.OS === "web"
					? []
					: [
							{
								text: "Rescan",
								onPress: () => {
									setItem();
									setIsCameraActive(true);
								},
							},
					  ]),
				{
					text: "Enter zone number manually",
					onPress: () => {
						setItem();
						setEditItem({ code: item.code });
						setShowEnterBarcode(true);
					},
				},
			],
		});
	}

	function createAlertZoneCompleted() {
		setErrorAlert({
			display: true,
			title: "Zone already completed",
			message: `Zone ${zoneQuery.data?.data?.result?.code} has already been completed`,
			buttons: [
				{
					text: "Cancel",
					onPress: () => {
						setItem();
					},
				},
			],
		});
	}

	React.useEffect(() => {
		if (!item.code) {
			setIsCameraActive(false);
			createAlertMissingNumber();
			return;
		}

		if (item.code !== params.zoneCode) {
			setIsCameraActive(false);
			createAlertZoneNumberMismatch();
		}
	}, [item.code]);

	React.useEffect(() => {
		if (!zoneQuery.isFetched) return;
		if (zoneQuery.data?.data?.result?.code != params.zoneCode) return;

		setIsCameraActive(false);
		setShowEnterBarcode(false);
		setShowCompleteZone(false);

		if (zoneQuery.data?.data?.result?.code) {
			if (
				ZoneItemStatus.Complete === zoneQuery.data?.data?.result?.status ||
				ZoneItemStatus.Approved === zoneQuery.data?.data?.result?.status
			) {
				createAlertZoneCompleted();
			} else {
				setItem(zoneQuery.data?.data?.result);
				setShowCompleteZone(true);
			}
		} else {
			createAlertInvalidZoneNumber();
		}
	}, [zoneQuery.isFetched]);

	return (
		<YStack key={item.code} width="100%">
			{zoneQuery.isLoading && (
				<YStack flex={1}>
					<H6>Loading...</H6>
				</YStack>
			)}

			{!zoneQuery.isLoading && zoneQuery.data?.data?.result?.code && item.code === params.zoneCode && (
				<YStack flex={1}>
					<H6>Ready to complete Zone {zoneQuery.data.data.result.code}?</H6>
				</YStack>
			)}

			{errorAlert?.display && (
				<Alert
					title={errorAlert.title}
					message={errorAlert.message}
					buttons={errorAlert.buttons}
					display={errorAlert.display}
					onClose={() => setErrorAlert(undefined)}
					onCancel={() => setItem()}
				/>
			)}
		</YStack>
	);
}

export function ZoneComplete() {
	const [zoneItem, setZoneItem] = React.useState<ZoneItem>();
	const [isCameraActive, setIsCameraActive] = React.useState(false);
	const [showEnterBarcode, setShowEnterBarcode] = React.useState(false);
	const [showCompleteZone, setShowCompleteZone] = React.useState(false);
	const [editZoneItem, setEditZoneItem] = React.useState<ZoneItem>({
		code: "",
	});

	const hasCamera = useHasCamera();

	const params = useParams();
	const navigate = useNavigate();

	const [frameProcessor, matchedOcrText] = useOCR(isCameraActive);
	React.useEffect(() => {
		if (zoneItem?.code) return;

		if (matchedOcrText) {
			setZoneItem({ code: matchedOcrText });
			setIsCameraActive(false);
		}
	}, [matchedOcrText]);

	const zoneQuery = useGetZone({
		zoneCode: params.zoneCode,
	});
	const updateZoneQuery = useUpdateZone();

	const isPageLoading = zoneQuery.isLoading;
	const isZoneFound =
		zoneQuery.isFetched &&
		zoneQuery.data?.data?.result != null &&
		zoneQuery.data.data.result.status == ZoneItemStatus.Started &&
		zoneQuery.data.data.startedByRequestor;

	React.useEffect(() => {
		(async () => {
			// Return if the zone does not exist or it was started by another user
			if (!isPageLoading && !isZoneFound) navigate(-1);
		})();
	}, [zoneQuery.isFetched]);

	async function handleCompleteZone() {
		if (!zoneQuery.data?.data?.result) return;

		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: String(zoneQuery.data.data.result.code),
				status: 2,
				startedAt: zoneQuery.data.data.result.startedAt,
				updateStartedBy: false,
			},
		});

		navigate(`/stocktake`, { replace: true });
	}

	return (
		<PageContainer>
			<YStack flex={1} maxWidth={800} w="100%" als="center">
				<ManagedScanner
					frameProcessor={frameProcessor}
					isActive={isCameraActive}
					startScanning={() => {
						setZoneItem(undefined);
						setIsCameraActive(true);
					}}
				/>
			</YStack>

			<PageScrollNarrow>
				<>
					{zoneItem ? (
						<ZoneCompleteItem
							item={zoneItem}
							setItem={(val) => setZoneItem(val)}
							setEditItem={setEditZoneItem}
							setIsCameraActive={setIsCameraActive}
							setShowEnterBarcode={setShowEnterBarcode}
							setShowCompleteZone={setShowCompleteZone}
							key={zoneItem.code}
						/>
					) : (
						<YStack>
							<H4 mb="$3">Scan zone sticker to complete zone</H4>
							<Button
								onPress={() => {
									setShowEnterBarcode(true);
									setEditZoneItem({ code: "" });
									setIsCameraActive(false);
									setShowCompleteZone(false);
								}}
							>
								Enter zone number manually
							</Button>
						</YStack>
					)}

					<Alert
						display={showEnterBarcode}
						title="Enter zone number manually"
						inputs={[
							{
								defaultValue: editZoneItem.code.toString(),
								placeholder: "123456",
								keyboardType: "numeric",
								autoFocus: true,
							},
						]}
						buttons={[
							{
								text: "Done",
								onPress: (response) => {
									let inputZone = response?.inputs?.[0]?.value || "";
									inputZone = inputZone.replace(/[^0-9]/g, "");
									setZoneItem({ code: inputZone });
								},
							},
						]}
						onClose={() => {
							setShowEnterBarcode(false);
						}}
						onCancel={() => {
							setZoneItem(undefined);
						}}
					/>
				</>
			</PageScrollNarrow>

			<XStack space="$3" maxWidth={800} w="100%" p="$3" $gtSm={{ p: "$6" }} als="center">
				{!zoneItem && hasCamera && (
					<Button
						flex={1}
						disabled={isCameraActive}
						onPress={() => {
							setIsCameraActive(true);
						}}
					>
						Scan
					</Button>
				)}

				{zoneItem && (
					<Button flex={1} onPress={() => setZoneItem(undefined)}>
						Back
					</Button>
				)}
				{zoneItem && showCompleteZone && (
					<Button flex={1} disabled={updateZoneQuery.isLoading} onPress={handleCompleteZone}>
						Complete Zone
					</Button>
				)}
			</XStack>
		</PageContainer>
	);
}
