import { Check, ChevronDown, ChevronUp, Edit, MoreVertical, X } from "@tamagui/lucide-icons";
import * as React from "react";
import { useWindowDimensions } from "react-native";
import { useNavigate, useParams } from "react-router";
import { H6, Paragraph, YStack, XStack, Input, H4, Spinner, Unspaced, Separator } from "tamagui";

import { useStockLookup } from "../../../common/api/multiRetail";
import { useAdminDeleteZone } from "../../../common/api/staverton.openapi/admin-zone";
import {
	ZoneListItem,
	QueryItem,
	ZoneItemStatus,
} from "../../../common/api/staverton.openapi/index.schemas";
import { useListQueries } from "../../../common/api/staverton.openapi/query";
import { useGetZone, useUpdateZone } from "../../../common/api/staverton.openapi/zone";
import {
	useDeleteZoneItem,
	useListZoneItems,
	useUpdateZoneItem,
} from "../../../common/api/staverton.openapi/zone-item";
import { Button } from "../../../common/components";
import { Alert } from "../../../common/components/Alert";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";
import { QueryItemView } from "../../query/QueryItemView";

interface ZoneListItemEdit extends Omit<ZoneListItem, "count"> {
	count: string;
}

function ZoneViewListItemOptions({ itemDelete }: { itemDelete: () => void }) {
	const [displayModal, setDisplayModal] = React.useState<boolean>(false);
	return (
		<>
			<Button
				icon={MoreVertical}
				p="$1"
				onPress={() => {
					setDisplayModal(!displayModal);
				}}
			/>

			<Alert
				display={displayModal}
				buttons={[
					{
						text: "Delete",
						icon: X,
						onPress: itemDelete,
					},
				]}
				onClose={() => {
					setDisplayModal(false);
				}}
			/>
		</>
	);
}

function ZoneViewItem({
	item,
	queryItems,
	itemUpdate,
	itemDelete,
}: {
	item: ZoneListItemEdit;
	queryItems?: QueryItem[];
	itemUpdate: (updatedValue: ZoneListItemEdit) => void;
	itemDelete: () => void;
}) {
	const stockQuery = useStockLookup(
		{ code: item.code, checkStockCode: false },
		{ enabled: !item.retailDescription }
	);

	const hasQueries = queryItems && queryItems.length > 0;

	// Prioritize exact match results before falling back to all results (which may be partial match)
	// e.g. Searching for "12345" may return ["012345", "123450", "12345"]
	const resultsExactMatch =
		stockQuery.data?.records.filter(
			(result) => result.Barcode === item.code || String(result.Stock_Code) === item.code
		) ?? [];
	const queryResults = resultsExactMatch.length
		? resultsExactMatch
		: stockQuery.data?.records ?? [];
	const hasLoaded = stockQuery.isFetchedAfterMount;

	React.useEffect(() => {
		if (
			!hasLoaded ||
			item.retailStockCode ||
			queryResults.length !== 1 ||
			!queryResults[0]?.Stock_Code
		)
			return;

		const updatedItem = { ...item };

		// Apply the lookup details to the db item
		updatedItem.code = queryResults[0].Barcode;
		updatedItem.retailStockCode = queryResults[0].Stock_Code;
		updatedItem.retailDescription = queryResults[0].Full_Desc;
		updatedItem.retailStockBalance = queryResults[0].Retail_Stock_Balance;
		updatedItem.retailPrice = queryResults[0].BandA_Sell;

		itemUpdate(updatedItem);
	}, [hasLoaded]);

	return (
		<YStack my="$3" space="$3">
			<XStack space="$3" alignItems="center">
				<YStack flex={1}>
					<H6>{item.code}</H6>
					<Paragraph color="$color" lineHeight="$1">
						{stockQuery.isLoading
							? "Loading..."
							: queryResults[0]?.Full_Desc ?? item.retailDescription ?? "Invalid code"}
					</Paragraph>
				</YStack>

				<Input
					keyboardType="numeric"
					width="$8"
					textAlign="right"
					placeholder="Qty"
					selectTextOnFocus
					value={item.count}
					onChangeText={(inputValue: string) => {
						const formattedValue = inputValue.replace(/[^0-9]/g, "");
						itemUpdate({ ...item, count: formattedValue });
					}}
					onBlur={() => {
						if (item.count == "") {
							itemDelete();
						}
					}}
				/>

				<ZoneViewListItemOptions itemDelete={itemDelete} />
			</XStack>

			{hasQueries &&
				queryItems.map((queryItem) => (
					<QueryItemView queryItem={queryItem} key={queryItem.zoneItemQueryId} />
				))}
		</YStack>
	);
}

function ZoneOptions({ zoneCode, zoneItemCount }: { zoneCode: string; zoneItemCount: number }) {
	const [displayOptions, setDisplayOptions] = React.useState<boolean>(false);
	const [displayDeleteConfirm, setDisplayDeleteConfirm] = React.useState<boolean>(false);
	const [displayDeleteHasItems, setDisplayDeleteHasItems] = React.useState<boolean>(false);

	const adminDeleteZoneQuery = useAdminDeleteZone();

	const navigate = useNavigate();

	async function handleDeleteZone() {
		const deleteResponse = await adminDeleteZoneQuery.mutateAsync({
			params: {
				zoneCode,
			},
		});
		if (deleteResponse.data.result) {
			navigate("/");
		}
	}

	return (
		<>
			<Button
				icon={MoreVertical}
				p="$1"
				onPress={() => {
					setDisplayOptions(!displayOptions);
				}}
			/>

			<Alert
				display={displayOptions}
				buttons={[
					{
						text: "Delete",
						icon: X,
						onPress: () =>
							zoneItemCount > 0 ? setDisplayDeleteHasItems(true) : setDisplayDeleteConfirm(true),
					},
				]}
				onClose={() => {
					setDisplayOptions(false);
				}}
			/>

			<Unspaced>
				<Alert
					display={displayDeleteConfirm}
					title="Delete zone"
					message="Are you sure you wish to delete this zone?"
					buttons={[
						{
							text: "Delete",
							onPress: handleDeleteZone,
						},
						{
							text: "Cancel",
						},
					]}
					onClose={() => {
						setDisplayDeleteConfirm(false);
					}}
				/>
				<Alert
					display={displayDeleteHasItems}
					title="Cannot delete zone"
					message="The zone has some items, please delete all items before deleting the zone."
					buttons={[
						{
							text: "Cancel",
						},
					]}
					onClose={() => {
						setDisplayDeleteHasItems(false);
					}}
				/>
			</Unspaced>
		</>
	);
}

export function AdminZoneView() {
	const params = useParams();
	const navigate = useNavigate();

	const zoneQuery = useGetZone({
		zoneCode: params.zoneCode,
	});
	const zoneItemsQuery = useListZoneItems({
		zoneCode: params.zoneCode,
	});
	const zoneQueriesQuery = useListQueries({
		filterZone: params.zoneCode,
	});
	const updateZoneQuery = useUpdateZone();
	const deleteZoneItemQuery = useDeleteZoneItem();
	const updateZoneItemQuery = useUpdateZoneItem();

	const [listItems, setListItems] = React.useState<ZoneListItemEdit[]>([]);
	const [displayConfirmApproveModal, setDisplayConfirmApproveModal] =
		React.useState<boolean>(false);
	const [showZoneQueries, setShowZoneQueries] = React.useState<boolean>(true);
	const [showConfirmUnassign, setShowConfirmUnassign] = React.useState<boolean>(false);

	const isPageLoading =
		zoneItemsQuery.isLoading || zoneQuery.isLoading || zoneQueriesQuery.isLoading;
	const isZoneFound = zoneQuery.isFetched && zoneQuery.data?.data?.result != null;
	const isStatusComplete =
		zoneQuery.isFetched && zoneQuery.data?.data?.result?.status === ZoneItemStatus.Complete;
	const isStatusStarted =
		zoneQuery.isFetched && zoneQuery.data?.data?.result?.status === ZoneItemStatus.Started;
	const isStatusCompleteOrApproved =
		zoneQuery.isFetched &&
		(zoneQuery.data?.data?.result?.status === ZoneItemStatus.Complete ||
			zoneQuery.data?.data?.result?.status === ZoneItemStatus.Approved);
	const zoneQueriesWithoutItem =
		zoneQueriesQuery.data?.data.results?.filter((q: QueryItem) => !q.zoneItemId) ?? [];
	const hasUnresolvedQueries = zoneQuery.isFetched && !!zoneQuery.data?.data?.result?.queryCount;

	React.useEffect(() => {
		if (zoneItemsQuery.isFetched && zoneItemsQuery.data?.data?.results) {
			setListItems(
				zoneItemsQuery.data?.data?.results.map((item: ZoneListItem) => {
					return {
						...item,
						count: String(item.count),
						showEdit: false,
						scanCount: 1,
					};
				})
			);
		}
	}, [zoneItemsQuery.isFetched, zoneItemsQuery.isRefetching]);

	async function handleSetStartedZone() {
		if (!zoneQuery.data?.data?.result) return;

		// TODO admin service to handle the update without changing the started by user
		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: zoneQuery.data.data.result.code,
				status: 1,
				startedAt: zoneQuery.data.data.result.startedAt,
				updateStartedBy: false,
			},
		});

		navigate("/");
	}

	async function handleApproveZone() {
		if (!zoneQuery.data?.data?.result) return;
		setDisplayConfirmApproveModal(true);
	}

	async function updateZoneCompleted() {
		if (!zoneQuery.data?.data?.result) return;

		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: zoneQuery.data.data.result.code,
				status: 2,
				startedAt: zoneQuery.data.data.result.startedAt,
				updateStartedBy: false,
			},
		});

		navigate("/");
	}

	async function updateZoneApproved() {
		if (!zoneQuery.data?.data?.result) return;

		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: zoneQuery.data.data.result.code,
				status: 3,
				startedAt: zoneQuery.data.data.result.startedAt,
				updateStartedBy: false,
			},
		});

		navigate("/");
	}

	async function updateZoneUnassign() {
		if (!zoneQuery.data?.data?.result) return;

		await updateZoneQuery.mutateAsync({
			data: {
				zoneCode: zoneQuery.data.data.result.code,
				status: 0,
				startedAt: zoneQuery.data.data.result.startedAt,
				updateStartedBy: false,
			},
		});

		zoneQuery.refetch();
	}

	async function handleUpdateListItem(item: ZoneListItemEdit, updatedValue: ZoneListItemEdit) {
		setListItems(listItems.map((i) => (i === item ? { ...updatedValue } : i)));

		if (item != updatedValue) {
			await updateZoneItemQuery.mutateAsync({
				data: {
					zoneItemId: updatedValue.zoneItemId,
					code: updatedValue.code,
					count: Number(updatedValue.count),
					retailStockCode: updatedValue.retailStockCode,
					retailDescription: updatedValue.retailDescription,
					retailStockBalance: updatedValue.retailStockBalance,
					retailPrice: updatedValue.retailPrice,
				},
			});
		}
	}

	async function handleDeleteListItem(item: ZoneListItemEdit) {
		setListItems(listItems.filter((i) => i !== item));

		await deleteZoneItemQuery.mutateAsync({
			params: {
				zoneItemId: item.zoneItemId,
			},
		});
	}

	const showDesktopZoneButtons = useWindowDimensions().width > 880;
	const zoneButtons = (
		<XStack space="$3" jc="flex-end" ai="center" fw="wrap">
			{isStatusCompleteOrApproved && (
				<Button
					my="$1"
					theme="blue"
					icon={Edit}
					disabled={updateZoneQuery.isLoading}
					onPress={handleSetStartedZone}
				>
					Continue Stocktaking
				</Button>
			)}

			{isStatusComplete ? (
				<>
					<Button my="$1" theme="blue" icon={Check} onPress={handleApproveZone}>
						Approve
					</Button>
					<Alert
						display={displayConfirmApproveModal}
						title={hasUnresolvedQueries ? "Zone has unresolved queries" : "Approve zone"}
						message={
							hasUnresolvedQueries
								? "Please resolve all queries before approving this zone"
								: "Are you sure you wish to approve this zone?"
						}
						buttons={[
							...(hasUnresolvedQueries
								? []
								: [
										{
											text: "Approve",
											onPress: () => updateZoneApproved(),
										},
								  ]),
							{
								text: "Cancel",
							},
						]}
						onClose={() => {
							setDisplayConfirmApproveModal(false);
						}}
					/>
				</>
			) : isStatusStarted ? (
				<Button my="$1" theme="green" onPress={() => updateZoneCompleted()}>
					Complete
				</Button>
			) : null}
		</XStack>
	);

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title={`Zone ${params.zoneCode}`}>
						<XStack space="$3" ai="center" fw="wrap-reverse">
							{showDesktopZoneButtons && zoneButtons}

							{!isPageLoading && isZoneFound && zoneQuery.data?.data.result && (
								<ZoneOptions
									zoneCode={zoneQuery.data.data.result.code}
									zoneItemCount={listItems.length}
								/>
							)}
						</XStack>
					</PageHeader>

					{!showDesktopZoneButtons && zoneButtons}

					{isPageLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!isPageLoading && !isZoneFound && <H4>Zone not found</H4>}

					{!isPageLoading && isZoneFound && !!zoneQuery.data?.data.result?.startedByName && (
						<YStack mb="$3" space="$3">
							<XStack ai="center" space="$3">
								<H4>Stocktaker: {zoneQuery.data.data.result.startedByName}</H4>
								{zoneQuery.data.data.result.status == ZoneItemStatus.Started && (
									<Button size="$2" onPress={() => setShowConfirmUnassign(true)}>
										Unassign
									</Button>
								)}
							</XStack>
							<Separator />
							<Alert
								title="Unassign zone"
								message="Are you sure you wish to unassign this zone?"
								buttons={[
									{
										text: "Unassign",
										onPress: updateZoneUnassign,
									},
									{ text: "Cancel" },
								]}
								display={showConfirmUnassign}
								onClose={() => setShowConfirmUnassign(false)}
							/>
						</YStack>
					)}

					{!isPageLoading && zoneQueriesWithoutItem.length > 0 && (
						<YStack mb="$3" space="$3">
							<XStack ai="center" space="$3">
								<H6>Zone Queries</H6>
								<Button
									size="$2"
									icon={showZoneQueries ? ChevronUp : ChevronDown}
									onPress={() => setShowZoneQueries(!showZoneQueries)}
								/>
							</XStack>

							{showZoneQueries &&
								zoneQueriesWithoutItem.map((query) => (
									<QueryItemView queryItem={query} key={query.zoneItemQueryId} />
								))}
							<Separator />
						</YStack>
					)}

					{!isPageLoading && isZoneFound && listItems.length === 0 && <H4>No items yet</H4>}

					{!isPageLoading &&
						isZoneFound &&
						listItems.map((item, idx) => (
							<ZoneViewItem
								item={item}
								queryItems={zoneQueriesQuery.data?.data.results?.filter(
									(q: QueryItem) => q.zoneItemId === item.zoneItemId
								)}
								key={`${idx}-${item.code}`}
								itemUpdate={(updatedValue) => handleUpdateListItem(item, updatedValue)}
								itemDelete={() => handleDeleteListItem(item)}
							/>
						))}
				</>
			</PageScrollNarrow>
		</PageContainer>
	);
}
