import { Platform } from "react-native";
import { useNavigate } from "react-router";
import { YStack, Button } from "tamagui";

type LinkProps =
	| {
			to: string;
			back?: undefined;
	  }
	| {
			back: boolean;
			to?: undefined;
	  };

export function Link({ to, back, ...props }: LinkProps & Parameters<typeof YStack>[0]) {
	const navigate = useNavigate();
	if (!to && !back) {
		return <YStack {...props} />;
	}
	return (
		<YStack
			{...props}
			tag="a"
			cursor={Platform.OS === "web" ? "pointer" : undefined}
			href={to}
			onPress={(ev) => {
				ev.preventDefault();
				props.onPress?.(ev);
				if (back) {
					navigate(-1);
				} else {
					navigate(to ?? "");
				}
			}}
		/>
	);
}

export function LinkButton({ to, back, ...props }: LinkProps & Parameters<typeof Button>[0]) {
	const navigate = useNavigate();
	if (!to && !back) {
		return <Button {...props} />;
	}
	return (
		<Button
			{...props}
			tag="a"
			cursor={Platform.OS === "web" ? "pointer" : undefined}
			href={to}
			onPress={(ev) => {
				ev.preventDefault();
				props.onPress?.(ev);
				if (back) {
					navigate(-1);
				} else {
					navigate(to ?? "");
				}
			}}
		/>
	);
}
