import { useState } from "react";

export function useSearch(
	timeout: number,
	defaultValue = ""
): [value: string, searchQuery: string, setValue: (value: string) => void] {
	const [value, setValue] = useState(defaultValue);
	const [search, setSearch] = useState(defaultValue);
	const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

	function onValue(val: string) {
		if (timeoutId) clearTimeout(timeoutId);
		setValue(val);
		setTimeoutId(
			setTimeout(() => {
				return setSearch(val);
			}, timeout)
		);
	}
	return [value, search, onValue];
}
