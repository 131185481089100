/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	CategoryEnumerableResponse,
	ResponseBase,
	Category,
	DeleteAvailabilityListParams,
	StartAvailabilityListImageUploadResponse,
	StartAvailabilityListImageUploadRequest,
	CompleteAvailabilityListImageUploadRequest,
	DeleteAvailabilityListImageRequest,
} from "./index.schemas";

export const useGetAvailabilityListsHook = () => {
	const getAvailabilityLists = useCustomInstance<CategoryEnumerableResponse>();

	return (signal?: AbortSignal) => {
		return getAvailabilityLists({ url: `/AvailabilityListsAdmin/Index`, method: "get", signal });
	};
};

export const getGetAvailabilityListsQueryKey = () => [`/AvailabilityListsAdmin/Index`];

export type GetAvailabilityListsQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetAvailabilityListsHook>>>
>;
export type GetAvailabilityListsQueryError = unknown;

export const useGetAvailabilityLists = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAvailabilityListsHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAvailabilityListsHook>>>,
		TError,
		TData
	>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAvailabilityListsQueryKey();

	const getAvailabilityLists = useGetAvailabilityListsHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useGetAvailabilityListsHook>>>
	> = ({ signal }) => getAvailabilityLists(signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useGetAvailabilityListsHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useUpsertAvailabilityListHook = () => {
	const upsertAvailabilityList = useCustomInstance<ResponseBase>();

	return (category: Category) => {
		return upsertAvailabilityList({
			url: `/AvailabilityListsAdmin/UpsertAvailabilityList`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: category,
		});
	};
};

export type UpsertAvailabilityListMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpsertAvailabilityListHook>>>
>;
export type UpsertAvailabilityListMutationBody = Category;
export type UpsertAvailabilityListMutationError = unknown;

export const useUpsertAvailabilityList = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpsertAvailabilityListHook>>>,
		TError,
		{ data: Category },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const upsertAvailabilityList = useUpsertAvailabilityListHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpsertAvailabilityListHook>>>,
		{ data: Category }
	> = (props) => {
		const { data } = props ?? {};

		return upsertAvailabilityList(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof upsertAvailabilityList>>,
		TError,
		{ data: Category },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteAvailabilityListHook = () => {
	const deleteAvailabilityList = useCustomInstance<ResponseBase>();

	return (params?: DeleteAvailabilityListParams) => {
		return deleteAvailabilityList({
			url: `/AvailabilityListsAdmin/DeleteAvailabilityList`,
			method: "delete",
			params,
		});
	};
};

export type DeleteAvailabilityListMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListHook>>>
>;

export type DeleteAvailabilityListMutationError = unknown;

export const useDeleteAvailabilityList = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListHook>>>,
		TError,
		{ params?: DeleteAvailabilityListParams },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteAvailabilityList = useDeleteAvailabilityListHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListHook>>>,
		{ params?: DeleteAvailabilityListParams }
	> = (props) => {
		const { params } = props ?? {};

		return deleteAvailabilityList(params);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteAvailabilityList>>,
		TError,
		{ params?: DeleteAvailabilityListParams },
		TContext
	>(mutationFn, mutationOptions);
};
export const useStartAvailabilityListImageUploadHook = () => {
	const startAvailabilityListImageUpload =
		useCustomInstance<StartAvailabilityListImageUploadResponse>();

	return (startAvailabilityListImageUploadRequest: StartAvailabilityListImageUploadRequest) => {
		return startAvailabilityListImageUpload({
			url: `/AvailabilityListsAdmin/StartAvailabilityListImageUpload`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: startAvailabilityListImageUploadRequest,
		});
	};
};

export type StartAvailabilityListImageUploadMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useStartAvailabilityListImageUploadHook>>>
>;
export type StartAvailabilityListImageUploadMutationBody = StartAvailabilityListImageUploadRequest;
export type StartAvailabilityListImageUploadMutationError = unknown;

export const useStartAvailabilityListImageUpload = <
	TError = unknown,
	TContext = unknown
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useStartAvailabilityListImageUploadHook>>>,
		TError,
		{ data: StartAvailabilityListImageUploadRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const startAvailabilityListImageUpload = useStartAvailabilityListImageUploadHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useStartAvailabilityListImageUploadHook>>>,
		{ data: StartAvailabilityListImageUploadRequest }
	> = (props) => {
		const { data } = props ?? {};

		return startAvailabilityListImageUpload(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof startAvailabilityListImageUpload>>,
		TError,
		{ data: StartAvailabilityListImageUploadRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useCompleteAvailabilityListImageUploadHook = () => {
	const completeAvailabilityListImageUpload = useCustomInstance<ResponseBase>();

	return (
		completeAvailabilityListImageUploadRequest: CompleteAvailabilityListImageUploadRequest
	) => {
		return completeAvailabilityListImageUpload({
			url: `/AvailabilityListsAdmin/CompleteAvailabilityListImageUpload`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: completeAvailabilityListImageUploadRequest,
		});
	};
};

export type CompleteAvailabilityListImageUploadMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCompleteAvailabilityListImageUploadHook>>>
>;
export type CompleteAvailabilityListImageUploadMutationBody =
	CompleteAvailabilityListImageUploadRequest;
export type CompleteAvailabilityListImageUploadMutationError = unknown;

export const useCompleteAvailabilityListImageUpload = <
	TError = unknown,
	TContext = unknown
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCompleteAvailabilityListImageUploadHook>>>,
		TError,
		{ data: CompleteAvailabilityListImageUploadRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const completeAvailabilityListImageUpload = useCompleteAvailabilityListImageUploadHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCompleteAvailabilityListImageUploadHook>>>,
		{ data: CompleteAvailabilityListImageUploadRequest }
	> = (props) => {
		const { data } = props ?? {};

		return completeAvailabilityListImageUpload(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof completeAvailabilityListImageUpload>>,
		TError,
		{ data: CompleteAvailabilityListImageUploadRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteAvailabilityListImageHook = () => {
	const deleteAvailabilityListImage = useCustomInstance<ResponseBase>();

	return (deleteAvailabilityListImageRequest: DeleteAvailabilityListImageRequest) => {
		return deleteAvailabilityListImage({
			url: `/AvailabilityListsAdmin/DeleteAvailabilityListImage`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: deleteAvailabilityListImageRequest,
		});
	};
};

export type DeleteAvailabilityListImageMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListImageHook>>>
>;
export type DeleteAvailabilityListImageMutationBody = DeleteAvailabilityListImageRequest;
export type DeleteAvailabilityListImageMutationError = unknown;

export const useDeleteAvailabilityListImage = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListImageHook>>>,
		TError,
		{ data: DeleteAvailabilityListImageRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteAvailabilityListImage = useDeleteAvailabilityListImageHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteAvailabilityListImageHook>>>,
		{ data: DeleteAvailabilityListImageRequest }
	> = (props) => {
		const { data } = props ?? {};

		return deleteAvailabilityListImage(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteAvailabilityListImage>>,
		TError,
		{ data: DeleteAvailabilityListImageRequest },
		TContext
	>(mutationFn, mutationOptions);
};
