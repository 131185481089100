import axios from "axios";

export async function putBlob({ url, data }: { url: string; data: string }) {
	try {
		const b64Data = `data:image/jpeg;base64,${data}`;
		const base64Response = await fetch(b64Data);
		const blobData = await base64Response.blob();

		const result = await axios.put(url, blobData, {
			headers: {
				"Content-Type": "application/octet-stream",
			},
		});

		return {
			...result,
			eTag: result.headers.etag,
		};
	} catch (e) {
		console.error(e);
	}
}
