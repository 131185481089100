/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery } from "react-query";
import type { UseQueryOptions, QueryFunction, UseQueryResult, QueryKey } from "react-query";

import { useUnauthenticatedInstance, useCustomInstance } from "../staverton.openapi.axios";
import type { GetAuthConfigResponse, GetConfigResponse } from "./index.schemas";

export const useGetAuthConfigHook = () => {
	const getAuthConfig = useUnauthenticatedInstance<GetAuthConfigResponse>();

	return (signal?: AbortSignal) => {
		return getAuthConfig({ url: `/Config/AuthConfig`, method: "get", signal });
	};
};

export const getGetAuthConfigQueryKey = () => [`/Config/AuthConfig`];

export type GetAuthConfigQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetAuthConfigHook>>>
>;
export type GetAuthConfigQueryError = unknown;

export const useGetAuthConfig = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAuthConfigHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAuthConfigHook>>>,
		TError,
		TData
	>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAuthConfigQueryKey();

	const getAuthConfig = useGetAuthConfigHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetAuthConfigHook>>>> = ({
		signal,
	}) => getAuthConfig(signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useGetAuthConfigHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useGetConfigHook = () => {
	const getConfig = useCustomInstance<GetConfigResponse>();

	return (signal?: AbortSignal) => {
		return getConfig({ url: `/Config/Create`, method: "get", signal });
	};
};

export const getGetConfigQueryKey = () => [`/Config/Create`];

export type GetConfigQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetConfigHook>>>
>;
export type GetConfigQueryError = unknown;

export const useGetConfig = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetConfigHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetConfigHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetConfigQueryKey();

	const getConfig = useGetConfigHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetConfigHook>>>> = ({
		signal,
	}) => getConfig(signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetConfigHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};
