import { ArrowRight } from "@tamagui/lucide-icons";
import { useAtomValue } from "jotai";
import React from "react";
import { Image } from "react-native";
import { useNavigate } from "react-router";
import { ThemeName, YStack, Paragraph, XStack, H6 } from "tamagui";

import { QueryItem } from "../../common/api/staverton.openapi/index.schemas";
import { useListQueryImages } from "../../common/api/staverton.openapi/query-image";
import { useColorMode } from "../../common/colorMode/colorMode";
import { ScrollView, Button } from "../../common/components/index";
import { themeAtom } from "../../common/storage/atoms";
import { openNewTab } from "../../common/url/url";

export function QueryItemView({
	queryItem,
	hideViewButton,
	showQueryTitle,
}: {
	queryItem: QueryItem;
	hideViewButton?: boolean;
	showQueryTitle?: boolean;
}) {
	const queryImagesQuery = useListQueryImages({
		zoneItemQueryId: queryItem.zoneItemQueryId ?? undefined,
	});

	const isResolved = queryItem.resolvedAt != null;

	const selectedTheme = useAtomValue(themeAtom);
	const colorMode = useColorMode() ?? "light";
	const theme = selectedTheme === "system" ? colorMode : selectedTheme;
	const isDarkMode = theme === "dark";

	let queryBg = isDarkMode ? "$red3Dark" : "$red6Light";
	let queryTitle = isDarkMode ? "$red6Light" : "$red6Dark";
	let queryBody = isDarkMode ? "$red1Light" : "$red1Dark";
	let queryButton: ThemeName = isDarkMode ? "red_alt2" : "red_alt1";
	if (isResolved) {
		queryBg = isDarkMode ? "$blue3Dark" : "$blue3Light";
		queryTitle = isDarkMode ? "$blue6Light" : "$blue6Dark";
		queryBody = isDarkMode ? "$blue1Light" : "$blue1Dark";
		queryButton = isDarkMode ? "blue_alt2" : "blue_alt2";
	}

	const navigate = useNavigate();

	function navigateToEditQuery() {
		navigate(`/query/edit/${queryItem.zoneItemQueryId}`);
	}

	return (
		<YStack space="$2" p="$3" ml={queryItem.zoneItemId ? "$3" : "$0"} bc={queryBg} br="$3">
			<YStack space="$2" opacity={isResolved ? 0.5 : 1}>
				{showQueryTitle && (
					<H6 size={isResolved ? "$3" : "$5"} m={0} color={queryTitle}>
						{queryItem.zoneItemId ? `Item ${queryItem.zoneItemCode}` : `Zone ${queryItem.zoneCode}`}
					</H6>
				)}

				<YStack>
					<Paragraph size={isResolved ? "$1" : undefined} m={0} color={queryTitle}>
						Query details
					</Paragraph>
					<Paragraph size={isResolved ? "$3" : "$5"} m={0} color={queryBody}>
						{queryItem.details}
					</Paragraph>
				</YStack>

				{queryImagesQuery?.data?.data?.results &&
					queryImagesQuery?.data?.data?.results?.length > 0 && (
						<ScrollView flex={1} horizontal height={isResolved ? 64 : 128}>
							{queryImagesQuery?.data?.data?.results.map((image: { s3Location: string }) => (
								<YStack
									mr={isResolved ? "$1.5" : "$3"}
									cursor="pointer"
									hoverStyle={{ opacity: 0.75 }}
									key={image.s3Location}
									width={isResolved ? 64 : 128}
									height={isResolved ? 64 : 128}
									onPress={() => openNewTab(image.s3Location)}
								>
									<Image
										source={{
											uri: image.s3Location,
											width: isResolved ? 64 : 128,
											height: isResolved ? 64 : 128,
										}}
									/>
								</YStack>
							))}
						</ScrollView>
					)}

				<XStack ai="center" space="$3">
					<Paragraph flex={1} size={isResolved ? "$1" : undefined} m={0} color={queryTitle}>
						{queryItem.claimedBy ? `Claimed by ${queryItem.claimedByName}` : "Query is unclaimed"}
					</Paragraph>
				</XStack>

				<XStack ai="center" space="$3">
					<Paragraph flex={1} size={isResolved ? "$1" : undefined} m={0} color={queryTitle}>
						Queried by {queryItem.queriedByName} at{" "}
						{new Date(queryItem.queriedAt).toLocaleString("en-GB")}
					</Paragraph>
					{!hideViewButton && !isResolved && (
						<Button
							size="$3"
							fs={0}
							theme={queryButton}
							iconAfter={ArrowRight}
							onPress={navigateToEditQuery}
						>
							View
						</Button>
					)}
				</XStack>
			</YStack>

			{isResolved && (
				<YStack>
					<Paragraph color={queryTitle} m={0}>
						Query resolution details
					</Paragraph>
					<Paragraph size="$5" m={0} color={queryBody}>
						{queryItem.resolvedDetails}
					</Paragraph>
				</YStack>
			)}
		</YStack>
	);
}
