import { X } from "@tamagui/lucide-icons";
import { IconProps } from "@tamagui/lucide-icons/types/IconProps";
import React, { useEffect, useLayoutEffect } from "react";
import {
	Dimensions,
	KeyboardTypeOptions,
	Modal,
	Platform,
	useWindowDimensions,
} from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { H4, Input, Paragraph, YStack, Dialog, XStack, ThemeName } from "tamagui";

import { ScrollView, Button, Icon } from ".";
import { KeyboardPaddedView } from "./Keyboard";

export interface AlertButton {
	text: string;
	icon?: React.NamedExoticComponent<IconProps>;
	iconAfter?: React.NamedExoticComponent<IconProps>;
	theme?: ThemeName;
	onPress?: (value: { inputs: AlertEditableInput[] }) => void;
}

export interface AlertInput {
	key?: string;
	title?: string;
	placeholder?: string;
	defaultValue?: string;
	autoFocus?: boolean;
	keyboardType?: KeyboardTypeOptions;
}

export interface AlertEditableInput extends AlertInput {
	value: string;
}

function AlertInput({
	input,
	onUpdate,
	onSubmit,
}: {
	input: AlertEditableInput;
	onUpdate: (val: string) => void;
	onSubmit: () => void;
}) {
	return (
		<YStack>
			{!!input.title && <Paragraph>{input.title}</Paragraph>}
			<Input
				nativeID=""
				value={input.value}
				placeholder={input.placeholder}
				autoFocus={input.autoFocus}
				keyboardType={input.keyboardType}
				onChangeText={onUpdate}
				onSubmitEditing={() => onSubmit()}
			/>
		</YStack>
	);
}

function AlertContent(props: { content: React.JSX.Element }) {
	return (
		<ScrollView p="$2" keyboardShouldPersistTaps="handled">
			<KeyboardPaddedView>
				<YStack paddingBottom={40}>{props.content}</YStack>
			</KeyboardPaddedView>
		</ScrollView>
	);
}

export function Alert({
	display,
	title,
	message,
	inputs,
	buttons,
	onClose,
	onCancel,
	children,
	size = "medium",
	showCloseCross,
}: {
	display: boolean;
	title?: string;
	message?: string;
	inputs?: AlertInput[];
	buttons?: AlertButton[];
	onClose?: () => void;
	onCancel?: () => void;
	children?: React.ReactNode;
	size?: "medium" | "large";
	showCloseCross?: boolean;
}) {
	const [shouldMount, setShouldMount] = React.useState(false);
	const [shouldDisplay, setShouldDisplay] = React.useState(false);
	useEffect(() => {
		setShouldDisplay(shouldMount && display);
	}, [display, shouldMount]);
	useEffect(() => {
		if (display) {
			setShouldMount(true);
		}
	}, [display]);
	const windowDimensions = useWindowDimensions();
	const isDesktop = windowDimensions.width > 800;
	const isWeb = Platform.OS === "web";
	const windowHeight = Dimensions.get("window").height;
	const safeArea = useSafeAreaInsets();

	const [editableInputs, setEditableInputs] = React.useState<AlertEditableInput[]>(
		inputs
			? inputs.map((input) => {
					return {
						...input,
						value: "",
					};
			  })
			: []
	);
	useLayoutEffect(() => {
		if (display) {
			setEditableInputs(
				inputs
					? inputs.map((input) => {
							return {
								...input,
								value: "",
							};
					  })
					: []
			);
		}
	}, [display]);

	function handleInputUpdate(input: AlertEditableInput, updatedValue: string) {
		setEditableInputs(
			editableInputs.map((editableInput) =>
				editableInput === input ? { ...input, value: updatedValue } : editableInput
			)
		);
	}

	function handleInputSubmit() {
		if (inputs?.length === 1 && buttons?.length === 1 && buttons[0].onPress) {
			buttons[0].onPress({ inputs: editableInputs });
			onClose?.();
		}
	}

	if (!shouldMount) return null;

	const content = (
		<YStack p={isDesktop ? "$6" : "$3"} space="$3">
			{!!title && <H4 m={0}>{title}</H4>}

			{!!message && (
				<Paragraph m={0} lineHeight={18}>
					{message}
				</Paragraph>
			)}

			{children}

			{editableInputs && editableInputs.length > 0 && (
				<YStack space="$2">
					{editableInputs.map((input, idx) => {
						return (
							<AlertInput
								input={input}
								key={input.key ?? idx}
								onUpdate={(val) => handleInputUpdate(input, val)}
								onSubmit={() => handleInputSubmit()}
							/>
						);
					})}
				</YStack>
			)}

			{buttons && buttons.length > 0 && (
				<YStack space="$2">
					{buttons.map((button, idx) => {
						return (
							<Button
								key={idx}
								icon={button.icon}
								iconAfter={button.iconAfter}
								theme={button.theme}
								h="auto"
								onPress={() => {
									button.onPress?.({ inputs: editableInputs });
									onClose?.();
								}}
							>
								<Paragraph w={0} flex={1} lineHeight="$1" ta="center" my="$2">
									{button.text}
								</Paragraph>
							</Button>
						);
					})}
				</YStack>
			)}
		</YStack>
	);

	if (Platform.OS === "ios") {
		return (
			<Dialog modal open={shouldDisplay}>
				<Dialog.Portal justifyContent="flex-end">
					<Dialog.Overlay
						onPress={() => {
							onCancel?.();
							onClose?.();
						}}
						key="overlay"
						animation="quick"
						o={0.5}
						enterStyle={{ o: 0 }}
						exitStyle={{ o: 0 }}
					/>
					<Dialog.Content
						bordered
						borderRadius={0}
						borderTopLeftRadius="$4"
						borderTopRightRadius="$4"
						elevate
						width="95%"
						key="content"
						animation={[
							"quick",
							{
								opacity: {
									overshootClamping: true,
								},
							},
						]}
						alignSelf="center"
						enterStyle={{ x: 0, y: 100, opacity: 0, scale: 0.9 }}
						exitStyle={{ x: 0, y: 100, opacity: 0, scale: 0.95 }}
						x={0}
						scale={1}
						opacity={1}
						y={0}
						space
						p={0}
						marginBottom={-40}
					>
						<AlertContent content={content} />
					</Dialog.Content>
				</Dialog.Portal>
			</Dialog>
		);
	}

	return (
		<Modal
			animationType={isDesktop ? "fade" : "slide"}
			transparent
			visible={shouldDisplay}
			onRequestClose={() => {
				onClose?.();
				onCancel?.();
			}}
		>
			<YStack
				pos="absolute"
				ai="center"
				jc={isDesktop ? "center" : "flex-end"}
				t={0}
				b={0}
				l={0}
				r={0}
			>
				<YStack
					bc="black"
					o={0.5}
					w="100%"
					h="100%"
					pos="absolute"
					zi={10}
					onPress={() => {
						onClose?.();
						onCancel?.();
					}}
				/>
				<YStack
					w={isDesktop ? "100%" : isWeb ? "auto" : undefined}
					minWidth={200}
					maxWidth={size == "large" ? 800 : 400}
					maxHeight={isDesktop ? "90%" : windowHeight * 0.9}
					pos={isDesktop ? undefined : "absolute"}
					l={isDesktop ? 0 : "$3"}
					r={isDesktop ? 0 : "$3"}
					b={isDesktop ? 0 : safeArea.bottom}
					mx="auto"
					zi={11}
				>
					{showCloseCross && (
						<XStack zi={12} position="absolute" r="$3" t="$3" onPress={onClose} cursor="pointer">
							<Icon size="$9" icon={X} color="black" />
						</XStack>
					)}
					<ScrollView
						bc="$background"
						btrr="$6"
						btlr="$6"
						bbrr={isDesktop ? "$6" : undefined}
						bblr={isDesktop ? "$6" : undefined}
						keyboardShouldPersistTaps="handled"
					>
						<KeyboardPaddedView>{content}</KeyboardPaddedView>
					</ScrollView>
				</YStack>
			</YStack>
		</Modal>
	);
}
