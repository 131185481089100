import { ArrowLeft } from "@tamagui/lucide-icons";
import React, { type ReactNode } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { H1, XStack, YStack } from "tamagui";

import { ScrollView } from ".";
import { Link } from "../../navigation/router";

interface PageProps {
	children: ReactNode;
}

export function PageContainer({ children }: PageProps) {
	const safeArea = useSafeAreaInsets();
	return (
		<YStack
			bc="$background"
			flexDirection="column"
			flex={1}
			pt={safeArea.top}
			pl={safeArea.left}
			pr={safeArea.right}
			h="100%"
		>
			{children}
		</YStack>
	);
}

export function PageScrollNarrow({ children, ...otherProps }: PageProps) {
	return (
		<ScrollView flex={1}>
			<YStack als="center" maxWidth={800} w="100%" p="$3" $gtSm={{ p: "$6" }} {...otherProps}>
				{children}
			</YStack>
		</ScrollView>
	);
}

export function PageScrollWide({ children, ...otherProps }: PageProps) {
	return (
		<ScrollView flex={1}>
			<YStack als="center" maxWidth={1600} w="100%" p="$3" $gtSm={{ p: "$6" }} {...otherProps}>
				{children}
			</YStack>
		</ScrollView>
	);
}

export function PageHeader({
	title,
	allowBack,
	children,
}: {
	title?: string;
	allowBack?: boolean;
	children?: JSX.Element;
}) {
	return (
		<XStack ai="center" space="$3" fw="wrap" mb="$3">
			{allowBack && (
				<Link back>
					<ArrowLeft size={32} />
				</Link>
			)}

			{!!title && <H1 fg={1}>{title}</H1>}

			{children}
		</XStack>
	);
}
