import "@react-native-anywhere/polyfill-base64";
import fjss from "fast-json-stable-stringify";
import { useLayoutEffect, useState } from "react";

// noinspection ES6PreferShortImport
import { useSearchParams } from "../../navigation/router";

type QueryParam<T> = [value: T, setValue: (value: T) => void, defaultValue?: T];

function encode<T>(value: T) {
	return btoa(encodeURIComponent(encodeURIComponent(fjss(value))))
		.replace(/=/g, "_")
		.replace(/\+/g, "-");
}

function decode<T>(value: string): T {
	return JSON.parse(
		decodeURIComponent(decodeURIComponent(atob(value.replace(/_/g, "=").replace(/-/g, "+"))))
	);
}

export function useSyncQueryParams<
	T extends {
		[key: string]: QueryParam<any>;
	}
>(values: T) {
	const [queryParams, setQueryParams] = useSearchParams();
	const [hasLoaded, setHasLoaded] = useState(false);
	useLayoutEffect(() => {
		if (!hasLoaded) {
			for (const [key, [, setValue]] of Object.entries(values)) {
				const param = queryParams.get(key);
				console.log(key, param);
				if (param !== null) {
					setValue(decode(param));
				}
			}
			setHasLoaded(true);
		}
	}, [hasLoaded]);

	useLayoutEffect(() => {
		for (const [key, [value, , defaultValue]] of Object.entries(values)) {
			setQueryParams(
				(v) => {
					if (value === defaultValue) {
						v.delete(key);
						return v;
					}
					v.set(key, encode(value));
					return v;
				},
				{
					replace: true,
				}
			);
		}
	}, [fjss(Object.entries(values).map(([key, [value]]) => [key, value]))]);
}

export function useQueryParamsState<T>(key: string, defaultValue: T) {
	const [value, setValue] = useState(defaultValue);
	useSyncQueryParams({
		[key]: [value, setValue, defaultValue],
	});

	return [value, setValue] as const;
}
