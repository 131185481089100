import {
	Bell,
	CheckCircle,
	default as F,
	HelpCircle,
	ClipboardEdit,
	Plus,
	User,
	LayoutList,
} from "@tamagui/lucide-icons";
import * as Notifications from "expo-notifications";
import React, { useState } from "react";
import { Platform, useWindowDimensions } from "react-native";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { Outlet, useLocation } from "react-router";
import { Text, XStack, YStack } from "tamagui";

import { useListUserNotifications } from "../common/api/staverton.openapi/user-notification";
import { useAuth } from "../common/auth/useAuth";
import { Icon } from "../common/components";
import { Notification } from "../notification/notification";
// noinspection ES6PreferShortImport
import { Link } from "./router";

export type TabProps = {
	label: string;
	icon: typeof F[keyof typeof F];
	prefix?: string;
	path?: string;
	count?: number;
	onPress?: () => void;
};

function Tab({ icon, label, onPress, path, prefix, count }: TabProps) {
	const windowSize = useWindowDimensions();
	const isDesktop = windowSize.width >= 800;
	const location = useLocation();

	return (
		<Link
			px={isDesktop ? "$2" : 0}
			py={isDesktop ? "$4" : 0}
			to={path}
			key={label}
			pos="relative"
			ai="center"
			jc="center"
			flex={isDesktop ? 0 : 1}
			onPress={onPress}
		>
			<Icon
				icon={icon}
				color={prefix && location.pathname.startsWith(prefix) ? "$color" : "$colorTranslucent"}
				size={isDesktop ? "$8" : "$6"}
			/>
			<Text
				fontFamily="$body"
				m={0}
				h="$1"
				w="100%"
				ta="center"
				color={prefix && location.pathname.startsWith(prefix) ? "$color" : "$colorTranslucent"}
			>
				{label}
			</Text>

			{count != null && count > 0 && (
				<XStack
					pos="absolute"
					t="$1.5"
					r="$2"
					$gtSm={{
						t: "$3",
						r: "$3",
					}}
				>
					<XStack br="$20" bc="$red9" w="$1" h="$1" ai="center" jc="center">
						{count > 9 ? (
							<Plus size={12} color="$red1" />
						) : (
							<Text m={0} fontFamily="$body" color="$red1" pb="$0.25" fontSize="$1">
								{count}
							</Text>
						)}
					</XStack>
				</XStack>
			)}
		</Link>
	);
}

function Tabs() {
	const auth = useAuth();
	const windowSize = useWindowDimensions();
	const isDesktop = windowSize.width >= 800;

	const [profileAlertCount, setProfileAlertCount] = useState<number>();

	// Web polling every 1 min, native should receive push notification to trigger refetch
	const notificationPolling = Platform.OS === "web" ? 60 * 1000 : 0;
	const listUserNotificationsQuery = useListUserNotifications(
		{
			pageFrom: 0,
			pageSize: 10,
		},
		{
			query: {
				queryKey: "TabbedUserNotifications",
				staleTime: notificationPolling,
			},
		}
	);

	React.useEffect(() => {
		const subscription = Notifications.addNotificationReceivedListener((notification) => {
			console.info("Received notification", notification);
			listUserNotificationsQuery.refetch();
		});
		return () => subscription.remove();
	}, []);

	React.useEffect(() => {
		if (listUserNotificationsQuery.isLoading || !listUserNotificationsQuery.data?.data.results)
			return;

		setProfileAlertCount(
			listUserNotificationsQuery.data.data.results.filter((notification) => !notification.readAt)
				.length
		);
	}, [
		listUserNotificationsQuery.isLoading,
		listUserNotificationsQuery.isRefetching,
		listUserNotificationsQuery.data,
	]);

	React.useEffect(() => {
		listUserNotificationsQuery.refetch();
	}, [listUserNotificationsQuery.isStale]);

	return (
		<>
			{auth.isAdmin && (
				<>
					<Tab icon={ClipboardEdit} label="Admin" path="/admin/zones" prefix="/admin/zone" />
					<Tab icon={HelpCircle} label="Queries" path="/admin/query" prefix="/admin/query" />
				</>
			)}

			{auth.isAvailabilityListAdmin && (
				<Tab
					icon={LayoutList}
					label="Availability"
					path="/admin/availability"
					prefix="/admin/availability"
				/>
			)}

			<Tab icon={CheckCircle} label="Stocktake" path="/stocktake" prefix="/stocktake" />

			<YStack flex={isDesktop ? 1 : 0} />

			<Tab icon={Bell} label="Alerts" path="/alerts" prefix="/alerts" count={profileAlertCount} />
			<Tab icon={User} label="Profile" path="/profile" prefix="/profile" />
		</>
	);
}

export function TabbedScreen() {
	const safeArea = useSafeAreaInsets();
	const windowSize = useWindowDimensions();
	const isDesktop = windowSize.width >= 800;

	if (isDesktop) {
		return (
			<XStack
				maxHeight={Platform.OS === "web" ? "100vh" : undefined}
				maxWidth={Platform.OS === "web" ? "100vw" : undefined}
				bc="$background"
				flexDirection="row"
				flex={1}
			>
				<YStack
					bc="$backgroundSoft"
					paddingLeft={safeArea.left}
					paddingTop={safeArea.top}
					paddingBottom={safeArea.bottom}
				>
					<YStack flex={1} flexDirection="column" height="100%" alignSelf="center">
						<Tabs />
					</YStack>
				</YStack>
				<YStack flex={1}>
					<Outlet />
				</YStack>
				<Notification />
			</XStack>
		);
	}

	return (
		<YStack
			maxHeight={Platform.OS === "web" ? "100vh" : undefined}
			maxWidth={Platform.OS === "web" ? "100vw" : undefined}
			bc="$background"
			flexDirection="column"
			flex={1}
		>
			<YStack flex={1}>
				<Outlet />
			</YStack>
			<YStack
				paddingBottom={safeArea.bottom}
				paddingLeft={safeArea.left}
				paddingRight={safeArea.right}
				bc="$backgroundSoft"
				height={56 + safeArea.bottom}
			>
				<XStack
					flex={1}
					flexDirection="row"
					width="100%"
					maxWidth={400}
					alignSelf="center"
					height={56}
				>
					<Tabs />
				</XStack>
			</YStack>
			<Notification />
		</YStack>
	);
}
