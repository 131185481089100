import { Save } from "@tamagui/lucide-icons";
import * as Application from "expo-application";
import Constants from "expo-constants";
import { documentDirectory, cacheDirectory } from "expo-file-system";
import * as Sharing from "expo-sharing";
import * as Updates from "expo-updates";
import * as React from "react";
import { Alert, Platform } from "react-native";
import { H3, Paragraph, YStack } from "tamagui";

import { Button } from "../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../common/components/Page";
import { zip } from "./zip";

export function DebugScreen() {
	return (
		<PageContainer>
			<PageScrollNarrow>
				<PageHeader allowBack title="Debugging Tools" />
				<YStack space="$3">
					<H3>
						{Platform.select({
							ios: "iOS ",
							android: "Android ",
							web: "Web ",
							default: "",
						})}
						App Version:{" "}
						{Application.nativeApplicationVersion ?? Constants.expoConfig?.version ?? "Unknown"} (
						{Platform.OS === "web" ? "Web" : Application.nativeBuildVersion ?? "Unknown"})
					</H3>
					<Paragraph>
						OTA Runtime {Updates?.runtimeVersion ?? "N/A"} - Update {Updates?.updateId ?? "N/A"}
					</Paragraph>
					<Button
						icon={Save}
						onPress={async () => {
							try {
								if (Platform.OS === "web") {
									alert(`Not supported on Web`);
									return;
								}

								const targetPath = `${cacheDirectory!}/export-${Date.now()}.zip`;
								const sourcePath = documentDirectory!;
								await zip(sourcePath, targetPath);
								await Sharing.shareAsync(targetPath);
								// eslint-disable-next-line @typescript-eslint/no-explicit-any
							} catch (e: any) {
								Alert.alert(
									`Failed to export data`,
									`An error occurred while trying to export data: ${e.message}`
								);
							}
						}}
					>
						Export Data
					</Button>
					<Button
						onPress={() => {
							throw new Error("Test Error");
						}}
					>
						Throw Error
					</Button>
				</YStack>
			</PageScrollNarrow>
		</PageContainer>
	);
}
