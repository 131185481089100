import { Check, ChevronDown, ChevronUp } from "@tamagui/lucide-icons";
import React from "react";
import { Select, SizableText, YStack } from "tamagui";

export interface FilterOption {
	id: string | number;
	label: string;
}

export function FilterSelect({
	placeholder,
	value,
	options,
	updateValue,
	...otherProps
}: {
	placeholder?: string;
	value: string;
	options: FilterOption[];
	updateValue: (value: string) => void;
}) {
	return (
		<Select sheetBreakpoint="sm" value={value} onValueChange={updateValue} {...otherProps}>
			<Select.Trigger w="100%" iconAfter={ChevronDown} cursor="pointer">
				<SizableText color="$color" margin={0}>
					<Select.Value placeholder={placeholder} color="$color" margin={0} />
				</SizableText>
			</Select.Trigger>

			<Select.Sheet modal dismissOnSnapToBottom>
				<Select.Sheet.Frame>
					<Select.Sheet.ScrollView>
						<Select.SheetContents />
					</Select.Sheet.ScrollView>
				</Select.Sheet.Frame>
				<Select.Sheet.Overlay />
			</Select.Sheet>

			<Select.Content>
				<Select.ScrollUpButton ai="center" jc="center" pos="relative" w="100%" h="$3">
					<YStack zi={10}>
						<ChevronUp size={20} />
					</YStack>
				</Select.ScrollUpButton>

				<Select.Viewport minWidth={200}>
					<Select.Group>
						{options.map((item, i) => {
							return (
								<Select.Item
									bc="$backgroundSoft"
									index={i}
									key={i}
									value={String(item.id)}
									cursor="pointer"
								>
									<Select.ItemText color="$color" margin={0} cursor="pointer">
										{item.label}
									</Select.ItemText>
									<Select.ItemIndicator ml="auto">
										<Check size={16} />
									</Select.ItemIndicator>
								</Select.Item>
							);
						})}
					</Select.Group>
				</Select.Viewport>

				<Select.ScrollDownButton ai="center" jc="center" pos="relative" w="100%" h="$3">
					<YStack zi={10}>
						<ChevronDown size={20} />
					</YStack>
				</Select.ScrollDownButton>
			</Select.Content>
		</Select>
	);
}
