import * as React from "react";
import { useNavigate } from "react-router";
import { Paragraph, XStack, Spinner } from "tamagui";

import {
	useGetAdminStocktakeCompletable,
	useUpdateAdminStocktake,
} from "../../../common/api/staverton.openapi/admin-stocktake";
import { Button } from "../../../common/components";
import { PageContainer, PageHeader, PageScrollNarrow } from "../../../common/components/Page";

export function AdminStocktakeComplete() {
	const navigate = useNavigate();

	const queryCompletable = useGetAdminStocktakeCompletable();
	const updateAdminStocktakeQuery = useUpdateAdminStocktake();

	async function handleCompleteStocktake() {
		if (!queryCompletable.data?.data.stocktake?.stocktakeId) return;

		const response = await updateAdminStocktakeQuery.mutateAsync({
			data: {
				status: "complete",
			},
		});

		if (response.data.result) {
			navigate(`/admin/stocktake/review/${queryCompletable.data.data.stocktake.stocktakeId}`);
		}
	}

	const isSaving = updateAdminStocktakeQuery.isLoading;
	const errorMessage = updateAdminStocktakeQuery.isError
		? " An error occurred"
		: updateAdminStocktakeQuery.data?.data.errorMessage ?? null;

	return (
		<PageContainer>
			<PageScrollNarrow>
				<>
					<PageHeader allowBack title="Complete Stocktake" />

					{queryCompletable.isLoading && (
						<XStack justifyContent="center">
							<Spinner />
						</XStack>
					)}

					{!queryCompletable.isLoading && !queryCompletable.data?.data.result && (
						<Paragraph mb="$3">
							All zones must be approved before the stocktake can be completed
						</Paragraph>
					)}

					{!queryCompletable.isLoading && queryCompletable.data?.data.result && (
						<>
							<Paragraph mb="$3">Are you sure you wish to complete the stocktake?</Paragraph>

							<Button disabled={isSaving} onPress={handleCompleteStocktake}>
								Complete stocktake
							</Button>

							{!isSaving && errorMessage != null && (
								<Paragraph mb="$3" color="$red9">
									{errorMessage}
								</Paragraph>
							)}
						</>
					)}
				</>
			</PageScrollNarrow>
		</PageContainer>
	);
}
