/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	ZoneListResponse,
	ZoneGetResponse,
	GetZoneParams,
	ZoneUpdateResponse,
	ZoneUpdateRequest,
} from "./index.schemas";

export const useListZonesHook = () => {
	const listZones = useCustomInstance<ZoneListResponse>();

	return (signal?: AbortSignal) => {
		return listZones({ url: `/Zone/List`, method: "get", signal });
	};
};

export const getListZonesQueryKey = () => [`/Zone/List`];

export type ListZonesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListZonesHook>>>
>;
export type ListZonesQueryError = unknown;

export const useListZones = <
	TData = Awaited<ReturnType<ReturnType<typeof useListZonesHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useListZonesHook>>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListZonesQueryKey();

	const listZones = useListZonesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListZonesHook>>>> = ({
		signal,
	}) => listZones(signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useListZonesHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useGetZoneHook = () => {
	const getZone = useCustomInstance<ZoneGetResponse>();

	return (params?: GetZoneParams, signal?: AbortSignal) => {
		return getZone({ url: `/Zone/Get`, method: "get", params, signal });
	};
};

export const getGetZoneQueryKey = (params?: GetZoneParams) => [
	`/Zone/Get`,
	...(params ? [params] : []),
];

export type GetZoneQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetZoneHook>>>
>;
export type GetZoneQueryError = unknown;

export const useGetZone = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetZoneHook>>>,
	TError = unknown
>(
	params?: GetZoneParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetZoneHook>>>, TError, TData>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetZoneQueryKey(params);

	const getZone = useGetZoneHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetZoneHook>>>> = ({
		signal,
	}) => getZone(params, signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetZoneHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useUpdateZoneHook = () => {
	const updateZone = useCustomInstance<ZoneUpdateResponse>();

	return (zoneUpdateRequest: ZoneUpdateRequest) => {
		return updateZone({
			url: `/Zone/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: zoneUpdateRequest,
		});
	};
};

export type UpdateZoneMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateZoneHook>>>
>;
export type UpdateZoneMutationBody = ZoneUpdateRequest;
export type UpdateZoneMutationError = unknown;

export const useUpdateZone = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateZoneHook>>>,
		TError,
		{ data: ZoneUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateZone = useUpdateZoneHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateZoneHook>>>,
		{ data: ZoneUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateZone(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateZone>>,
		TError,
		{ data: ZoneUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
