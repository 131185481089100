/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	UserDeviceListResponse,
	UserDeviceCreateResponse,
	UserDeviceCreateRequest,
	UserDeviceUpdateResponse,
	UserDeviceUpdateRequest,
	UserDeviceDeleteResponse,
	DeleteUserDeviceParams,
} from "./index.schemas";

export const useListUserDevicesHook = () => {
	const listUserDevices = useCustomInstance<UserDeviceListResponse>();

	return (signal?: AbortSignal) => {
		return listUserDevices({ url: `/UserDevice/List`, method: "get", signal });
	};
};

export const getListUserDevicesQueryKey = () => [`/UserDevice/List`];

export type ListUserDevicesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListUserDevicesHook>>>
>;
export type ListUserDevicesQueryError = unknown;

export const useListUserDevices = <
	TData = Awaited<ReturnType<ReturnType<typeof useListUserDevicesHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useListUserDevicesHook>>>,
		TError,
		TData
	>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListUserDevicesQueryKey();

	const listUserDevices = useListUserDevicesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListUserDevicesHook>>>> = ({
		signal,
	}) => listUserDevices(signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useListUserDevicesHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useCreateUserDeviceHook = () => {
	const createUserDevice = useCustomInstance<UserDeviceCreateResponse>();

	return (userDeviceCreateRequest: UserDeviceCreateRequest) => {
		return createUserDevice({
			url: `/UserDevice/Create`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: userDeviceCreateRequest,
		});
	};
};

export type CreateUserDeviceMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCreateUserDeviceHook>>>
>;
export type CreateUserDeviceMutationBody = UserDeviceCreateRequest;
export type CreateUserDeviceMutationError = unknown;

export const useCreateUserDevice = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCreateUserDeviceHook>>>,
		TError,
		{ data: UserDeviceCreateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const createUserDevice = useCreateUserDeviceHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCreateUserDeviceHook>>>,
		{ data: UserDeviceCreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return createUserDevice(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof createUserDevice>>,
		TError,
		{ data: UserDeviceCreateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useUpdateUserDeviceHook = () => {
	const updateUserDevice = useCustomInstance<UserDeviceUpdateResponse>();

	return (userDeviceUpdateRequest: UserDeviceUpdateRequest) => {
		return updateUserDevice({
			url: `/UserDevice/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: userDeviceUpdateRequest,
		});
	};
};

export type UpdateUserDeviceMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateUserDeviceHook>>>
>;
export type UpdateUserDeviceMutationBody = UserDeviceUpdateRequest;
export type UpdateUserDeviceMutationError = unknown;

export const useUpdateUserDevice = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserDeviceHook>>>,
		TError,
		{ data: UserDeviceUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateUserDevice = useUpdateUserDeviceHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateUserDeviceHook>>>,
		{ data: UserDeviceUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateUserDevice(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateUserDevice>>,
		TError,
		{ data: UserDeviceUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useDeleteUserDeviceHook = () => {
	const deleteUserDevice = useCustomInstance<UserDeviceDeleteResponse>();

	return (params?: DeleteUserDeviceParams) => {
		return deleteUserDevice({ url: `/UserDevice/Delete`, method: "delete", params });
	};
};

export type DeleteUserDeviceMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useDeleteUserDeviceHook>>>
>;

export type DeleteUserDeviceMutationError = unknown;

export const useDeleteUserDevice = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserDeviceHook>>>,
		TError,
		{ params?: DeleteUserDeviceParams },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const deleteUserDevice = useDeleteUserDeviceHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useDeleteUserDeviceHook>>>,
		{ params?: DeleteUserDeviceParams }
	> = (props) => {
		const { params } = props ?? {};

		return deleteUserDevice(params);
	};

	return useMutation<
		Awaited<ReturnType<typeof deleteUserDevice>>,
		TError,
		{ params?: DeleteUserDeviceParams },
		TContext
	>(mutationFn, mutationOptions);
};
