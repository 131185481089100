/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	QueryCreateResponse,
	QueryCreateRequest,
	QueryListResponse,
	ListQueriesParams,
	QueryGetResponse,
	GetQueryParams,
	QueryUpdateResponse,
	QueryUpdateRequest,
} from "./index.schemas";

export const useCreateQueryHook = () => {
	const createQuery = useCustomInstance<QueryCreateResponse>();

	return (queryCreateRequest: QueryCreateRequest) => {
		return createQuery({
			url: `/Query/Create`,
			method: "post",
			headers: { "Content-Type": "application/json" },
			data: queryCreateRequest,
		});
	};
};

export type CreateQueryMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCreateQueryHook>>>
>;
export type CreateQueryMutationBody = QueryCreateRequest;
export type CreateQueryMutationError = unknown;

export const useCreateQuery = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCreateQueryHook>>>,
		TError,
		{ data: QueryCreateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const createQuery = useCreateQueryHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCreateQueryHook>>>,
		{ data: QueryCreateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return createQuery(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof createQuery>>,
		TError,
		{ data: QueryCreateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useListQueriesHook = () => {
	const listQueries = useCustomInstance<QueryListResponse>();

	return (params?: ListQueriesParams, signal?: AbortSignal) => {
		return listQueries({ url: `/Query/List`, method: "get", params, signal });
	};
};

export const getListQueriesQueryKey = (params?: ListQueriesParams) => [
	`/Query/List`,
	...(params ? [params] : []),
];

export type ListQueriesQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useListQueriesHook>>>
>;
export type ListQueriesQueryError = unknown;

export const useListQueries = <
	TData = Awaited<ReturnType<ReturnType<typeof useListQueriesHook>>>,
	TError = unknown
>(
	params?: ListQueriesParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useListQueriesHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getListQueriesQueryKey(params);

	const listQueries = useListQueriesHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useListQueriesHook>>>> = ({
		signal,
	}) => listQueries(params, signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useListQueriesHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useGetQueryHook = () => {
	const getQuery = useCustomInstance<QueryGetResponse>();

	return (params?: GetQueryParams, signal?: AbortSignal) => {
		return getQuery({ url: `/Query/Get`, method: "get", params, signal });
	};
};

export const getGetQueryQueryKey = (params?: GetQueryParams) => [
	`/Query/Get`,
	...(params ? [params] : []),
];

export type GetQueryQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetQueryHook>>>
>;
export type GetQueryQueryError = unknown;

export const useGetQuery = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetQueryHook>>>,
	TError = unknown
>(
	params?: GetQueryParams,
	options?: {
		query?: UseQueryOptions<Awaited<ReturnType<ReturnType<typeof useGetQueryHook>>>, TError, TData>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetQueryQueryKey(params);

	const getQuery = useGetQueryHook();

	const queryFn: QueryFunction<Awaited<ReturnType<ReturnType<typeof useGetQueryHook>>>> = ({
		signal,
	}) => getQuery(params, signal);

	const query = useQuery<Awaited<ReturnType<ReturnType<typeof useGetQueryHook>>>, TError, TData>(
		queryKey,
		queryFn,
		queryOptions
	) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useUpdateQueryHook = () => {
	const updateQuery = useCustomInstance<QueryUpdateResponse>();

	return (queryUpdateRequest: QueryUpdateRequest) => {
		return updateQuery({
			url: `/Query/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: queryUpdateRequest,
		});
	};
};

export type UpdateQueryMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateQueryHook>>>
>;
export type UpdateQueryMutationBody = QueryUpdateRequest;
export type UpdateQueryMutationError = unknown;

export const useUpdateQuery = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateQueryHook>>>,
		TError,
		{ data: QueryUpdateRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateQuery = useUpdateQueryHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateQueryHook>>>,
		{ data: QueryUpdateRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateQuery(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateQuery>>,
		TError,
		{ data: QueryUpdateRequest },
		TContext
	>(mutationFn, mutationOptions);
};
