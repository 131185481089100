import {
	exchangeCodeAsync,
	fetchDiscoveryAsync,
	makeRedirectUri,
	TokenResponse,
	useAuthRequest,
	dismiss,
	Prompt,
} from "expo-auth-session";
import { resolveDiscoveryAsync } from "expo-auth-session/src/Discovery";
import { maybeCompleteAuthSession } from "expo-web-browser";
import { useAtom } from "jotai";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { Alert, Platform } from "react-native";
import { useQuery } from "react-query";

import { APP_SCHEME } from "../../constants/application";
import { useGetAuthConfig, useGetAuthConfigHook } from "../api/staverton.openapi/config";
import { authAtom } from "../storage/atoms";

maybeCompleteAuthSession();

function useDiscovery() {
	const config = useGetAuthConfig();
	const discovery = useQuery(
		["discovery", config.data?.data.azureDiscoveryUrl],
		() => {
			return resolveDiscoveryAsync(config.data!.data.azureDiscoveryUrl);
		},
		{
			enabled: !!config.data,
		}
	);
	return discovery.data ?? null;
}

export interface Token {
	accessToken: string;
}

export interface AuthDetails {
	preferred_username: string;
	email: string;
	name: string;
	roles: string[];
}

export type UseAuthResponse =
	| {
			isAuthenticated: false;
			getToken: undefined;
			details: undefined;
			isAdmin: undefined;
			isAvailabilityListAdmin: undefined;
	  }
	| {
			isAuthenticated: true;
			getToken: () => Promise<Token | undefined>;
			details: Partial<AuthDetails>;
			isAdmin: boolean;
			isAvailabilityListAdmin: boolean;
	  };

export function useAuth(): UseAuthResponse {
	const [token, setToken] = useAtom(authAtom);
	const getConfig = useGetAuthConfigHook();

	if (!token) {
		return {
			isAuthenticated: false,
			getToken: undefined,
			details: undefined,
			isAdmin: undefined,
			isAvailabilityListAdmin: undefined,
		};
	}

	// TODO: Make AuthDetails non-partial and logout when no valid idToken
	const details: Partial<AuthDetails> = token.idToken ? jwtDecode(token.idToken) : {};

	return {
		isAuthenticated: true,
		getToken: async () => {
			try {
				let tokenResponse = new TokenResponse(token);
				if (tokenResponse.shouldRefresh()) {
					const config = await getConfig();
					const discovery = await fetchDiscoveryAsync(config.data.azureDiscoveryUrl);
					tokenResponse = await tokenResponse.refreshAsync(
						{
							clientId: config.data.azureClientId,
							scopes: config.data.azureScopes,
						},
						discovery
					);
					setToken(tokenResponse);
				}
				return {
					accessToken: tokenResponse.accessToken,
				};
			} catch {
				setToken(undefined);
				return undefined;
			}
		},
		details,
		isAdmin: !!details.roles?.includes("Staverton.Role.Admin"),
		isAvailabilityListAdmin: !!details.roles?.includes("Staverton.Role.AvailabilityListAdmin"),
	};
}

export function useAuthLogin() {
	const [, setToken] = useAtom(authAtom);
	const config = useGetAuthConfig();
	const redirectUri =
		Platform.OS === "web"
			? makeRedirectUri({
					path: "login",
			  })
			: `${APP_SCHEME}://login`;

	const discovery = useDiscovery();

	const [request, response, promptAsync] = useAuthRequest(
		{
			clientId: config.data?.data.azureClientId ?? "",
			scopes: config.data?.data.azureScopes ?? [],
			redirectUri,
			usePKCE: true,
			prompt: Prompt.SelectAccount,
		},
		discovery
	);
	useEffect(() => {
		console.debug("useAuthLogin: response", response);
		if (response?.type === "success") {
			const { code } = response.params;
			exchangeCodeAsync(
				{
					code,
					clientId: config.data?.data.azureClientId ?? "",
					redirectUri,
					extraParams: {
						code_verifier: request?.codeVerifier!,
					},
				},
				discovery!
			)
				.then((result) => {
					setToken(result);
				})
				.catch((e) => {
					setToken(undefined);
					console.error(e);
					if (Platform.OS === "web") {
						alert(`Login failed: ${JSON.stringify(e)}`);
					} else {
						Alert.alert("Login failed");
					}
				});
		} else if (response?.type === "error") {
			if (Platform.OS === "web") {
				alert(`Login failed: ${JSON.stringify(response)}`);
			} else {
				Alert.alert("Login failed");
			}
			console.debug("Login failed", response);
		} else if (response) {
			console.debug("Unknown response", response);
		}
	}, [response]);

	return request ? promptAsync : undefined;
}

export function useAuthLogout() {
	const [, setToken] = useAtom(authAtom);

	return () => {
		if (Platform.OS === "web") dismiss();
		setToken(undefined);
	};
}
