/**
 * Generated by orval v6.10.2 🍺
 * Do not edit manually.
 * StavertonAPI
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from "react-query";
import type {
	UseQueryOptions,
	UseMutationOptions,
	QueryFunction,
	MutationFunction,
	UseQueryResult,
	QueryKey,
} from "react-query";

import { useCustomInstance } from "../staverton.openapi.axios";
import type {
	GetAdminStocktakeCompletableResponse,
	UpdateAdminStocktakeResponse,
	UpdateAdminStocktakeRequest,
	CreateAdminStocktakeResponse,
	GetAdminStocktakeReviewResponse,
	GetAdminStocktakeReviewParams,
} from "./index.schemas";

export const useGetAdminStocktakeCompletableHook = () => {
	const getAdminStocktakeCompletable = useCustomInstance<GetAdminStocktakeCompletableResponse>();

	return (signal?: AbortSignal) => {
		return getAdminStocktakeCompletable({
			url: `/AdminStocktake/GetCompletable`,
			method: "get",
			signal,
		});
	};
};

export const getGetAdminStocktakeCompletableQueryKey = () => [`/AdminStocktake/GetCompletable`];

export type GetAdminStocktakeCompletableQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeCompletableHook>>>
>;
export type GetAdminStocktakeCompletableQueryError = unknown;

export const useGetAdminStocktakeCompletable = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeCompletableHook>>>,
	TError = unknown
>(options?: {
	query?: UseQueryOptions<
		Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeCompletableHook>>>,
		TError,
		TData
	>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAdminStocktakeCompletableQueryKey();

	const getAdminStocktakeCompletable = useGetAdminStocktakeCompletableHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeCompletableHook>>>
	> = ({ signal }) => getAdminStocktakeCompletable(signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeCompletableHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};

export const useUpdateAdminStocktakeHook = () => {
	const updateAdminStocktake = useCustomInstance<UpdateAdminStocktakeResponse>();

	return (updateAdminStocktakeRequest: UpdateAdminStocktakeRequest) => {
		return updateAdminStocktake({
			url: `/AdminStocktake/Update`,
			method: "put",
			headers: { "Content-Type": "application/json" },
			data: updateAdminStocktakeRequest,
		});
	};
};

export type UpdateAdminStocktakeMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useUpdateAdminStocktakeHook>>>
>;
export type UpdateAdminStocktakeMutationBody = UpdateAdminStocktakeRequest;
export type UpdateAdminStocktakeMutationError = unknown;

export const useUpdateAdminStocktake = <TError = unknown, TContext = unknown>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useUpdateAdminStocktakeHook>>>,
		TError,
		{ data: UpdateAdminStocktakeRequest },
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const updateAdminStocktake = useUpdateAdminStocktakeHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useUpdateAdminStocktakeHook>>>,
		{ data: UpdateAdminStocktakeRequest }
	> = (props) => {
		const { data } = props ?? {};

		return updateAdminStocktake(data);
	};

	return useMutation<
		Awaited<ReturnType<typeof updateAdminStocktake>>,
		TError,
		{ data: UpdateAdminStocktakeRequest },
		TContext
	>(mutationFn, mutationOptions);
};
export const useCreateAdminStocktakeHook = () => {
	const createAdminStocktake = useCustomInstance<CreateAdminStocktakeResponse>();

	return () => {
		return createAdminStocktake({ url: `/AdminStocktake/Create`, method: "post" });
	};
};

export type CreateAdminStocktakeMutationResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useCreateAdminStocktakeHook>>>
>;

export type CreateAdminStocktakeMutationError = unknown;

export const useCreateAdminStocktake = <
	TError = unknown,
	TVariables = void,
	TContext = unknown
>(options?: {
	mutation?: UseMutationOptions<
		Awaited<ReturnType<ReturnType<typeof useCreateAdminStocktakeHook>>>,
		TError,
		TVariables,
		TContext
	>;
}) => {
	const { mutation: mutationOptions } = options ?? {};

	const createAdminStocktake = useCreateAdminStocktakeHook();

	const mutationFn: MutationFunction<
		Awaited<ReturnType<ReturnType<typeof useCreateAdminStocktakeHook>>>,
		TVariables
	> = () => {
		return createAdminStocktake();
	};

	return useMutation<
		Awaited<ReturnType<typeof createAdminStocktake>>,
		TError,
		TVariables,
		TContext
	>(mutationFn, mutationOptions);
};
export const useGetAdminStocktakeReviewHook = () => {
	const getAdminStocktakeReview = useCustomInstance<GetAdminStocktakeReviewResponse>();

	return (params?: GetAdminStocktakeReviewParams, signal?: AbortSignal) => {
		return getAdminStocktakeReview({
			url: `/AdminStocktake/GetReview`,
			method: "get",
			params,
			signal,
		});
	};
};

export const getGetAdminStocktakeReviewQueryKey = (params?: GetAdminStocktakeReviewParams) => [
	`/AdminStocktake/GetReview`,
	...(params ? [params] : []),
];

export type GetAdminStocktakeReviewQueryResult = NonNullable<
	Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeReviewHook>>>
>;
export type GetAdminStocktakeReviewQueryError = unknown;

export const useGetAdminStocktakeReview = <
	TData = Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeReviewHook>>>,
	TError = unknown
>(
	params?: GetAdminStocktakeReviewParams,
	options?: {
		query?: UseQueryOptions<
			Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeReviewHook>>>,
			TError,
			TData
		>;
	}
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
	const { query: queryOptions } = options ?? {};

	const queryKey = queryOptions?.queryKey ?? getGetAdminStocktakeReviewQueryKey(params);

	const getAdminStocktakeReview = useGetAdminStocktakeReviewHook();

	const queryFn: QueryFunction<
		Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeReviewHook>>>
	> = ({ signal }) => getAdminStocktakeReview(params, signal);

	const query = useQuery<
		Awaited<ReturnType<ReturnType<typeof useGetAdminStocktakeReviewHook>>>,
		TError,
		TData
	>(queryKey, queryFn, queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

	query.queryKey = queryKey;

	return query;
};
