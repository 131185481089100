import { PlusSquare } from "@tamagui/lucide-icons";
import { AxiosResponse } from "axios";
import * as ImagePicker from "expo-image-picker";
import { useState } from "react";
import { Alert as NativeAlert, Platform } from "react-native";
import { UseMutationResult } from "react-query";
import {
	H4,
	Paragraph,
	XStack,
	Spinner,
	YStack,
	Card,
	H2,
	Button,
	Input,
	Image,
	Switch,
	H3,
} from "tamagui";

import { putBlob } from "../../../common/api/blobUploader";
import {
	useCompleteAvailabilityListImageUpload,
	useDeleteAvailabilityList,
	useDeleteAvailabilityListImage,
	useStartAvailabilityListImageUpload,
	useUpsertAvailabilityList,
} from "../../../common/api/staverton.openapi/availability-lists-admin";
import {
	Category,
	MultipartUploadDetails,
	StartAvailabilityListImageUploadRequest,
	StartAvailabilityListImageUploadResponse,
} from "../../../common/api/staverton.openapi/index.schemas";
import { Icon } from "../../../common/components";

export default function AvailabilityCategory(props: {
	category: Category;
	onCategoryUpdated: () => Promise<void>;
	showTestAlertForCategory: (availabilityListId: number | null) => void;
}) {
	const [editing, setEditing] = useState(false);

	return (
		<AvailabilityCategoryInternal
			key={String(editing)}
			{...props}
			editing={editing}
			setEditing={setEditing}
			showTestAlertForCategory={props.showTestAlertForCategory}
		/>
	);
}

function AvailabilityCategoryInternal({
	category,
	onCategoryUpdated,
	editing,
	setEditing,
	showTestAlertForCategory,
}: {
	category: Category;
	onCategoryUpdated: () => Promise<void>;
	editing: boolean;
	setEditing: (editing: boolean) => void;
	showTestAlertForCategory: (availabilityListId: number | null) => void;
}) {
	return (
		<Card elevate size="$4" bordered bc="$backgroundSoft">
			{editing ? (
				<EditCard
					setEditing={setEditing}
					onCategoryUpdated={onCategoryUpdated}
					category={category}
				/>
			) : category.availabilityListId ? (
				<ExistingCard
					category={category}
					onCategoryUpdated={onCategoryUpdated}
					setEditing={setEditing}
					showTestAlertForCategory={showTestAlertForCategory}
				/>
			) : (
				<NewCard setEditing={setEditing} />
			)}
		</Card>
	);
}

function ExistingCard({
	category: {
		active,
		availabilityListId,
		department,
		description,
		imageUrl,
		listName,
		mainGroup,
		minQuantity,
		subGroup,
		superDepartment,
	},
	onCategoryUpdated,
	setEditing,
	showTestAlertForCategory,
}: {
	category: Category;
	onCategoryUpdated: () => void;
	setEditing: (editing: boolean) => void;
	showTestAlertForCategory: (availabilityListId: number | null) => void;
}) {
	const [uploadingImage, setUploadingImage] = useState(false);
	const [categoryFormError, setCategoryFormError] = useState<string | undefined>(undefined);
	const startCategoryImageUpload = useStartAvailabilityListImageUpload();
	const completeCategoryImageUpload = useCompleteAvailabilityListImageUpload();
	const deleteCategoryImage = useDeleteAvailabilityListImage();

	function showTestAlert() {
		showTestAlertForCategory(availabilityListId ?? null);
	}

	async function pickImage() {
		if (!availabilityListId) return;

		const result: ImagePicker.ImagePickerResult = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			allowsEditing: false,
			quality: 0.4,
			selectionLimit: 1,
			base64: true,
		});
		const firstAsset = result.assets?.[0];
		if (!firstAsset) return;

		await addImage(firstAsset);
	}

	async function addImage(image: ImagePicker.ImagePickerAsset) {
		if (!availabilityListId) return;
		if (
			!(
				image?.fileName?.match(/(\.jpg$|\.jpeg$|\.png$)/) ||
				image?.uri.match(/(^data:image\/jpeg|^data:image\/png)/)
			)
		) {
			const errorMsg = "Unsupported image format. Please select a jpeg, jpg or png image";
			return Platform.OS === "web" ? alert(errorMsg) : NativeAlert.alert(errorMsg);
		}
		setCategoryFormError("");
		setUploadingImage(true);

		const startUploadResponse = await startUploadingCategoryImage(
			{
				uri: image.uri,
				base64: image.base64!,
				fileName: getImageFileName(image),
				type: image.type,
			},
			availabilityListId,
			startCategoryImageUpload
		);

		if (!startUploadResponse.success) {
			console.error("Start upload Error", startUploadResponse.errorMessage);
			setCategoryFormError(`Start upload error`);
			setUploadingImage(false);
			return;
		}

		const completeUploadResponse = await completeCategoryImageUpload
			.mutateAsync({
				data: {
					...startUploadResponse,
					availabilityListId,
				},
			})
			.catch((err) => {
				console.error("Complete upload Error", err);
				setCategoryFormError(`Complete upload error`);
				setUploadingImage(false);
			});

		if (!completeUploadResponse) {
			setCategoryFormError(`Error completing upload`);
			setUploadingImage(false);
			return;
		}

		await onCategoryUpdated();
		setUploadingImage(false);
	}

	function getImageFileName(image: ImagePicker.ImageInfo) {
		return image.fileName || image.uri.substring(image.uri.lastIndexOf("/") + 1) || "";
	}

	function getNumericValue(
		value: string | undefined,
		defaultValue: number | undefined
	): number | undefined {
		console.log("getNumericValue", value, defaultValue);
		if (value === undefined || value === "") {
			return undefined;
		}

		const parsedValue = parseInt(value);

		if (isNaN(parsedValue)) {
			return defaultValue;
		}

		return parsedValue;
	}

	return (
		<>
			<Card.Header padded>
				<YStack space="$2">
					<H2>
						{listName} {!active && "(not active)"}
					</H2>
					<XStack space="$4">
						<Card
							width={400}
							height={300}
							p={10}
							bg="$background"
							ai="center"
							jc="center"
							br={0}
							bordered
						>
							{!imageUrl ? (
								<Card.Header flex={1} ai="center" jc="center">
									{uploadingImage ? <Spinner /> : <H4>No picture</H4>}
								</Card.Header>
							) : (
								<>
									<Card.Header flex={1} ai="center" jc="center">
										{uploadingImage && <Spinner />}
									</Card.Header>
									<Card.Background>
										<Image key={imageUrl} width={400} height={300} src={imageUrl ?? undefined} />
									</Card.Background>
								</>
							)}
							<Card.Footer>
								<XStack space="$6">
									<XStack flex={1}>
										<Button
											theme="red"
											onPress={async () => {
												setUploadingImage(true);
												setCategoryFormError("");
												const response = await deleteCategoryImage
													.mutateAsync({
														data: { availabilityListId: availabilityListId! },
													})
													.catch((e) => {
														setCategoryFormError(e.message);
													});
												setUploadingImage(false);
												if (!response) {
													setCategoryFormError("An error occurred trying to delete the image");
													return;
												}

												if (response.data.errorMessage) {
													setCategoryFormError(response.data.errorMessage);
													return;
												}

												await onCategoryUpdated();
											}}
										>
											Remove
										</Button>
									</XStack>
									<XStack flex={1}>
										<Button onPress={pickImage}>Change</Button>
									</XStack>
								</XStack>
							</Card.Footer>
						</Card>
						<YStack space="$1">
							<H4>{description}</H4>
							{superDepartment !== 1 && (
								<XStack space="$2">
									<Paragraph>Super Department ID:</Paragraph>
									<Paragraph>{superDepartment}</Paragraph>
								</XStack>
							)}
							{department !== 1 && (
								<XStack space="$2">
									<Paragraph>Department ID:</Paragraph>
									<Paragraph>{department}</Paragraph>
								</XStack>
							)}
							<XStack space="$2">
								<Paragraph>Group Id:</Paragraph>
								<Paragraph>{mainGroup}</Paragraph>
							</XStack>
							<XStack space="$2">
								<Paragraph>Sub Group Id:</Paragraph>
								<Paragraph>{subGroup}</Paragraph>
							</XStack>
							<XStack space="$2">
								<Paragraph>Min Quantity:</Paragraph>
								<Paragraph>{minQuantity}</Paragraph>
							</XStack>
						</YStack>
					</XStack>
				</YStack>
			</Card.Header>
			<Card.Footer padded>
				<XStack flex={1} jc="flex-end" space="$2">
					<Paragraph color="$red9" flex={1}>
						{categoryFormError && <>Error: {categoryFormError}</>}
					</Paragraph>
					<Button theme="gray" onPress={showTestAlert}>
						Test
					</Button>
					<Button
						onPress={() => {
							setEditing(true);
						}}
					>
						Edit
					</Button>
				</XStack>
			</Card.Footer>
		</>
	);
}

function NewCard({ setEditing }: { setEditing: (editing: boolean) => void }) {
	return (
		<Card.Header
			cursor="pointer"
			padded
			onPress={() => {
				setEditing(true);
			}}
		>
			<XStack space="$2" ai="center">
				<Icon size="$8" icon={PlusSquare} />
				<H4 cursor="pointer">Add new Category</H4>
			</XStack>
		</Card.Header>
	);
}

async function startUploadingCategoryImage(
	image: CategoryImage,
	availabilityListId: number,
	startUpload: UseMutationResult<
		AxiosResponse<StartAvailabilityListImageUploadResponse>,
		unknown,
		{
			data: StartAvailabilityListImageUploadRequest;
		}
	>
): Promise<
	| {
			success: true;
			eTags: string[];
			details: MultipartUploadDetails;
	  }
	| {
			success: false;
			errorMessage: string;
	  }
> {
	function getFileExtension(image: CategoryImage) {
		const parts = image.fileName?.split(".");

		if (parts && parts.length > 1) {
			return image.fileName?.split(".").pop();
		}

		return null;
	}

	try {
		if (!image.base64)
			return {
				success: false,
				errorMessage: "Image not valid",
			};

		// Chunk the image base64 string for larger uploads
		const chars = 12000000;
		const chunks = Math.ceil(image.base64.length / chars);
		const chunkedBase64 = [];
		for (let i = 0; i < chunks; i++) {
			chunkedBase64.push(image.base64.slice(i * chars, (i + 1) * chars));
		}

		// Get the presigned s3 urls to upload the image
		const started = await startUpload
			.mutateAsync({
				data: {
					fileExtension: getFileExtension(image) ?? "jpg",
					availabilityListId,
					chunks,
					contentType: "image/jpeg",
				},
			})
			.catch((e) => {
				console.error(`image start error `, e);
			});
		if (!started) {
			return {
				success: false,
				errorMessage: `Failed to start upload.`,
			};
		}

		// Upload the image chunks to the presigned S3 urls
		const eTags = [];
		let index = 0;
		for (const url of started.data.urls) {
			const result = await putBlob({
				url,
				data: chunkedBase64[index],
			});
			if (!result?.eTag) {
				return {
					success: false,
					errorMessage: `Failed to start upload ${image.fileName}`,
				};
			}

			eTags.push(result.eTag);
			index++;
		}

		return {
			success: true,
			eTags,
			details: started.data.details,
		};
	} catch (e) {
		console.error(e);
		return {
			success: false,
			errorMessage: `Failed to start upload ${image.fileName}`,
		};
	}
}

interface CategoryImage {
	uri?: string;
	base64?: string;
	fileName?: string;
	type?: string;
	s3Location?: string;
}

function EditCard({
	category,
	setEditing,
	onCategoryUpdated,
}: {
	category: Category;
	setEditing: (editing: boolean) => void;
	onCategoryUpdated: () => void;
}) {
	const [updatedCategory, setUpdatedCategory] = useState<Category>(category);
	const [categoryFormError, setCategoryFormError] = useState<string | undefined>(undefined);
	const [validationErrors, setValidationErrors] = useState<string[]>([]);
	const upsertCategory = useUpsertAvailabilityList();
	const deleteCategory = useDeleteAvailabilityList();
	const isNew = category.availabilityListId === undefined;

	function getNumericValue(
		value: string | undefined,
		defaultValue: number | undefined
	): number | undefined {
		console.log("getNumericValue", value, defaultValue);
		if (value === undefined || value === "") {
			return undefined;
		}

		const parsedValue = parseInt(value);

		if (isNaN(parsedValue)) {
			return defaultValue;
		}

		return parsedValue;
	}

	function validateForm(formData: Category): boolean {
		const errorFields: string[] = [];

		["listName", "superDepartment", "department"].forEach((key) => {
			console.log("key", key, formData[key as keyof Category]);
			if (formData[key as keyof Category] === undefined || formData[key as keyof Category] === "") {
				setCategoryFormError(`Please enter the missing values`);
				errorFields.push(key);
			}
		});

		setValidationErrors(errorFields);

		return errorFields.length === 0;
	}

	async function handleDeleteCategory(id: number) {
		const response = await deleteCategory.mutateAsync({ params: { id } });

		if (response.data?.errorMessage ?? true) {
			setCategoryFormError(response?.data?.errorMessage ?? "Something went wrong");
			return;
		}

		await onCategoryUpdated();
		setEditing(false);
	}

	async function handleSaveCategory(categoryToSave: Category) {
		if (!validateForm({ ...categoryToSave })) return;

		try {
			const response = await upsertCategory.mutateAsync({
				data: categoryToSave,
			});

			if (response.data.errorMessage) {
				setCategoryFormError(response.data.errorMessage ?? "Something went wrong");
			} else if (response.data) {
				await onCategoryUpdated();
				setEditing(false);
			}
		} catch (err) {
			setCategoryFormError("Something went wrong");
		}
	}

	return (
		<>
			<Card.Header padded>
				<H2>{isNew ? "New Category" : updatedCategory.listName}</H2>
				<XStack space="$4">
					{!updatedCategory.imageUrl ? (
						<YStack width={400} height={300} p={10} bg="$background" ai="center" jc="center">
							<H3>No Image</H3>
						</YStack>
					) : (
						<Image
							key={updatedCategory.imageUrl}
							width={400}
							height={300}
							src={updatedCategory.imageUrl ?? undefined}
						/>
					)}
					<YStack space="$2">
						<XStack>
							<Paragraph width={180}>Category name</Paragraph>
							<Input
								{...(validationErrors.includes("listName") && { borderColor: "red" })}
								keyboardType="default"
								size="$4"
								width={300}
								textAlign="left"
								selectTextOnFocus
								value={updatedCategory.listName}
								onChangeText={(value) =>
									setUpdatedCategory({ ...updatedCategory, listName: value })
								}
							/>
						</XStack>
						<XStack>
							<Paragraph width={180}>Description</Paragraph>
							<Input
								{...(validationErrors.includes("description") && { borderColor: "red" })}
								keyboardType="default"
								size="$4"
								width={600}
								textAlign="left"
								selectTextOnFocus
								value={updatedCategory.description ?? undefined}
								onChangeText={(value) =>
									setUpdatedCategory({ ...updatedCategory, description: value })
								}
							/>
						</XStack>
						{String(category.superDepartment) !== "1" && (
							<XStack>
								<Paragraph width={180}>Super Department Id</Paragraph>
								<Input
									{...(validationErrors.includes("superDepartment") && { borderColor: "red" })}
									keyboardType="number-pad"
									size="$4"
									width="$8"
									textAlign="right"
									selectTextOnFocus
									value={updatedCategory.superDepartment?.toString()}
									onChangeText={(value) => {
										setUpdatedCategory({
											...updatedCategory,
											superDepartment: getNumericValue(
												value,
												updatedCategory.superDepartment ?? undefined
											),
										});
									}}
								/>
							</XStack>
						)}
						{String(category.department) !== "1" && (
							<XStack>
								<Paragraph width={180}>Department Id</Paragraph>
								<Input
									{...(validationErrors.includes("department") && { borderColor: "red" })}
									keyboardType="number-pad"
									size="$4"
									width="$8"
									textAlign="right"
									selectTextOnFocus
									value={updatedCategory.department?.toString()}
									onChangeText={(value) =>
										setUpdatedCategory({
											...updatedCategory,
											department: getNumericValue(value, updatedCategory.department ?? undefined),
										})
									}
								/>
							</XStack>
						)}
						<XStack>
							<Paragraph width={180}>Group Id</Paragraph>
							<Input
								{...(validationErrors.includes("mainGroup") && { borderColor: "red" })}
								keyboardType="number-pad"
								size="$4"
								width="$8"
								textAlign="right"
								selectTextOnFocus
								value={(updatedCategory.mainGroup ?? undefined)?.toString()}
								onChangeText={(value) =>
									setUpdatedCategory({
										...updatedCategory,
										mainGroup: getNumericValue(value, updatedCategory.mainGroup ?? undefined),
									})
								}
							/>
						</XStack>
						<XStack>
							<Paragraph width={180}>Sub Group Id</Paragraph>
							<Input
								{...(validationErrors.includes("subGroup") && { borderColor: "red" })}
								keyboardType="number-pad"
								size="$4"
								width="$8"
								textAlign="right"
								selectTextOnFocus
								value={(updatedCategory.subGroup ?? undefined)?.toString()}
								onChangeText={(value) =>
									setUpdatedCategory({
										...updatedCategory,
										subGroup: getNumericValue(value, updatedCategory.subGroup ?? undefined),
									})
								}
							/>
						</XStack>
						<XStack>
							<Paragraph width={180}>Active</Paragraph>
							<Switch
								p={0}
								size="$2"
								checked={updatedCategory.active}
								onCheckedChange={(checked) =>
									setUpdatedCategory({
										...updatedCategory,
										active: checked,
									})
								}
							>
								<Switch.Thumb animation="quick" />
							</Switch>
						</XStack>
						<XStack>
							<Paragraph width={180}>Min quantity</Paragraph>
							<Input
								{...(validationErrors.includes("minQuantity") && { borderColor: "red" })}
								keyboardType="number-pad"
								size="$4"
								width="$8"
								textAlign="right"
								selectTextOnFocus
								value={(updatedCategory.minQuantity ?? undefined)?.toString()}
								onChangeText={(value) => {
									setUpdatedCategory({
										...updatedCategory,
										minQuantity: getNumericValue(value, updatedCategory.minQuantity ?? undefined),
									});
								}}
							/>
						</XStack>
					</YStack>
				</XStack>
			</Card.Header>
			<Card.Footer padded>
				<XStack flex={1} space="$2">
					{!isNew && (
						<Button
							theme="red"
							onPress={async () => {
								await handleDeleteCategory(category.availabilityListId!);
							}}
						>
							Delete
						</Button>
					)}
					<Paragraph color="$red9" flex={1}>
						{categoryFormError && <>Error: {categoryFormError}</>}
					</Paragraph>
					<Button
						theme="gray"
						onPress={() => {
							setEditing(false);
						}}
					>
						Cancel
					</Button>
					<Button
						onPress={async () => {
							await handleSaveCategory(updatedCategory);
						}}
					>
						Save
					</Button>
				</XStack>
			</Card.Footer>
		</>
	);
}
