import { ArrowLeft, ArrowRight } from "@tamagui/lucide-icons";
import React from "react";
import { Paragraph, XStack } from "tamagui";

import { Button } from ".";

export function PagingButtons({
	from,
	size,
	items,
	total,
	updateFrom,
	...otherProps
}: {
	from: number;
	size: number;
	items: number;
	total: number;
	updateFrom: (value: number) => void;
}) {
	const canPageLeft = from > 0;
	const canPageRight = from + size < total;

	return (
		<XStack ai="center" jc="space-between" space="$3" {...otherProps}>
			<Button
				icon={ArrowLeft}
				disabled={!canPageLeft}
				onPress={() => updateFrom(from - size > 0 ? from - size : 0)}
			/>
			<Paragraph ta="center" color="$colorTranslucent" size="$2">
				Showing {from + 1} to {from + items} of {total}
			</Paragraph>
			<Button icon={ArrowRight} disabled={!canPageRight} onPress={() => updateFrom(from + size)} />
		</XStack>
	);
}
