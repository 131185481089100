import * as React from "react";
import { Svg, Line } from "react-native-svg";

export function AlertBasic(props) {
	const { color = "black", size = 24, ...otherProps } = props;
	return (
		<Svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			stroke={`${color}`}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
			{...otherProps}
		>
			<Line
				x1="12"
				y1="5"
				x2="12"
				y2="13"
				fill="none"
				stroke={`${color}`}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<Line
				x1="12"
				y1="17"
				x2="12"
				y2="19"
				fill="none"
				stroke={`${color}`}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</Svg>
	);
}
export default AlertBasic;
