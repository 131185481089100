import type LucideIcons from "@tamagui/lucide-icons";
import React from "react";
import { Platform, ScrollView as RNScrollView } from "react-native";
import { getConfig, styled, useTheme, Button as TamaguiButton, ButtonProps } from "tamagui";

export const ScrollView = styled(RNScrollView, {
	name: "ScrollView",
});

export const Icon = function ({
	icon: As,
	size,
	color = "$color",
}: {
	size: string;
	color?: string;
	icon: typeof LucideIcons[keyof typeof LucideIcons];
}) {
	const theme = useTheme();
	const sizeValue = getConfig().fontsParsed?.$body?.size?.[size];
	const tc = Object(theme)[color];
	return (
		<As
			size={typeof sizeValue === "number" ? sizeValue : sizeValue?.val}
			height={typeof sizeValue === "number" ? sizeValue : sizeValue?.val}
			width={typeof sizeValue === "number" ? sizeValue : sizeValue?.val}
			color={typeof tc === "number" ? tc.toString() : typeof tc === "object" ? tc.val : tc}
		/>
	);
};

export const Button = function ({ disabled, onPress, children, ...otherProps }: ButtonProps) {
	return (
		<TamaguiButton
			opacity={disabled ? 0.5 : 1}
			cursor={Platform.OS === "web" && disabled ? "normal" : undefined}
			onPress={disabled ? undefined : onPress}
			{...otherProps}
		>
			{children}
		</TamaguiButton>
	);
};
